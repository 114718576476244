import { TOPBAR_HELP_CLICK_EVENT_NAME } from '@constants/mixpanel'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useLazyService } from '@hooks/useLazyService'
import {
  Button,
  Icon,
  List,
  ListItem,
  PerfectDropdown,
  Tooltip
} from '@landingi/landingi-ui-kit'
import { mixpanelEvent } from '@services/mixpanel'
import { useTranslation } from 'react-i18next'

export const Help = () => {
  const { t } = useTranslation()

  const { hasFreeTrial, isAgency } = useUserContext()

  const freePlan = useFeatureFlags('FREE_PLAN_RESTRICTIONS')

  const freeTrial = hasFreeTrial && !freePlan

  const notFreeTrial = !hasFreeTrial && !freePlan

  const consultationURL = () => {
    if (isAgency) {
      return t('topbar.consultations.agency.url')
    }

    if (freePlan) {
      return t('topbar.consultations.free.url')
    }

    return t('topbar.consultations.url')
  }

  const [sendMixpanelEvent] = useLazyService(mixpanelEvent)

  return (
    <div id='topbar-help' data-testid='topbar-help'>
      <Tooltip content={t('topbar.help.tooltip.content')} align='center'>
        <PerfectDropdown
          icon='icon-help'
          size='large'
          hasArrow={false}
          fontColor='color-3'
          fontWeight={600}
          hasHoverLabel
          handleOnOpen={() => {
            sendMixpanelEvent({
              name: TOPBAR_HELP_CLICK_EVENT_NAME
            })
          }}
        >
          <List>
            <ListItem variant='dropdown'>
              <Button
                variant='dropdown-element'
                tag='a'
                href={t('topbar.help-center.url')}
                target='_blank'
                hasIcon
                buttonStyle
              >
                <Icon icon='icon-help-center' size={12} />
                {t('topbar.help-center')}
              </Button>
            </ListItem>

            {freeTrial ? (
              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  tag='a'
                  href={t('topbar.free-onboarding-session.url')}
                  target='_blank'
                  hasIcon
                  buttonStyle
                >
                  <Icon icon='icon-consultation' size={12} />
                  {t('topbar.free-onboarding-session')}
                </Button>
              </ListItem>
            ) : null}

            {notFreeTrial ? (
              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  tag='a'
                  href={t('topbar.create-support-ticket.url')}
                  target='_blank'
                  hasIcon
                  buttonStyle
                >
                  <Icon icon='icon-envelope' size={12} />
                  {t('topbar.create-support-ticket')}
                </Button>
              </ListItem>
            ) : null}

            {!hasFreeTrial ? (
              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  tag='a'
                  href={consultationURL()}
                  target='_blank'
                  hasIcon
                  buttonStyle
                >
                  <Icon icon='icon-consultation' size={12} />
                  {t('topbar.consultations')}
                </Button>
              </ListItem>
            ) : null}

            <ListItem variant='dropdown'>
              <Button
                variant='dropdown-element'
                tag='a'
                href={t('topbar.video-tutorials.url')}
                target='_blank'
                hasIcon
                buttonStyle
              >
                <Icon icon='icon-play-lesson' size={12} />
                {t('topbar.video-tutorials')}
              </Button>
            </ListItem>

            <ListItem variant='dropdown'>
              <Button
                variant='dropdown-element'
                tag='a'
                href={t('topbar.webinars.url')}
                target='_blank'
                hasIcon
                buttonStyle
              >
                <Icon icon='icon-video-alt' size={12} />
                {t('topbar.webinars')}
              </Button>
            </ListItem>

            <ListItem variant='dropdown'>
              <Button
                variant='dropdown-element'
                tag='a'
                href={t('topbar.landing-page-academy.url')}
                target='_blank'
                hasIcon
                buttonStyle
              >
                <Icon icon='icon-academy' size={12} />
                {t('topbar.landing-page-academy')}
              </Button>
            </ListItem>

            <ListItem variant='dropdown'>
              <Button
                variant='dropdown-element'
                tag='a'
                href={t('topbar.system-status.url')}
                target='_blank'
                hasIcon
                buttonStyle
              >
                <Icon icon='icon-server' size={12} />
                {t('topbar.system-status')}
              </Button>
            </ListItem>
          </List>
        </PerfectDropdown>
      </Tooltip>
    </div>
  )
}

Help.displayName = 'TopbarHelp'
