import { APP_LANDINGI_URL } from '@config/env'
import { ADDONS_IDS } from '@constants/addonsIds'
import Agency from '@images/addons/icons/Agency.svg'
import {
  FEATURE,
  INCLUDED_IN_PLAN
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { AgencyScheduleDemoButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import {
  ADVERTISE,
  AdvertiseAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getUseCases } from './UseCases'

export const getAgencyAddon: (
  t: TFunction<'translation', undefined>,
  { isHidden }: { isHidden: boolean },
  isAgency: boolean
) => AdvertiseAddonTemplate = (t, { isHidden }, isAgency) => ({
  type: ADVERTISE,
  name: t('addons.store.advertise.agency'),
  title: t('addons.store.advertise.agency'),
  tileDescription: (
    <Trans i18nKey='addons.store.advertise.agency.description' />
  ),
  badges: isAgency ? [INCLUDED_IN_PLAN, FEATURE] : [FEATURE],
  imageSrc: Agency,
  id: ADDONS_IDS.AGENCY,
  pageDescription: t('addons.store.advertise.agency.page.description'),
  overview: getOverview(t),
  features: getFeatures(),
  useCases: getUseCases(t),
  faq: getFAQ(t),
  CTALink: `${APP_LANDINGI_URL}/account/package`,
  secondaryButton: AgencyScheduleDemoButton,
  isHidden
})
