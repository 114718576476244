import { APP_LANDINGI_URL } from '@config/env'
import { List, ListItem } from '@landingi/landingi-ui-kit'
import { Paragraph, Spacer, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={1} />

        <Spreader spread={15} />

        {t('addons.store.advertise.agency.question.1')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        <Trans
          i18nKey='addons.store.advertise.agency.answer.1'
          components={{
            a: (
              <a
                href={`${APP_LANDINGI_URL}/account/package`}
                target='_blank'
                rel='noreferrer'
              />
            )
          }}
        />
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.advertise.agency.question.2')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          <Trans i18nKey='addons.store.advertise.agency.answer.2.header' />
        </Paragraph>

        <Spacer space={5} />

        <List listStyle='ordered-disc'>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.1')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.2')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.3')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.4')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.5')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.6')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.7')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.8')}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.advertise.agency.answer.2.point.9')}
            </Paragraph>
          </ListItem>
        </List>

        <Spacer space={10} />

        <Paragraph size={14}>
          <Trans
            i18nKey='addons.store.advertise.agency.answer.2'
            components={{
              a: (
                <a
                  href={t('addons.store.advertise.agency.answer.2.url')}
                  target='_blank'
                  rel='noreferrer'
                />
              )
            }}
          />
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.advertise.agency.question.3')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.advertise.agency.answer.3')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={4} />

        <Spreader spread={15} />

        {t('addons.store.advertise.agency.question.4')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.advertise.agency.answer.4')}
      </Paragraph>
    )
  }
]
