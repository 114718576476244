import { useStyles } from '@hooks/useStyles'
import { Heading, Image, Radio } from '@landingi/landingi-ui-kit'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import styles from './SelectBoxRadio.module.scss'

/**
 * Select Box (radio button) - stateless presentational component
 * @param {object} props - props
 * @param {string|array} props.className - list of class names, default: ''
 * @param {object} props.field - react-formik field properties
 * @param {object} props.form - react-formik form properties
 * @param {string} props.id - id of the element
 * @param {string} props.label - label, default: ''
 * @param {string} props.type - type of element, default: 'radio'
 * @param {variant} props.variant - variant element, default: 'small', oneOf: 'small', 'big', 'wide', 'responsive'
 * @param {string} props.title - title to be shown in select box
 * @param {string} props.iconSource - source of image to be shown in select box
 * @param {object} props.activeContent - React node to be shown when radio button is selected
 * @param {object} centerTextInActiveContent - used to center active content - shouldn't be used when activeContent is input
 * @param {bool} translate - info whether title is id to translate or already translated message
 * @return {object} An object of children element
 */
const SelectBoxRadio = ({
  field: { name, value, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  onChange,
  className,
  type,
  variant,
  iconSource,
  activeContent,
  centerTextInActiveContent,
  i18n
}) => {
  const isSelected = value === id

  const elementClasses = useStyles(
    {
      [styles.selectBox]: true,
      [styles[`selectBox--${variant}`]]: variant,
      [styles['selectBox--active']]: isSelected
    },
    className
  )

  const variantValues = {
    small: {
      headingLevel: 4,
      iconSize: 48
    },
    big: {
      headingLevel: 3,
      iconSize: 48
    },
    wide: {
      headingLevel: 3,
      iconSize: 48
    },
    responsive: {
      headingLevel: 4,
      iconSize: 48
    }
  }

  /**
   * @function shouldShowActiveContent - returns true when there is activeContent prop given and radio is selected
   * @return {bool}
   */
  const shouldShowActiveContent = () => activeContent && isSelected

  const activeContentStyles = useStyles({
    [styles['selectBox__active-content']]: true,
    [styles['selectBox__active-content--centered']]: centerTextInActiveContent
  })

  return (
    <motion.label className={elementClasses} data-testid='label'>
      <div className={styles['selectBox__inner-div']}>
        <div className={styles[`selectBox__content-container`]}>
          <Radio
            field={{
              name,
              value,
              onChange: event => {
                setFieldValue(name, event?.target?.value)
                onChange && onChange(event)
              },
              onBlur
            }}
            form={{ errors, touched, setFieldValue }}
            className={styles['selectBox__radio-button']}
            id={id}
            type={type}
          />

          <Image
            src={iconSource}
            size={variantValues[variant]?.iconSize || null}
            height={variantValues[variant]?.iconSize || null}
          />

          <Heading level={variantValues[variant].headingLevel} margin='none'>
            {i18n.title}
          </Heading>

          {shouldShowActiveContent() && (
            <div className={activeContentStyles}>{activeContent}</div>
          )}
        </div>
      </div>

      <div />
    </motion.label>
  )
}

SelectBoxRadio.displayName = 'SelectBoxRadio'

SelectBoxRadio.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.instanceOf(Object),
    touched: PropTypes.instanceOf(Object),
    setFieldValue: PropTypes.func
  }).isRequired,
  id: PropTypes.string.isRequired,
  iconSource: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  activeContent: PropTypes.node,
  variant: PropTypes.oneOf(['small', 'big', 'wide', 'responsive']),
  centerTextInActiveContent: PropTypes.bool,
  onChange: PropTypes.func,
  i18n: PropTypes.shape({ title: PropTypes.string })
}

SelectBoxRadio.defaultProps = {
  className: '',
  type: 'radio',
  variant: 'responsive',
  activeContent: null,
  centerTextInActiveContent: false,
  i18n: { title: '' },
  onChange: () => null
}

export default SelectBoxRadio
