import { TestDomainIcon } from '@components/TestDomainIcon'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { isTestDomain } from '@helpers/domains'
import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import { useLazyService } from '@hooks/useLazyService'
import {
  Button,
  CustomLink,
  EditableLabel,
  Icon,
  Paragraph,
  Spacer,
  Spreader,
  StatusIcon,
  Tooltip
} from '@landingi/landingi-ui-kit'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { PublicationType } from '@pages/Landings/routes/Landings/components/LandingsTable/components/PublicationType'
import { isPublishedOnTestDomain } from '@pages/Landings/routes/Landings/helpers'
import LANDINGS from '@routes/path/landings'
import { renameLanding, RenameLandingRequest } from '@services/landings/rename'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { Badges } from './Badges'
import { statusOfTheDomain } from './helpers'
import styles from './Info.module.scss'
import { Thumbnail } from './Thumbnail'

export const Info = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { landing, setNewLandingData } = useDashboardContext()

  const {
    domain_url,
    identifier: landingUuid,
    is_published,
    name,
    publication_type
  } = landing

  const isFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')
  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const hasAccessToPublishingOptions = useAcl(
    'landing_pages.landing.publishing_options'
  )

  const hasAccessToRename = useAcl('landing_pages.landing.rename')

  // Render publication type only for published landings and only non-test domains on free plan
  const shouldRenderPublicationType =
    is_published &&
    !(
      isTestDomain(domain_url) &&
      isFreePlanRestrictions &&
      !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)
    )

  // Render publication status only for test domains on free plan without ALLOW_TEST_AUTODOMAIN feature flag
  const shouldRenderPublicationStatus =
    isTestDomain(domain_url) &&
    isFreePlanRestrictions &&
    !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)

  // Render link for all domains on paid plan or free plan with ALLOW_TEST_AUTODOMAIN feature flag
  const shouldRenderLink = !(
    isTestDomain(domain_url) &&
    isFreePlanRestrictions &&
    !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)
  )

  const [handleCopy] = useCopyToClipboard(domain_url)

  const [renameLandingService, { isLoading }] =
    useLazyService<RenameLandingRequest>(renameLanding, {
      successToastText: t('toast.saved.success'),
      errorToastText: t('toast.saved.fail'),
      onSuccess: (res, { newName }) => setNewLandingData({ name: newName })
    })

  const handleRenameLanding = async (name: string) => {
    await renameLandingService({
      uuid: landingUuid,
      newName: name
    })
  }

  const handleOpenPublishingOptions = () => {
    const path = generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
      identifier: landingUuid
    })

    navigate(`${path}?dashboard`)
  }

  return (
    <Row className={styles['landing__info--text']}>
      <Link
        to={generatePath(LANDINGS.LANDINGS_DASHBOARD, {
          identifier: landingUuid
        })}
      >
        <Thumbnail />
      </Link>

      <Column>
        <EditableLabel
          initialName={name}
          isClickable={hasAccessToRename}
          placeholder={t('word.landings')}
          onChange={handleRenameLanding}
          isLoading={isLoading}
          isDisabled={!hasAccessToRename}
          tooltip={{
            focused: t('dashboard.header.hover.tooltip.focused'),
            notFocused: t('dashboard.header.hover.tooltip.not.focused')
          }}
        />

        <Spacer space='tiny' />

        <div className={styles['landing__info--domain']}>
          <TestDomainIcon isPublished={is_published} domain={domain_url} />

          {isPublishedOnTestDomain(is_published, domain_url) ? (
            <Spreader spread='mini' />
          ) : null}

          <StatusIcon
            size='tiny'
            variant={is_published ? 'active' : 'inactive'}
          />

          {shouldRenderPublicationType && (
            <Fragment>
              <Spreader spread='mini' />

              <PublicationType publicationType={publication_type} />
            </Fragment>
          )}

          <Spreader spread='mini' />

          {shouldRenderPublicationStatus && (
            <Paragraph
              padding='none'
              color={is_published ? 'success' : 'accent-6'}
            >
              {is_published ? t('word.published') : t('word.not.published')}
            </Paragraph>
          )}

          {shouldRenderLink && (
            <Fragment>
              <CustomLink
                href={`https://${domain_url}`}
                label={domain_url}
                target='_blank'
                variant={statusOfTheDomain(is_published)}
              />

              <Spreader spread='medium' />

              <Tooltip content={t('copy.link.to.clipboard')}>
                <Button onClick={handleCopy} variant='icon' size='mini'>
                  <Icon icon='icon-link' color='primary' />
                </Button>
              </Tooltip>
            </Fragment>
          )}

          {hasAccessToPublishingOptions ? (
            <Fragment>
              <Spreader spread='tiny' />

              <Button
                variant='secondary'
                size='tiny'
                onClick={handleOpenPublishingOptions}
              >
                {t('word.change.url')}
              </Button>
            </Fragment>
          ) : null}
        </div>

        <Spacer space='tiny' />

        <Badges />
      </Column>
    </Row>
  )
}

Info.displayName = 'LandingDashboardHeaderInfo'
