import { NEW_LANDINGI_URL } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useStyles } from '@hooks/useStyles'
import {
  Button,
  Icon,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { paragraphColor } from '@pages/Landings/routes/LandingsPublishingOptions/helpers/paragraphColor'
import { LANDINGS } from '@routes/path'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import styles from './Boxes.module.scss'
import { Box } from './components/Box'

export const Boxes = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { landingPageUrls, publishingOptions, handleChangeUrl, uuid } =
    usePublishingOptionsContext()

  const { data } = landingPageUrls

  const isCollapsedInitial = data.collection.length > 1
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedInitial)

  const toggleCollapse = () => setIsCollapsed(prev => !prev)

  const {
    isLandingPagePublished,
    landingPagePublicationType,
    hasLandingPageCustomDomain
  } = publishingOptions.data

  const HIDE_WORDPRESS_PUBLICATION_FF = useFeatureFlags(
    'HIDE_WORDPRESS_PUBLICATION'
  )

  const boxesStyles = useStyles({
    [styles.boxesWrapper]: true,
    [styles['boxesWrapper--no-wordpress']]: HIDE_WORDPRESS_PUBLICATION_FF
  })

  useEffect(() => {
    setIsCollapsed(isCollapsedInitial)
  }, [isCollapsedInitial])

  return (
    <Fragment>
      {isCollapsedInitial && (
        <Fragment>
          <Row>
            <Button variant='secondary' onClick={toggleCollapse}>
              <span>{t('publishing.options.boxes.collapse.button')}</span>

              <Spreader spread='tiny' />

              <Icon icon={isCollapsed ? 'icon-caret-down' : 'icon-caret-up'} />
            </Button>
          </Row>

          <Spacer space='tiny' />
        </Fragment>
      )}

      {!isCollapsed && (
        <Fragment>
          <Spacer space='tiny' />

          <Paragraph
            size={18}
            color={paragraphColor(isLandingPagePublished)}
            padding='none'
            weight={400}
          >
            {t('publishing.options.title.panels')}
          </Paragraph>

          <Spacer space='mini' />

          <Paragraph
            color={paragraphColor(isLandingPagePublished)}
            weight={400}
          >
            {t('publishing.options.desc.panels')}
          </Paragraph>

          <div className={boxesStyles}>
            {hasLandingPageCustomDomain ? (
              <Box
                name={t('publishing.options.boxes.box.custom_domain.name')}
                icon={
                  <Icon
                    icon='icon-globe'
                    color={
                      isLandingPagePublished ? 'color-accent-5' : 'color-4'
                    }
                    size={32}
                  />
                }
                description={t(
                  'publishing.options.boxes.box.custom_domain.description'
                )}
                onClick={() => handleChangeUrl('')}
                disabled={
                  !isLandingPagePublished ||
                  landingPagePublicationType === TYPES.CUSTOM
                }
              />
            ) : (
              <Box
                name={t('publishing.options.boxes.box.add_domain.name')}
                icon={
                  <Icon icon='icon-globe' color='color-accent-5' size={32} />
                }
                description={t(
                  'publishing.options.boxes.box.custom_domain.description'
                )}
                onClick={() =>
                  window.open(`https://${NEW_LANDINGI_URL}/domains`, '_self')
                }
              />
            )}

            {!HIDE_WORDPRESS_PUBLICATION_FF && (
              <Box
                name={t('publishing.options.boxes.box.wordpress.name')}
                icon={
                  <Icon
                    icon='icon-wordpress-alt'
                    color={isLandingPagePublished ? undefined : 'color-4'}
                    size={32}
                  />
                }
                description={t(
                  'publishing.options.boxes.box.wordpress.description'
                )}
                onClick={() =>
                  navigate(
                    generatePath(
                      LANDINGS.LANDINGS_PUBLISHING_OPTIONS.WORDPRESS,
                      {
                        identifier: uuid
                      }
                    )
                  )
                }
                disabled={!isLandingPagePublished}
              />
            )}

            <Box
              name={t('publishing.options.boxes.box.embed.name')}
              icon={
                <Icon
                  icon='icon-server'
                  color={isLandingPagePublished ? 'color-accent-5' : 'color-4'}
                  size={32}
                />
              }
              description={t('publishing.options.boxes.box.embed.description')}
              onClick={() =>
                navigate(
                  generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.EMBED, {
                    identifier: uuid
                  })
                )
              }
              disabled={!isLandingPagePublished}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

Boxes.displayName = 'PublishingOptionsViewsMainBoxes'
