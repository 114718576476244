import { useStyles } from '@hooks/useStyles'
import { Back, Button, Icon, Spreader } from '@landingi/landingi-ui-kit'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './Questions.module.scss'

/**
 * FooterButtons - components representing buttons that appear in footer of survey questions
 * @param {bool} isValid - formik isValid
 * @param {bool} isSubmitting - formik isSubmitting
 * @param {function} handleSkipStep - function that handles clicking skip button
 * @param {function} handleNext -function that handles clicking next step button
 * @param {bool} isLastQuestion - information whether question is last or not
 * @return {object} - children
 */
const FooterButtons = ({
  handleNext,
  isSubmitting,
  handleSkipStep,
  leftSideContent,
  isLastQuestion,
  isSkippingLastQuestion,
  isValid
}) => {
  const { t } = useTranslation()
  const { currentStep, previousStep } = useStepsContext()

  const footerButtonsStyles = useStyles({
    [styles.footer]: true,
    [styles['footer--with-left-content']]: leftSideContent
  })

  return (
    <motion.div layout='position'>
      <div className={footerButtonsStyles}>
        {leftSideContent}
        <Row
          className={styles.footer__content}
          justifyContent={currentStep <= 1 ? 'flex-end' : undefined}
        >
          {currentStep > 1 && (
            <Back className={styles.back} onClick={previousStep} />
          )}
          <Row className={styles.footer__buttons}>
            <Button
              variant='transparent'
              onClick={handleSkipStep}
              isDisabled={isSubmitting}
              isLoading={isSkippingLastQuestion}
              type={isLastQuestion ? 'submit' : 'button'}
            >
              {t('registration.flow.survey.skip.step')}
            </Button>
            <Spreader spread='tiny' />
            <Spreader spread='mini' />
            <Button
              onClick={handleNext}
              type='submit'
              isLoading={isSubmitting}
              isDisabled={isSubmitting || !isValid || isSkippingLastQuestion}
            >
              {t('registration.flow.survey.next.step')}
              <Spreader spread='tiny' />
              <Icon icon='icon-arrow-right' />
            </Button>
          </Row>
        </Row>
      </div>
    </motion.div>
  )
}

FooterButtons.propTypes = {
  handleNext: PropTypes.func,
  isSubmitting: PropTypes.bool,
  handleSkipStep: PropTypes.func,
  isLastQuestion: PropTypes.bool,
  isValid: PropTypes.bool,
  leftSideContent: PropTypes.node,
  isSkippingLastQuestion: PropTypes.bool
}

FooterButtons.defaultProps = {
  handleNext: () => null,
  isSubmitting: false,
  handleSkipStep: () => null,
  isLastQuestion: false,
  isValid: false,
  leftSideContent: '',
  isSkippingLastQuestion: false
}

export default FooterButtons
