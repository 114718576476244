import { CurrencyType } from '@constants/currencies'
import { FREE_PLANS } from '@constants/plans'
import { COMPLETED } from '@constants/registrationSteps'
import {
  AccountInfoResponse,
  useGetAccountInfo
} from '@services/account/useGetAccountInfo'
import { useGetAuth } from '@services/authentication'
import { useGetDiscounts } from '@services/discounts'
import { createContext, FC, ReactNode, useContext } from 'react'
import useSWR from 'swr'

const useUser = () => {
  const {
    data: auth,
    isLoading: isLoadingAuth,
    error: authError,
    isAuth
  } = useGetAuth()

  const {
    data: user,
    isLoading: isLoadingAccountInfo,
    error: userError,
    accountName,
    accountID,
    accountCurrency,
    userName,
    userEmail,
    userID,
    ownerName,
    ownerEmail,
    ownerID,
    language,
    isNewNotification,
    userLogo,
    planRecordKey,
    planPackageUuid,
    leadsCount,
    isSupportMode,
    hasPinnedCreditCard,
    timezone,
    accountType,
    isAgency,
    isSubaccount,
    isNormal,
    isTypeOwner,
    isTypeAdmin,
    isTypeClient,
    isTypeManager,
    isTypeDesigner,
    isTypeReadOnly,
    isTypeAccountant,
    hasFreeTrial,
    hasSubscription,
    isActive,
    createdAt,
    expiresAt,
    creditCardExpire,
    subscription,
    billingPeriod,
    subscriptionStatus,
    planName,
    daysLeftToAccountExpiration,
    isDefaultRegistration,
    isCreditCardExpired
  } = useGetAccountInfo()

  const {
    data: discounts,
    isLoading: isLoadingDiscounts,
    error: discountsError
  } = useGetDiscounts()

  const { data: addonStoreLastSeen } = useSWR(
    'payments/product-addon/last_seen'
  )

  return {
    isLoading: isLoadingAccountInfo || isLoadingAuth || isLoadingDiscounts,
    user: user as AccountInfoResponse,
    auth,
    billingPeriod,
    userError,
    authError,
    discounts,
    discountsError,
    isAuth,
    accountName,
    accountID,
    accountCurrency: (accountCurrency as CurrencyType) || undefined,
    userName,
    userEmail,
    userID,
    ownerName,
    ownerEmail,
    ownerID,
    language,
    isNewNotification,
    userLogo,
    planRecordKey,
    planPackageUuid,
    leadsCount,
    isSupportMode,
    hasPinnedCreditCard,
    timezone,
    accountType,
    isAgency,
    isSubaccount,
    isNormal,
    isTypeOwner,
    isTypeAdmin,
    isTypeClient,
    isTypeManager,
    isTypeDesigner,
    isTypeAccountant,
    isTypeReadOnly,
    hasFreeTrial,
    hasSubscription,
    isActive,
    createdAt,
    expiresAt,
    creditCardExpire,
    subscription,
    subscriptionStatus,
    isFreePlan: FREE_PLANS.some(plan => planRecordKey === plan),
    isRegistrationCompleted: auth?.flow.current_step.name === COMPLETED,
    planName,
    daysLeftToAccountExpiration,
    addonStoreLastSeenDate: addonStoreLastSeen?.last_seen,
    isDefaultRegistration,
    isCreditCardExpired
  }
}

const UserContext = createContext<ReturnType<typeof useUser> | null>(null)

type ProviderProps = {
  children: ReactNode
}

export const UserContextProvider: FC<ProviderProps> = ({ children }) => {
  const value = useUser()

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const value = useContext(UserContext)

  if (!value) {
    throw new Error('useUserContext must be used inside UserContextProvider')
  }

  return value
}
