import { COUNTRY_CODES } from '@components/Topbar/components/Infobars/constants'
import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { useUserContext } from '@contexts/user'
import { Button, Spreader } from '@landingi/landingi-ui-kit'
import { PAYMENTS } from '@routes/path'
import { useGetAccountLimits } from '@services/limits'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const HasSubscription = () => {
  const { t } = useTranslation()

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  const { user, auth, hasPinnedCreditCard } = useUserContext()

  const {
    profile: { country }
  } = user

  const buttonLinkContent =
    country === COUNTRY_CODES.BR
      ? 'infobar.quick.demo.href'
      : 'infobar.schedule.free.onboarding.session.href'

  const buttonContent =
    country === COUNTRY_CODES.BR
      ? 'infobar.quick.demo'
      : 'infobar.schedule.free.onboarding.session'

  const limits = useGetAccountLimits()

  const accountCreationDate = new Date(user.created_at.formatted)
  const trialDays = auth?.free_trial_days || 14

  const trialEndDate = new Date(accountCreationDate)
  trialEndDate.setDate(accountCreationDate.getDate() + trialDays)

  const currentDate = new Date()

  const timeDifference = trialEndDate.getTime() - currentDate.getTime()

  const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  return (
    <Fragment>
      <span className={styles.alert__content}>
        <Trans
          i18nKey='infobar.freetrial.subscription.few-days'
          values={{
            count: days,
            trafficCount: limits.uniqueVisits?.uniqueVisitsCount,
            packageName: user.plan.name,
            regularPaidTrafficLimit: limits.uniqueVisits?.regularLimit
          }}
          components={{
            b: <b />,
            br: <br />
          }}
        />
      </span>

      <Row>
        <Button
          className={styles.alert__button}
          variant='secondary'
          size='tiny'
          tag='a'
          buttonStyle
          href={t(buttonLinkContent) as string}
          target='_blank'
        >
          {t(buttonContent)}
        </Button>

        {hasAccessToPayments && (
          <Fragment>
            <Spreader spread='small' />

            <Button
              className={styles.alert__button}
              size='tiny'
              tag='a'
              buttonStyle
              href={`${NEW_LANDINGI_APP_URL}/${
                hasPinnedCreditCard
                  ? PAYMENTS.SUBSCRIPTION.SUBSCRIPTION
                  : PAYMENTS.PAYMENTS
              }`}
            >
              {t(
                hasPinnedCreditCard
                  ? 'payments.start-your-plan-now'
                  : 'modal.expired.free-trial.button'
              )}
            </Button>
          </Fragment>
        )}
      </Row>
    </Fragment>
  )
}

HasSubscription.displayName = 'TopbarInfobarAccountExpiresAtHasSubscription'
