import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import { ExpandType } from '@components/Sidebar/types'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { UPGRADE_MODALS } from '@constants/modals'
import NiceModal from '@ebay/nice-modal-react'
import {
  FONTS,
  LIGHTBOXES,
  ORDERS,
  POPUPS,
  PRODUCTS,
  SMART_SECTIONS
} from '@routes/path'

export const getMainPath = () => `/${window.location.pathname.split('/')[1]}`

// Temporary solution. To be removed after the release of Smart Sections, Popups, Lightboxes and Fonts.
export const handleOpenTab = (path: string, hasAccessToPath = true) => {
  const upgradeModalName = {
    [SMART_SECTIONS.SMART_SECTIONS]: UPGRADE_MODALS.SMART_SECTIONS,
    [POPUPS.POPUPS_OLD]: UPGRADE_MODALS.POPUPS,
    [LIGHTBOXES.LIGHTBOXES]: UPGRADE_MODALS.LIGHTBOXES,
    [`${FONTS.FONTS}/list`]: UPGRADE_MODALS.FONTS,
    [`/ecommerce${PRODUCTS.PRODUCTS}`]: UPGRADE_MODALS.ECOMMERCE,
    [`/ecommerce${ORDERS.ORDERS}`]: UPGRADE_MODALS.ECOMMERCE
  }

  return hasAccessToPath
    ? window.open(`${NEW_LANDINGI_APP_URL}${path}`, '_self')
    : NiceModal.show(MODAL_UPGRADE_PLAN, {
        upgradeType: upgradeModalName[path]
      })
}

export const groupRoute = (group: ExpandType[]) =>
  group.find(item => item === getMainPath())
