import { useFeatureFlags } from '@contexts/featureFlags'
import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { useStyles } from '@hooks/useStyles'
import { Icon, Spreader } from '@landingi/landingi-ui-kit'
import ADDONS_STORE from '@routes/path/addons'
import { AnimatePresence } from 'framer-motion'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { ItemAnimation } from '../ItemAnimation'
import styles from './Switcher.module.scss'

interface SwitcherProps {
  icon: string
  text: string
  sidebarMini?: boolean
}

export const Switcher: FC<SwitcherProps> = ({ icon, text, sidebarMini }) => {
  const { t } = useTranslation()

  const switcherStyles = useStyles({
    [styles.switcher]: true,
    [styles['switcher--mini']]: sidebarMini
  })

  const { isAgency, isSubaccount, userEmail, planRecordKey } = useUserContext()

  const { toggleWorkspacesOpen } = useSidebarContext()

  const navigate = useNavigate()

  const hasAccessToAddonsStore = useFeatureFlags('ADDONS_STORE')

  const hasAccessToLiteLimitedDevelopment = useFeatureFlags(
    'LITE_LIMITED_DEVELOPMENT'
  )

  const handleOpenAgencyPlanInfo = () => {
    if (hasAccessToAddonsStore) {
      navigate(ADDONS_STORE.AGENCY_ADDON)
      return
    }

    window.open(
      t('sidebar.drawer.url', {
        email: userEmail,
        recordKey: planRecordKey
      }),
      '_blank'
    )
  }

  const handleClick = () => {
    if (hasAccessToLiteLimitedDevelopment || isAgency || isSubaccount) {
      toggleWorkspacesOpen()
    } else {
      handleOpenAgencyPlanInfo()
    }
  }

  return (
    <AnimatePresence>
      <button
        id='sidebar-main-account'
        type='button'
        className={switcherStyles}
        onClick={handleClick}
      >
        <Row alignItems='center' className={styles.switcher__content}>
          <Spreader spread='mini' />

          <Icon icon={icon} />

          <Spreader spread='mini' />

          {sidebarMini ? null : (
            <ItemAnimation>
              <span className={styles.switcher__text}>
                {isAgency || isSubaccount ? text : t('word.main-account')}
              </span>
            </ItemAnimation>
          )}
        </Row>

        {sidebarMini ? null : (
          <ItemAnimation>
            <Icon size={12} icon='icon-chevron-right' color='color-3' />
          </ItemAnimation>
        )}
      </button>
    </AnimatePresence>
  )
}

Switcher.displayName = 'SidebarSwitcher'
