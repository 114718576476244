import client from '@lib/http/client'
import { AxiosError, AxiosResponse } from 'axios'
import useSWR from 'swr'

import { PublicationType } from './list/types'

export interface GetLandingPagePublishingOptionsRequest {
  uuid: string
}

export interface GetLandingPagePublishingOptionsResponse {
  landingPageMainUrl: string
  isLandingPagePublished: boolean
  hasLandingPageCustomDomain: boolean
  landingPagePublicationType: PublicationType
  accountTestDomain: string
  isDedicatedTestDomain: boolean
}

export const useGetLandingPagePublishingOptions = ({
  uuid
}: GetLandingPagePublishingOptionsRequest) =>
  useSWR<GetLandingPagePublishingOptionsResponse, AxiosError>(
    `landing-page/landings/${uuid}/publishing-options`
  )

interface GetLandingPageUrlsRequest {
  uuid: string
}

export type UrlType = {
  identifier: string
  url: string
  domain: string
  path: string
  publicationType: PublicationType
  isMain: boolean
  // isFake - is used to show fake url in the list of urls
  isFake: boolean
}

interface GetLandingPageUrlsResponse {
  collection: UrlType[]
}

export const useGetLandingPageUrls = ({ uuid }: GetLandingPageUrlsRequest) =>
  useSWR<GetLandingPageUrlsResponse, AxiosError>(
    `landing-page/landings/${uuid}/urls`
  )

interface GetLandingPageUrlRequest {
  uuid: string
}

interface GetLandingPageUrlResponse {
  collection: UrlType[]
}

export const getLandingPageUrls = ({ uuid }: GetLandingPageUrlRequest) =>
  client.get<GetLandingPageUrlResponse>(`landing-page/landings/${uuid}/urls`)

export const getLandingPageExternalUrls = ({
  uuid
}: GetLandingPageUrlRequest) =>
  client.get<GetLandingPageUrlResponse>(
    `landing-page/landings/${uuid}/external-urls`
  )

interface TogglePublicationStateRequest {
  uuid: string
}

export const togglePublicationState = ({
  uuid
}: TogglePublicationStateRequest) =>
  client.patch(`landing-page/landings/${uuid}/toggle-publication-state`)

interface AssignDomainToLandingPageRequest {
  uuid: string
  domain: string
  path: string
}

export const assignDomainToLandingPage = ({
  uuid,
  domain,
  path
}: AssignDomainToLandingPageRequest) =>
  client.patch(`landing-page/landings/${uuid}/domain`, { domain, path })

interface SetAsMainOptionRequest {
  uuid: string
  url: string
  type: PublicationType
}

export const setAsMainOption = ({ uuid, url, type }: SetAsMainOptionRequest) =>
  client.patch(`landing-page/landings/${uuid}/main-url`, {
    url,
    type
  })

interface RemoveDomainFromLandingPageRequest {
  uuid: string
  url_uuid: string
}

export const removeUrl = ({
  uuid,
  url_uuid
}: RemoveDomainFromLandingPageRequest) =>
  client.delete(`landing-page/landings/${uuid}/external_urls/${url_uuid}`)

interface GetLandingPageExportPHPRequest {
  uuid: string
}

interface GetLandingPageExportPHPResponse {
  fileUrl: string
}

export const getLandingPageExportPHP = ({
  uuid
}: GetLandingPageExportPHPRequest) =>
  client.get<AxiosResponse<GetLandingPageExportPHPResponse>>(
    `landing-page/landings/${uuid}/export-php`
  )
