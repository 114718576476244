import { Paragraph } from '@ui-kit/Paragraph'
import { FC } from 'react'
import { Row } from 'simple-flexbox'

import { COUNTDOWN } from './constants'
import { CountdownBox } from './CountdownBox'
import styles from './CountdownTimer.module.scss'
import { useCountdown } from './hooks/useCountdown'

interface CountdownTimerProps {
  startDate?: string
  endDate: string
}

const renderColon = () => (
  <Paragraph size={16} color='white' weight={600}>
    :
  </Paragraph>
)

export const CountdownTimer: FC<CountdownTimerProps> = ({
  startDate,
  endDate
}) => {
  const { days, hours, minutes } = useCountdown({ startDate, endDate })

  return (
    <Row
      className={styles['countdown-timer']}
      horizontal='center'
      vertical='center'
      data-testid='countdown-timer'
    >
      <CountdownBox value={days} type={COUNTDOWN.DAYS} />

      {renderColon()}

      <CountdownBox value={hours} type={COUNTDOWN.HOURS} />

      {renderColon()}

      <CountdownBox value={minutes} type={COUNTDOWN.MINUTES} />
    </Row>
  )
}
