import { FREE_PLAN_RECORD_KEY } from '@config/plans'
import { PLN } from '@constants/currencies'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { contactSupport } from '@helpers/support'
import {
  Button,
  CustomLink,
  Divider,
  Heading,
  Icon,
  Paragraph,
  PerfectDropdownSelect,
  Spacer
} from '@landingi/landingi-ui-kit'
import {
  SalePromoBanner,
  SummerSaleBanner
} from '@pages/Authentication/components/SalePromoBanner'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import { useRegistrationConfig } from '@pages/Authentication/helpers/useRegistrationConfig'
import BruttoPrice from '@pages/Authentication/routes/CreditCardStep/components/BruttoPrice'
import NettoPrice from '@pages/Authentication/routes/CreditCardStep/components/NettoPrice'
import styles from '@pages/Authentication/routes/CreditCardStep/CreditCardStep.module.scss'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

const SubscriptionSummaryPanel = () => {
  const { trialPeriod } = useRegistrationConfig()

  const hasSkipCreditCardStep = useFeatureFlags('SKIP_CREDIT_CARD')

  const {
    formik: {
      values,
      isSubmitting,
      isValid,
      dirty,
      setFieldValue,
      setFieldTouched,
      touched,
      errors
    },
    plansSelectOptions,
    billingPeriods,
    bruttoPrice,
    planPrice,
    getFormattedPrice,
    currency,
    vat,
    annualSaleVat,
    hasAccessTo1MonthSale,
    discount,
    discountNet,
    skipPaymentMethod,
    hasAccessToOctoberSale,
    isAnnualSaleToggled,
    handleAnnualSaleToggle,
    hasAccessTo12MonthSale,
    annualSaleDiscountValue
  } = useCreditCardStepContext()

  const { isDefaultRegistration } = useUserContext()

  const { t } = useTranslation()

  const { period, plan } = values

  const getAnnualSaleBanner = () => (
    <SummerSaleBanner
      isChecked={isAnnualSaleToggled}
      handleToggle={handleAnnualSaleToggle}
      discount={annualSaleDiscountValue}
      currency={currency}
    />
  )

  const getSelect = () => {
    if (isDefaultRegistration) {
      return (
        <PerfectDropdownSelect
          formikKey='plan.value'
          options={plansSelectOptions}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          value={values.plan.value}
          error={errors}
          touched={touched}
          hasDescription
        />
      )
    }

    return plansSelectOptions.find(plan => plan.value === values.plan.value)
      .label
  }

  const handleContactSupport = () => contactSupport()

  return (
    <Column className={styles['fixed-block']}>
      <div className={styles['subscription-summary']}>
        <Heading level={2} bold>
          {t(
            plan.value === FREE_PLAN_RECORD_KEY
              ? 'registration.flow.credit.card.subscription.summary.2022.free'
              : 'registration.flow.credit.card.subscription.summary.2022'
          )}
        </Heading>

        <div className={styles['subscription-summary__selects']}>
          <div className={styles['subscription-summary__plan']}>
            <Column>{t('registration.flow.credit.card.plan')}</Column>

            <Column className={styles['subscription-summary__plan-select']}>
              {getSelect()}
            </Column>
          </div>

          {plan.value === FREE_PLAN_RECORD_KEY ? (
            t('creditcardstep.register.summary.moneyforamonth', {
              price: 0,
              currency
            })
          ) : (
            <div className={styles['subscription-summary__period']}>
              <Column>{t('registration.flow.credit.card.billed')}</Column>
              <Column className={styles['subscription-summary__period-select']}>
                <PerfectDropdownSelect
                  formikKey='period.value'
                  options={billingPeriods}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  value={values.period.value}
                  error={errors}
                  touched={touched}
                  hasLabel={false}
                  inModal
                />
              </Column>
            </div>
          )}
        </div>

        {hasAccessTo12MonthSale && (
          <Fragment>
            <Spacer space='small' />

            {getAnnualSaleBanner()}
          </Fragment>
        )}

        <Spacer space='small' />

        <Spacer space='mini' />

        <Spacer space='tiny' />

        {plan.value !== FREE_PLAN_RECORD_KEY && (
          <Fragment>
            <Row justifyContent='space-between'>
              <Column>
                {t('registration.flow.credit.card.step.equation', {
                  price: getFormattedPrice(planPrice / period.value, currency),
                  currency,
                  count: period.value
                })}
              </Column>

              <Column>
                {t('registration.flow.credit.card.step.result', {
                  result: planPrice,
                  currency
                })}

                <NettoPrice currency={currency} />
              </Column>
            </Row>

            {isAnnualSaleToggled && hasAccessTo12MonthSale && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.october.discount')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.discounted.price', {
                      currency,
                      discount: discountNet
                    })}

                    <NettoPrice currency={currency} />
                  </Column>
                </Row>
              </Fragment>
            )}

            {currency === PLN && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.vat.23')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.vat.value', {
                      currency,
                      vat:
                        isAnnualSaleToggled && hasAccessTo12MonthSale
                          ? annualSaleVat
                          : vat
                    })}
                  </Column>
                </Row>
              </Fragment>
            )}

            {hasAccessToOctoberSale && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.october.discount')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.discounted.price', {
                      currency,
                      discount
                    })}
                    <BruttoPrice currency={currency} />
                  </Column>
                </Row>
              </Fragment>
            )}

            {hasAccessTo1MonthSale && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.october.discount')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.discounted.price', {
                      currency,
                      discount
                    })}

                    <BruttoPrice currency={currency} />
                  </Column>
                </Row>
              </Fragment>
            )}

            <Spacer space='mini' />

            <Spacer space='tiny' />

            <Divider />

            <Spacer space='mini' />

            <Spacer space='tiny' />
          </Fragment>
        )}

        <Row justifyContent='space-between' alignItems='center'>
          <Column>
            <Trans
              i18nKey={
                plan.value === FREE_PLAN_RECORD_KEY
                  ? t('registration.flow.credit.card.step.total.due')
                  : t('registration.flow.credit.card.step.total.due.today')
              }
            />
          </Column>

          <Column
            className={styles['subscription-summary__total-due-today-price']}
          >
            {t('registration.flow.credit.card.step.total.due.today.price', {
              currency
            })}
          </Column>
        </Row>

        {plan.value !== FREE_PLAN_RECORD_KEY && (
          <Fragment>
            <Spacer space='small' />

            <Row justifyContent='space-between' alignItems='center'>
              <Column>
                {t('registration.flow.credit.card.step.total.due.in.days', {
                  days: trialPeriod
                })}
              </Column>

              <Column>
                {t(
                  'registration.flow.credit.card.step.total.due.in.days.price',
                  {
                    currency,
                    price: bruttoPrice
                  }
                )}

                <BruttoPrice currency={currency} />
              </Column>
            </Row>
          </Fragment>
        )}

        <Spacer space='tiny' />

        {hasAccessToOctoberSale && (
          <SalePromoBanner
            promoPeriodText={t('october.sale.promo.for.3.months')}
          />
        )}

        {hasAccessTo1MonthSale && (
          <Fragment>
            <Spacer space='tiny' />

            <SalePromoBanner
              promoPeriodText={t('october.sale.promo.for.1.month')}
            />
          </Fragment>
        )}

        <Spacer space='mini' />

        {!hasAccessToOctoberSale ||
          (!hasAccessTo1MonthSale && <Spacer space='small' />)}

        <Button
          size='large'
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          data-testid='submit-button'
          fitWidth
        >
          {plan.value === FREE_PLAN_RECORD_KEY
            ? t('registration.flow.credit.card.step.start.on.free.plan', {
                days: trialPeriod
              })
            : t('registration.flow.credit.card.step.start.free.trial', {
                days: trialPeriod
              })}
        </Button>

        {hasSkipCreditCardStep && (
          <Fragment>
            <Spacer space='tiny' />

            <Button
              variant='secondary-outlined'
              onClick={skipPaymentMethod}
              size='large'
              fitWidth
            >
              {t('registration.flow.credit.card.step.skip.payment.method')}
            </Button>
          </Fragment>
        )}

        {hasAccessToOctoberSale && (
          <Fragment>
            <Spacer space='mini' />
            <Paragraph padding='none' size={10} color='accent-2'>
              {t('october.sale.discount.has.been.applied')}
            </Paragraph>
          </Fragment>
        )}

        {hasAccessTo1MonthSale && (
          <Fragment>
            <Spacer space='mini' />
            <Paragraph padding='none' size={10} color='accent-2'>
              {t('october.sale.discount.has.been.applied.1m')}
            </Paragraph>
          </Fragment>
        )}

        {hasAccessTo12MonthSale && (
          <Fragment>
            <Spacer space='mini' />
            <Paragraph padding='none' size={10} color='accent-2'>
              {t('summer.sale.discount.has.been.applied')}
            </Paragraph>
          </Fragment>
        )}

        <Spacer space='mini' />

        <Spacer space='tiny' />

        <Column className={styles['subscription-summary__footer']}>
          {plan.value !== FREE_PLAN_RECORD_KEY && (
            <Row>
              <Icon
                icon='icon-ok'
                className={styles['subscription-summary__footer__icon-ok']}
              />
              <Paragraph size={12}>
                {t(
                  'registration.flow.credit.card.step.we.will.send.you.email.reminder'
                )}
              </Paragraph>
            </Row>
          )}
          <Row>
            <Icon
              icon='icon-ok'
              className={styles['subscription-summary__footer__icon-ok']}
            />
            <Paragraph size={12}>
              <Trans
                i18nKey={
                  plan.value === FREE_PLAN_RECORD_KEY
                    ? 'registration.flow.credit.card.step.you.can.change.plan.to.professional'
                    : 'registration.flow.credit.card.step.you.can.cancel'
                }
              />
            </Paragraph>
          </Row>

          <Row>
            <Icon
              icon='icon-shield'
              className={styles['subscription-summary__footer__icon-shield']}
            />
            <Paragraph size={12} padding='none'>
              {t('registration.flow.credit.card.step.30.day.money.back')}
            </Paragraph>
          </Row>
        </Column>
      </div>

      <Spacer space='medium' />

      {!hasSkipCreditCardStep && (
        <Column className={styles['contact-us-info-text-container']}>
          <Row className={styles['contact-us-info-text']}>
            <Paragraph size={14}>
              {t('registration.flow.credit.card.step.need.to.start')}{' '}
              <CustomLink
                onClick={handleContactSupport}
                label={t('registration.flow.credit.card.step.contact.us')}
                className={styles['contact-us__link']}
              />
            </Paragraph>
          </Row>
        </Column>
      )}
    </Column>
  )
}

SubscriptionSummaryPanel.displayName = 'SubscriptionSummaryPanel'

export default SubscriptionSummaryPanel
