import { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import { Button, Input, Spacer, Spreader } from '@landingi/landingi-ui-kit'
import { resetLanding } from '@services/landings/reset'
import { useFormik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

interface LandingModalResetFormProps {
  uuid: string
}

export const LandingModalResetForm: FC<LandingModalResetFormProps> = ({
  uuid
}) => {
  const { t } = useTranslation()

  const { remove } = useModal()

  const { mutate } = useSWRConfig()

  const keysToMutate = [
    `landing-page/landings/${uuid}/get-statistics`,
    `landing-page/landings/${uuid}/events/table-and-chart`,
    `landing-page/landings/${uuid}/events/top-events`,
    `landing-page/landings/${uuid}/scroll`
  ]

  const [handleReset, { isLoading }] = useLazyService(resetLanding, {
    successToastText: t('modal.landing.reset.toast.success'),
    errorToastText: t('toast.error.something.went.wrong'),
    onSuccess: () => {
      mutate(
        (key: string) =>
          keysToMutate.some(keyFromArray => key.includes(keyFromArray)),
        undefined,
        {
          revalidate: true
        }
      )

      remove()
    }
  })

  const validationSchema = toFormikValidationSchema(
    z.object({
      reset: z.string().refine(value => value.match(/^reset$/i))
    })
  )

  const { isSubmitting, handleSubmit, isValid, handleChange, values, dirty } =
    useFormik({
      initialValues: {
        reset: ''
      },
      validationSchema,
      onSubmit: async () => {
        await handleReset({ uuid })
      }
    })

  return (
    <form onSubmit={handleSubmit}>
      <Row wrap>
        <Column flex='1'>
          <Input
            name='reset'
            i18n={{
              placeholder: t('form.input.placeholder.reset')
            }}
            onChange={handleChange}
            value={values.reset}
          />
        </Column>
      </Row>

      <Spacer />

      <Row flex='1' justifyContent='end'>
        <Row>
          <Button variant='secondary' size='medium' onClick={remove}>
            {t('label.cancel')}
          </Button>

          <Spreader spread='small' />

          <Button
            size='medium'
            onClick={() => handleSubmit()}
            isLoading={isSubmitting || isLoading}
            isDisabled={!dirty || !isValid || isSubmitting}
          >
            {t('word.reset.lp')}
          </Button>
        </Row>
      </Row>
    </form>
  )
}

LandingModalResetForm.displayName = 'LandingModalResetForm'
