import { useFeatureFlags } from '@contexts/featureFlags'

export const PLANS = {
  CORE_20: 'core_20',
  CORE_21: 'core_21',
  CREATE_20: 'create_20',
  CREATE_21: 'create_21',
  AUTOMATE_20: 'automate_20',
  AUTOMATE_21: 'automate_21',
  AGENCY_20: 'agency_20',
  AGENCY_21: 'agency_21',
  AGENCY_22: 'agency_22',
  FREE_22: 'free_22',
  FREE2LP_22: 'free2lp_22',
  LITE_22: 'lite_22',
  LITE_24: 'lite_24',
  LITE_DISC_22: 'lite_disc_22',
  PROFESSIONAL_22: 'professional_22',
  PROFESSIONAL_24: 'professional_24',
  PROFESSIONAL_DISC_22: 'professional_disc_22',
  BR_PROFESSIONAL_22: 'br_professional_22',
  BR_ESSENTIAL_22: 'br_essential_22',
  BR_PAGEX_22: 'br_pagex_22',
  APP_SUMO: 'APP_SUMO',
  UNLIMITED_22: 'unlimited_22',
  PROFESSIONAL_100K_23: 'professional_uv100k_23',
  PROFESSIONAL_200K_23: 'professional_uv200k_23',
  PROFESSIONAL_500K_23: 'professional_uv500k_23',
  PROFESSIONAL_1M_23: 'professional_uv1m_23',
  AGENCY_100K_23: 'agency_uv100k_23',
  AGENCY_200K_23: 'agency_uv200k_23',
  AGENCY_500K_23: 'agency_uv500k_23',
  AGENCY_1M_23: 'agency_uv1m_23',
  FREE_LIMITED_24: 'free_limited_24',
  LITE_LIMITED_24: 'lite_limited_24'
} as const

export const FREE_PLANS = [
  PLANS.FREE_22,
  PLANS.FREE2LP_22,
  PLANS.APP_SUMO,
  PLANS.FREE_LIMITED_24
] as const

export const PROFESSIONAL_PLANS = [
  PLANS.PROFESSIONAL_22,
  PLANS.PROFESSIONAL_100K_23,
  PLANS.PROFESSIONAL_200K_23,
  PLANS.PROFESSIONAL_500K_23,
  PLANS.PROFESSIONAL_1M_23
] as const

export const PROFESSIONAL_PLANS_24 = [
  PLANS.PROFESSIONAL_24,
  PLANS.PROFESSIONAL_100K_23,
  PLANS.PROFESSIONAL_200K_23,
  PLANS.PROFESSIONAL_500K_23,
  PLANS.PROFESSIONAL_1M_23
] as const

export const AGENCY_PLANS = [
  PLANS.AGENCY_22,
  PLANS.AGENCY_100K_23,
  PLANS.AGENCY_200K_23,
  PLANS.AGENCY_500K_23,
  PLANS.AGENCY_1M_23
] as const

export const plans2022 = [
  {
    name: 'Free',
    recordKey: PLANS.FREE_22
  },
  {
    name: 'Lite',
    recordKey: PLANS.LITE_22
  },
  {
    name: 'Professional',
    recordKey: PLANS.PROFESSIONAL_22
  },
  {
    name: 'Agency',
    recordKey: PLANS.AGENCY_22
  },
  {
    name: 'Unlimited',
    recordKey: PLANS.UNLIMITED_22
  }
]

export const plans2024 = [
  {
    name: 'Free',
    recordKey: PLANS.FREE_22
  },
  {
    name: 'Lite',
    recordKey: PLANS.LITE_22
  },
  {
    name: 'Professional',
    recordKey: PLANS.PROFESSIONAL_24
  },
  {
    name: 'Agency',
    recordKey: PLANS.AGENCY_22
  },
  {
    name: 'Unlimited',
    recordKey: PLANS.UNLIMITED_22
  }
]

export const useFreePlan = () => {
  const LIMITED_PLANS_FF = useFeatureFlags('LIMITED_PLANS')

  if (LIMITED_PLANS_FF) {
    return PLANS.FREE_LIMITED_24
  }

  return PLANS.FREE_22
}

export const useLitePlan = () => {
  const LITE_24_TEST_FF = useFeatureFlags('LITE_24_TEST')
  const LIMITED_PLANS_FF = useFeatureFlags('LIMITED_PLANS')

  if (LIMITED_PLANS_FF) {
    return PLANS.LITE_LIMITED_24
  }

  if (LITE_24_TEST_FF) {
    return PLANS.LITE_24
  }

  return PLANS.LITE_22
}

export const usePlans = (planRecordKey: string) => {
  const PRICING_2022_FF = useFeatureFlags('PRICING_2022')
  const freePlanRecordKey = useFreePlan()
  const litePlanRecordKey = useLitePlan()

  const plans = {
    free: {
      name: 'Free',
      recordKey: freePlanRecordKey
    },
    lite: {
      name: 'Lite',
      recordKey: litePlanRecordKey
    },
    professional: {
      name: 'Professional',
      recordKey: PRICING_2022_FF ? PLANS.PROFESSIONAL_24 : PLANS.PROFESSIONAL_22
    },
    agency: {
      name: 'Agency',
      recordKey: PLANS.AGENCY_22
    },
    unlimited: {
      name: 'Unlimited',
      recordKey: PLANS.UNLIMITED_22
    }
  }

  const { free, lite, professional, agency, unlimited } = plans

  const defautPlans = [lite, professional, unlimited]

  if (planRecordKey === PLANS.AGENCY_22) {
    return [lite, professional, agency, unlimited]
  }

  if (planRecordKey === freePlanRecordKey) {
    return [free, ...defautPlans]
  }

  return defautPlans
}

export const ACCOUNT_TYPES = { BUSINESS: 'business', AGENCY: 'agency' }
