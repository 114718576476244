export const ITEMS = {
  SUBACCOUNTS: 'SUBACCOUNTS',
  SETTINGS: 'SETTINGS',
  PAYMENT_GATEWAYS: 'PAYMENT_GATEWAYS',
  USERS: 'USERS',
  BETA_FEATURES: 'BETA_FEATURES',
  INVOICES: 'INVOICES',
  PLAN_AND_BILLING: 'PLAN_AND_BILLING'
} as const

export type ItemsTypes = keyof typeof ITEMS
