import {
  Button,
  Divider,
  emitCloseDropdown,
  Icon,
  List,
  ListItem,
  Spreader
} from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import styles from './TestDomain.module.scss'

interface TestDomainProps {
  setValues: (
    values: SetStateAction<{
      domain: string
      path: string
    }>
  ) => void
}

export const TestDomain: FC<TestDomainProps> = ({ setValues }) => {
  const { t } = useTranslation()

  const {
    publishingOptions: {
      data: { accountTestDomain }
    }
  } = usePublishingOptionsContext()

  return (
    <List>
      <Divider variant='dropdown' />

      <ListItem variant='dropdown'>
        <Button
          className={styles['button-test-domain']}
          variant='dropdown-element'
          onClick={() => {
            setValues({ domain: accountTestDomain, path: uuidv4() })

            emitCloseDropdown()
          }}
        >
          <Icon icon='icon-flask-fill' color='warning' />

          <Spreader spread='tiny' />

          {t('publishing.options.change.url.test.domain', {
            domain: accountTestDomain
          })}
        </Button>
      </ListItem>
    </List>
  )
}

TestDomain.displayName = 'PublishingOptionsViewsChangeUrlTestDomain'
