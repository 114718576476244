import { PROD, SEMRUSH_SENTRY_DSN } from '@config/env'
import SemrushErrorImage from '@images/semrush/SemrushErrorImage.png'
import { Heading, Image, Spacer } from '@landingi/landingi-ui-kit'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import styles from './SemrushErrorPage.module.scss'

export const SemrushErrorPage = () => {
  useEffect(() => {
    if (PROD) {
      Sentry.init({
        dsn: SEMRUSH_SENTRY_DSN,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            )
          })
        ],
        tracesSampleRate: 1,
        beforeSend(event) {
          if (!event.extra?.['semrush-error']) {
            return null
          }

          return event
        }
      })

      Sentry.withScope(scope => {
        scope.setExtra('semrush-error', true)
        Sentry.captureMessage(
          'semrush error - redirected to react registration page due to unauthorized redirection'
        )
      })
    }
  }, [])

  return (
    <div className={styles.semrushErrorPageLayout}>
      <Image src={SemrushErrorImage} size='740px' height={300} />

      <Heading level={1} margin='none'>
        <Trans i18nKey='word.whoops' />
      </Heading>

      <Spacer space='small' />

      <Heading level={3}>
        <Trans i18nKey='semrush.error.page.try.refresh.page' />
      </Heading>
    </div>
  )
}
SemrushErrorPage.displayName = 'SemrushErrorPage'
