import { AxiosError } from 'axios'
import useSWR from 'swr'

import { PublicationType } from './list/types'

interface GetLandingParams {
  uuid?: string
}

export type LandingData = {
  identifier: string
  account_uuid: string
  name: string
  thumbnail_url: string
  domain_url: string
  publication_type: PublicationType
  created_at: string
  updated_at: string
  master_hash: string
  group_uuid: string | null
  group_name: string
  test_enabled: boolean
  schedule_enabled: boolean
  camino_lid: string | null
  is_published: boolean
  is_archived: boolean
  smart_sections_count: number
  new_camino_lid: string
}

export interface GetLandingResponse {
  data: LandingData
}

export const useGetLanding = ({ uuid }: GetLandingParams) =>
  useSWR<GetLandingResponse, AxiosError>(
    uuid ? `landing-page/landing/${uuid}` : null
  )
