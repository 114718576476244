import { ErrorBoundary } from '@components/ErrorBoundary'
import { ErrorBoundaryFallback } from '@components/ErrorBoundaryFallback'
import { RouterTabs } from '@components/RouterTabs'
import { useFeatureFlags } from '@contexts/featureFlags'
import { Loader } from '@landingi/landingi-ui-kit'
import { Header } from '@pages/Landings/routes/Dashboard/components/Header'
import { NotFound } from '@pages/NotFound/routes/NotFound'
import { useGetAccountInfo } from '@services/account'
import { Layout as GridLayout, Spacer } from '@ui-kit'
import { Fragment, Suspense, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Outlet, useParams } from 'react-router-dom'

import { DashboardProvider, useDashboardContext } from './context'
import { useRoutesPaths } from './routes'

const DashboardContent = () => {
  const { identifier } = useParams()

  const { t } = useTranslation()

  const { isTypeClient, isTypeManager } = useGetAccountInfo()

  const hasAccessToEventTracker = useFeatureFlags('EVENT_TRACKER')

  const userHasNotAccessToEventTracker =
    (isTypeClient || isTypeManager) && !hasAccessToEventTracker

  const hasNotAccessToEventTrackerTab =
    !useFeatureFlags('EVENT_TRACKER_TAB') || userHasNotAccessToEventTracker

  const hasNotAccessToProgrammaticLandingPages =
    !useFeatureFlags('PROGRAMMATIC_LP')

  const { landing, isLoading, errorLanding } = useDashboardContext()

  const routesPaths = useRoutesPaths()

  const routes = useMemo(() => {
    const hidden = {
      hasNotAccessToEventTrackerTab,
      hasNotAccessToProgrammaticLandingPages
    } as const

    return identifier
      ? routesPaths.map(({ path, title, isHidden, ...rest }) => ({
          path: generatePath(path, { identifier }),
          title: t(title),
          isHidden: hidden[isHidden as keyof typeof hidden] ?? false,
          ...rest
        }))
      : []
  }, [
    identifier,
    t,
    hasNotAccessToEventTrackerTab,
    hasNotAccessToProgrammaticLandingPages,
    routesPaths
  ])

  return (
    <GridLayout>
      {isLoading && <Loader />}

      {!isLoading && landing && (
        <Fragment>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>

          <Spacer space={30} />

          <RouterTabs routes={routes} />

          <Spacer space={30} />

          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>

          <Spacer space={70} />
        </Fragment>
      )}

      {!isLoading &&
        errorLanding &&
        (errorLanding?.response?.status === 404 ? (
          <NotFound />
        ) : (
          <ErrorBoundaryFallback />
        ))}
    </GridLayout>
  )
}

export const Dashboard = () => (
  <DashboardProvider>
    <DashboardContent />
  </DashboardProvider>
)
