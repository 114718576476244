import { SemrushErrorPage } from '@components/SemrushErrorPage/SemrushErrorPage'
import { getPricePageUrl } from '@constants/pricePageUrls'
import { useUserContext } from '@contexts/user'
import { isInIframe } from '@helpers/iframe'
import { removeLocalStorage } from '@helpers/storage'
import { useHead } from '@hooks/useHead'
import useQuery from '@hooks/useQuery'
import { useValidateChangePasswordToken } from '@hooks/useValidateChangePasswordToken'
import { getRouteBasedOnCurrentStep } from '@pages/Authentication/helpers/steps'
import { INVOICES, LANDINGS, REGISTRATION } from '@routes/path'
import { TWO_FACTOR_AUTHENTICATION_ROUTES } from '@routes/path/registration'
import { FC } from 'react'
import { matchPath, Navigate, useLocation } from 'react-router-dom'

interface UnauthenticatedProps {
  children: JSX.Element | null
}

export const Unauthenticated: FC<UnauthenticatedProps> = ({ children }) => {
  const { isAuth, auth, isLoading, authError, isTypeAccountant } =
    useUserContext()

  const queryParams = useQuery()

  removeLocalStorage('logout-path')

  const { pathname } = useLocation()

  const { hasAccessToPasswordChange, isChangePasswordTokenValidating } =
    useValidateChangePasswordToken()

  useHead()

  if (isLoading || isChangePasswordTokenValidating) {
    return null
  }

  if (isInIframe()) {
    return <SemrushErrorPage />
  }

  if (hasAccessToPasswordChange) {
    return <Navigate to={REGISTRATION.LOGIN.DEFAULT} />
  }

  if (
    [REGISTRATION.REGISTER.DEFAULT, REGISTRATION.SIGNUP.DEFAULT].find(path =>
      matchPath(path, pathname)
    ) &&
    !queryParams.has('package')
  ) {
    const languageFromQueryParams = queryParams.get('lang')

    const pricingPageUrl = getPricePageUrl(languageFromQueryParams)

    const queryString = queryParams.toString()

    const fullRedirectUrl = queryString
      ? `${pricingPageUrl}?${queryString}`
      : pricingPageUrl

    window.location.assign(fullRedirectUrl)

    return null
  }

  if (
    authError?.error?.message !== 'Access Denied - 2FA Required' &&
    TWO_FACTOR_AUTHENTICATION_ROUTES.find(path => matchPath(path, pathname))
  ) {
    return <Navigate to={REGISTRATION.LOGIN.DEFAULT} />
  }

  if (isAuth && auth) {
    const redirectRoute = getRouteBasedOnCurrentStep(
      auth.flow.current_step as unknown as string
    )

    if (redirectRoute) {
      return <Navigate to={redirectRoute} />
    }

    if (isTypeAccountant) {
      return <Navigate to={INVOICES.INVOICES} />
    }

    return <Navigate to={LANDINGS.LANDINGS} />
  }

  return children
}
