import { Button, InputForm, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { REGISTRATION } from '@routes/path'
import { getAuthInfo, verify2FACode } from '@services/authentication'
import { emitTimingToast } from '@ui-kit'
import { useFormik } from 'formik'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import FormSchema from './FormSchema'

const TwoFactorAuthenticationForm = () => {
  const { t } = useTranslation()
  const { clientName } = useParams()
  const { getWhitelabelPageConfig } = useWhitelabelPageContext()
  const { mainColor, buttonStyle } = getWhitelabelPageConfig()

  const initialValues = {
    code: ''
  }

  const onSubmit = async (values, { setFieldError }) => {
    const { code } = values

    try {
      await verify2FACode(code)

      const authInfo = await getAuthInfo()
      const { flow } = authInfo
      const { current_step, completed_step } = flow
      const { name } = current_step

      // setCurrentStepRoute(name, history, {}, completed_step?.name)
    } catch {
      emitTimingToast({
        message: t('two.factor.authentication.this.code.is.invalid'),
        type: 'error'
      })

      setFieldError('code', t('two.factor.authentication.wrong.code.error'))
    }
  }

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    dirty
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: FormSchema
  })

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <Row>
          <Column flexGrow='1'>
            <InputForm
              data-testid='code'
              field={{
                name: 'code',
                value: values.code,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              i18n={{
                label: t('two.factor.authentication.6.digit.code'),
                placeholder: t('two.factor.authentication.6.digit.code')
              }}
              id='code'
              maxLength={6}
              form={{
                errors,
                touched
              }}
            />
          </Column>
        </Row>

        <Button
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          size='large'
          customEnabledBackgroundColor={
            buttonStyle?.backgroundColor || mainColor
          }
          customStyle={{ ...buttonStyle }}
        >
          {t('two.factor.authentication.verify')}
        </Button>

        <Fragment>
          <Spacer space='mini' />

          <Spacer space='tiny' />

          <Paragraph size={12} color='accent-2' align='center' padding='none'>
            {t('two.factor.authentication.dont.have.mobile.device')}
            <Link
              style={{ color: mainColor }}
              to={`${REGISTRATION.RECOVERY_CODE.DEFAULT}/${clientName}`}
            >
              {t('two.factor.authentication.dont.have.mobile.device.link')}
            </Link>
          </Paragraph>
        </Fragment>
      </Column>
    </form>
  )
}

TwoFactorAuthenticationForm.displayName = 'TwoFactorAuthenticationForm'

export default TwoFactorAuthenticationForm
