import { ADDONS_IDS } from '@constants/addonsIds'
import { singleLandingPageDesignPrices } from '@constants/addonsPrices'
import LandingPageDesign from '@images/addons/icons/LandingPageDesign.svg'
import {
  ONE_OFF_PAYMENT,
  SERVICE
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { LandingPageDesignSecondaryButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import {
  SERVICE as ADDON_TYPE_SERVICE,
  ServiceAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getOverview } from './Overview'
import { getServices } from './Services'
import { getUseCases } from './UseCases'

export const getLandingPageDesignAddon: (
  t: TFunction<'translation', undefined>
) => ServiceAddonTemplate = t => ({
  type: ADDON_TYPE_SERVICE,
  name: t('addons.store.landing.page.design'),
  title: t('addons.store.landing.page.design'),
  tileDescription: (
    <Trans i18nKey='addons.store.landing.page.design.description' />
  ),
  pageDescription: t('addons.store.landing.page.design.page.description'),
  badges: [SERVICE, ONE_OFF_PAYMENT],
  imageSrc: LandingPageDesign,
  id: ADDONS_IDS.LANDING_PAGE_DESIGN,
  overview: getOverview(t),
  services: getServices(),
  useCases: getUseCases(t),
  price: singleLandingPageDesignPrices,
  faq: getFAQ(t),
  secondaryButton: LandingPageDesignSecondaryButton
})
