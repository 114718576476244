import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal from '@ebay/nice-modal-react'
import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import { useLazyService } from '@hooks/useLazyService'
import {
  Button,
  emitCloseDropdown,
  Icon,
  List,
  ListItem,
  PerfectDropdown,
  Spreader,
  Tooltip
} from '@landingi/landingi-ui-kit'
import { MODAL_REMOVE_URL } from '@pages/Landings/routes/LandingsPublishingOptions/components/Modals/ModalRemoveUrl'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { PublicationType } from '@services/landings/list/types'
import {
  assignDomainToLandingPage,
  setAsMainOption
} from '@services/landings/publishingOptions'
import { FC, Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'
import { v4 as uuidv4 } from 'uuid'

interface RowActionsProps {
  publicationType: PublicationType
  isMain: boolean
  url: string
  domain: string
  path: string
  identifier: string
  isFake: boolean
}

export const RowActions: FC<RowActionsProps> = ({
  publicationType,
  isMain,
  url,
  domain,
  path,
  identifier,
  isFake
}) => {
  const { t } = useTranslation()

  const [handleCopy] = useCopyToClipboard(url)

  const {
    publishingOptions: {
      data: { isLandingPagePublished, accountTestDomain, isDedicatedTestDomain }
    },
    uuid,
    handleChangeUrl,
    setPublicationType,
    landingPageUrls: { handleRefreshlUrls }
  } = usePublishingOptionsContext()

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const freePlanRestrictionsFeatureFlag = useFeatureFlags(
    'FREE_PLAN_RESTRICTIONS'
  )

  const isFreePlanRestrictions =
    freePlanRestrictionsFeatureFlag && !hasAllowTestAutoDomainFeatureFlag

  const isCustomDomain = publicationType === TYPES.CUSTOM
  const isWordpress = publicationType === TYPES.WORDPRESS
  const isEmbed = publicationType === TYPES.EMBED

  const isRestrictionForFreeAccount =
    isFreePlanRestrictions && isCustomDomain && isMain

  const renderDropdown = !(
    (!isCustomDomain && isMain) ||
    isRestrictionForFreeAccount
  )

  const renderSetAsMain = !isMain

  const renderRemoveButton = (isWordpress || isEmbed) && !isMain

  const [assignDomainToLandingPageRequest] = useLazyService(
    assignDomainToLandingPage,
    {
      onSuccess: () => {
        handleRefreshlUrls()

        setPublicationType({ publicationType: TYPES.TEST })
      },
      successToastText: t('publishing.options.change.url.toast.success'),
      errorToastText: t('toast.error.something.went.wrong')
    }
  )

  const handleSwitchToTestDomain = () =>
    assignDomainToLandingPageRequest({
      uuid,
      domain: accountTestDomain,
      path: `/${uuidv4()}`
    })

  const [setAsMainOptionRequest] = useLazyService(setAsMainOption, {
    onSuccess: () => {
      handleRefreshlUrls()

      setPublicationType({ publicationType })
    },
    successToastText: t('publishing.options.actions.setmain.toast.success'),
    errorToastText: t('toast.error.something.went.wrong')
  })

  const handleSetAsMain = () =>
    setAsMainOptionRequest({ uuid, type: publicationType, url })

  const handleRemoveUrl = () => {
    emitCloseDropdown()

    NiceModal.show(MODAL_REMOVE_URL, {
      uuid,
      url_uuid: identifier
    })
  }

  const handleOpen = useCallback(() => window.open(url, '_blank'), [url])

  if (isFake) return null

  return (
    <Row>
      <Tooltip content={t('copy.link.to.clipboard')} placement='top'>
        <Button
          variant='icon-transparent-hover'
          onClick={handleCopy}
          isDisabled={!isLandingPagePublished}
        >
          <Icon icon='icon-link' />
        </Button>
      </Tooltip>

      <Tooltip
        content={t('publishing.options.actions.new.tab')}
        placement='top'
      >
        <Button
          variant='icon-transparent-hover'
          onClick={handleOpen}
          isDisabled={!isLandingPagePublished}
        >
          <Icon icon='icon-external-link-alt' />
        </Button>
      </Tooltip>

      {(isCustomDomain || isDedicatedTestDomain) && (
        <Fragment>
          <Spreader spread='small' />

          <Button
            variant='transparent'
            isDisabled={!isLandingPagePublished}
            onClick={() => handleChangeUrl(domain, path)}
          >
            {t('publishing.options.change.url')}
          </Button>
        </Fragment>
      )}

      {renderDropdown && (
        <PerfectDropdown hasArrow={false} icon='icon-ellipsis-v' size='auto'>
          <List>
            {isCustomDomain && !isFreePlanRestrictions && (
              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  onClick={handleSwitchToTestDomain}
                  hasIcon
                  isDisabled={!isLandingPagePublished || !isCustomDomain}
                >
                  <Icon icon='icon-flask-fill' />
                  {t('publishing.options.actions.switch')}
                </Button>
              </ListItem>
            )}

            {renderSetAsMain && (
              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  onClick={handleSetAsMain}
                  hasIcon
                  isDisabled={!isLandingPagePublished}
                >
                  <Icon icon='icon-star' />
                  {t('publishing.options.actions.setmain')}
                </Button>
              </ListItem>
            )}

            {renderRemoveButton && (
              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  onClick={handleRemoveUrl}
                  hasIcon
                  isDisabled={!isLandingPagePublished}
                >
                  <Icon icon='icon-trash' />
                  {t('word.remove')}
                </Button>
              </ListItem>
            )}
          </List>
        </PerfectDropdown>
      )}
    </Row>
  )
}

RowActions.displayName = 'PublishingOptionsViewsMainTableRowActions'
