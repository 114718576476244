import { useStyles } from '@hooks/useStyles'
import { NeutralColor, PrimaryColor } from '@ui-kit/types/color'
import { FC, ReactNode } from 'react'

import styles from './Heading.module.scss'
import { HeadingTagType } from './types'

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

export interface HeadingProps {
  children: ReactNode
  className?: string | string[]
  /** @default 1 */
  level?: HeadingLevel
  size?: 'large'
  /** @default 'neutral' */
  color?: NeutralColor | PrimaryColor
  /** @default 400 */
  weight?: 400 | 600
  /** @default 'left' */
  align?: 'left' | 'center' | 'right' | 'justify'
  /** @default 'heading' */
  'data-testid'?: string
}

export const Heading: FC<HeadingProps> = ({
  children,
  className,
  level = 1,
  size,
  color = 'neutral',
  weight = 400,
  align = 'left',
  'data-testid': dataTestId = 'heading'
}) => {
  const headingStyles = useStyles(
    {
      [styles.heading]: true,
      [styles[`heading--level-${level}`]]: level,
      [styles[`heading--size-${size}`]]: size,
      [styles[`heading--color-${color}`]]: color,
      [styles[`heading--weight-${weight}`]]: weight,
      [styles[`heading--align-${align}`]]: align
    },
    className
  )

  const HeadingTag: HeadingTagType = `h${level}`

  return (
    <HeadingTag data-testid={dataTestId} className={headingStyles}>
      {children}
    </HeadingTag>
  )
}

Heading.displayName = 'Heading'
