import { BillingPeriodType } from '@constants/billingPeriods'
import { useAcl } from '@contexts/acl'
import client from '@lib/http/client'
import { AxiosError } from 'axios'
import useSWR from 'swr'

export const buyAddon = ({
  addonUuid,
  nonce
}: {
  addonUuid: string
  nonce?: string
}) =>
  client.post(
    `/payments/product-addon/buy`,
    {
      uuid: addonUuid
    },
    { headers: { 'braintree-token': nonce } }
  )

export const requestAddon = ({
  addonUuid,
  billing_period
}: {
  addonUuid: string
  billing_period?: BillingPeriodType
}) =>
  client.post(`/payments/product-addon/request`, {
    uuid: addonUuid,
    billing_period
  })

interface ChangePlanRequest {
  addonName: string
}

export const addUserToAddonWaitList = ({ addonName }: ChangePlanRequest) =>
  client.post(`payments/product-addon/waitlist/${addonName}`)

export const suggestFeature = (suggestion: string) =>
  client.post(`/payments/features/suggest`, {
    featureDescription: suggestion
  })

export const purchaseService = ({
  serviceName,
  nonce
}: {
  serviceName: string
  nonce: string
}) =>
  client.post(
    `payments/services/charge`,
    { serviceName },
    { headers: { 'braintree-token': nonce } }
  )

export const updateAddonStoreLastSeenDate = () =>
  client.put(`payments/product-addon/last_seen`)

export const requestService = ({ serviceName }: { serviceName: string }) =>
  client.post(`payments/services/request`, { serviceName })

export const useGetWaitlistInfo = (addonId?: string) => {
  const hasAccessToAddonsMarketplaceManagement = useAcl(
    'payments.product_add_on.management'
  )

  const { data: waitlistInfo, isLoading } = useSWR(
    addonId && hasAccessToAddonsMarketplaceManagement
      ? `payments/product-addon/waitlist/${addonId}`
      : null
  )

  return { waitlistInfo, isLoading }
}

interface SummarySubscriptionResponse {
  next_billing_date: string
  next_billing_amount: number
}

export const useSubscriptionSummary = () => {
  const { data: subscriptionData, isLoading: isSubscriptionDataLoading } =
    useSWR<SummarySubscriptionResponse, AxiosError>(
      'payments/subscriptions/summary'
    )

  return { subscriptionData, isSubscriptionDataLoading }
}

export const useGetAddonChargeInfo = (
  addonUuid?: string,
  hasUsedTrial?: boolean
) => {
  const params = new URLSearchParams({
    withoutProductAddOnTrial: hasUsedTrial ? '1' : '0'
  })

  const { data: chargeInfo, isLoading: isChargeInfoLoading } = useSWR(
    `payments/product-addon/charge/calculate/${addonUuid}?${params.toString()}`,
    {
      onErrorRetry: error => {
        if (error.status === 422) return
      }
    }
  )

  return { chargeInfo, isChargeInfoLoading }
}

export const useGetUsedProductAddOnUuids = () => {
  const { data: usedProductAddOnUuids, isLoading } = useSWR(
    'payments/product-addon/already-used'
  )

  return { usedProductAddOnUuids, isLoading }
}
