import { InfobarAlert } from './components/InfobarAlert'
import { useGetAccountExpiresAtType } from './hooks/useGetAccountExpiresAtType'
import { useGetCreditCardExpiresAtType } from './hooks/useGetCreditCardExpiresAtType'
import { useGetPaymentError } from './hooks/useGetPaymentError'

export const Infobars = () => {
  const infobarAccountExpiresAt = useGetAccountExpiresAtType()
  const infobarCreditCardExpiresAt = useGetCreditCardExpiresAtType()
  const infobarPaymentError = useGetPaymentError()

  if (infobarAccountExpiresAt) {
    return <InfobarAlert {...infobarAccountExpiresAt} />
  }

  if (infobarCreditCardExpiresAt) {
    return <InfobarAlert {...infobarCreditCardExpiresAt} />
  }

  if (infobarPaymentError) {
    return <InfobarAlert {...infobarPaymentError} />
  }

  return null
}

Infobars.displayName = 'TopbarInfobars'
