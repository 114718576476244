import { AlertTypes } from '@components/Topbar/components/Infobars/constants'
import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { Alert, Spacer } from '@landingi/landingi-ui-kit'
import { FC, Fragment } from 'react'
import { Row } from 'simple-flexbox'

interface InfobarAlertProps {
  type: AlertTypes
  component: () => JSX.Element
}

export const InfobarAlert: FC<InfobarAlertProps> = ({
  type,
  component: Component
}) => (
  <Fragment>
    <Alert className={styles.alert} type={type}>
      <Row justifyContent='space-between' alignItems='center'>
        <Component />
      </Row>
    </Alert>

    <Spacer space='tiny' />
  </Fragment>
)

InfobarAlert.displayName = 'TopbarInfobarsInfobarAlert'
