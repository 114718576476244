import { useUserContext } from '@contexts/user'

import { AgencyAccountContent } from './AgencyAccountContent'
import { BusinessAccountContent } from './BusinessAccountContent'

export const WorkspacesContentDev = () => {
  const { isAgency, isSubaccount } = useUserContext()

  return isAgency || isSubaccount ? (
    <AgencyAccountContent />
  ) : (
    <BusinessAccountContent />
  )
}

WorkspacesContentDev.displayName = 'SidebarWorkspacesContent'
