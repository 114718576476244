import { MODAL_ADVERTISE_CREDITS } from '@components/Modals/ModalAdvertiseCredits'
import { CLICK_BUY_CREDITS, TRANSLATIONS } from '@constants/mixpanel'
import { useAcl } from '@contexts/acl'
import NiceModal from '@ebay/nice-modal-react'
import { Tooltip } from '@landingi/landingi-ui-kit'
import { useGetCredits } from '@services/credits/useGetCredits'
import { mixpanelEvent } from '@services/mixpanel'
import { Badge } from '@ui-kit/Badge'
import { Button } from '@ui-kit/Button'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

type ButtonUseCreditsProps = {
  children: ReactNode
  price?: number
  isLoading: boolean
  disabled: boolean
  onClick?: () => void
  type?: 'button' | 'submit'
}

export const ButtonUseCredits: FC<ButtonUseCreditsProps> = ({
  children,
  price,
  isLoading,
  disabled,
  onClick,
  type = 'button'
}) => {
  const { t } = useTranslation()

  const { credits: availableCredits } = useGetCredits()

  const hasAccessToUseCredits = useAcl('credits.use_credits')
  const hasAccessToBuyCredits = useAcl('credits.buy_credits')

  const loading = isLoading || typeof price !== 'number'

  const isDisabledWithAdvertiseModal =
    typeof price !== 'number' ||
    loading ||
    typeof availableCredits !== 'number' ||
    (availableCredits < price && !hasAccessToBuyCredits) ||
    !hasAccessToUseCredits ||
    disabled

  const isDisabled = isDisabledWithAdvertiseModal

  const loadingBadge = <Icon icon='icon-spinner' spin />

  const priceBadge = (
    <Fragment>
      <Icon icon='icon-coin' />
      <Spreader spread={5} />
      {price === 0 ? 'FREE' : price}
    </Fragment>
  )

  const renderTooltip = () => {
    if (!price) return null

    if (!hasAccessToUseCredits) return t('credits.not.enough.subaccount')

    if (!availableCredits || availableCredits < price) {
      if (hasAccessToBuyCredits) return null

      return t('credits.not.enough.subaccount')
    }

    return null
  }

  const tooltip = renderTooltip()

  const handleBuyModal = () => {
    if (!hasAccessToBuyCredits) return

    mixpanelEvent({
      name: CLICK_BUY_CREDITS,
      properties: {
        click_location: TRANSLATIONS,
        type: 'not enough credits'
      }
    })

    NiceModal.show(MODAL_ADVERTISE_CREDITS, {
      properties: {
        click_location: TRANSLATIONS,
        type: 'not enough credits'
      }
    })
  }

  const handleClick = () => {
    if (!price || !availableCredits || availableCredits < price) {
      handleBuyModal()
      return
    }

    onClick?.()
  }

  const buttonType =
    !price || !availableCredits || availableCredits < price ? undefined : type

  return (
    <Tooltip placement='top' content={tooltip} disabled={!tooltip}>
      <Button
        onClick={handleClick}
        disabled={isDisabled}
        type={buttonType}
        data-testid='button-use-credits'
      >
        <Row alignItems='center'>
          {children}
          {hasAccessToUseCredits && (
            <Fragment>
              <Spreader spread={5} />

              <Badge variant='neutral4-filled'>
                {loading ? loadingBadge : priceBadge}
              </Badge>
            </Fragment>
          )}
        </Row>
      </Button>
    </Tooltip>
  )
}
