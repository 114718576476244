import { useStyles } from '@hooks/useStyles'
import { NeutralColor, PrimaryColor, SuccessColor } from '@ui-kit/types/color'
import { SpaceType } from '@ui-kit/types/space'
import { SurfaceLevel } from '@ui-kit/types/surface'
import { FC, ReactNode } from 'react'

import styles from './Panel.module.scss'

export interface PanelProps {
  className?: string | string[]
  children?: ReactNode
  border?:
    | Extract<NeutralColor, 'neutral-2'>
    | Extract<PrimaryColor, 'primary'>
    | Extract<SuccessColor, 'success'>
    | 'none'
  surface?: SurfaceLevel
  padding?:
    | {
        x: SpaceType
        y: SpaceType
      }
    | number
  onClick?: () => void
  'data-testid'?: string
}

export const Panel: FC<PanelProps> = ({
  className = '',
  children,
  border = 'none',
  surface = 1,
  padding = 20,
  onClick,
  'data-testid': dataTestId = 'panel'
}) => {
  const paddingX = typeof padding !== 'number' ? padding.x : null
  const paddingY = typeof padding !== 'number' ? padding.y : null

  const elementClasses = useStyles(
    {
      [styles.panel]: true,
      [styles[`panel--border-${border}`]]: border !== 'none',
      [styles[`panel--surface-${surface}`]]: true,
      [styles[`panel--padding-${padding}`]]: typeof padding === 'number',
      [styles[`panel--padding-x-${paddingX}`]]: paddingX,
      [styles[`panel--padding-y-${paddingY}`]]: paddingY
    },
    className
  )

  return (
    <div className={elementClasses} data-testid={dataTestId} onClick={onClick}>
      {children}
    </div>
  )
}

Panel.displayName = 'Panel'
