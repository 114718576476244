import { REGISTRATION } from '@routes/path'
import { matchPath, useLocation } from 'react-router-dom'

export const useHideSecondColumn = () => {
  const { pathname } = useLocation()

  const pathsWithoutSecondColumn = [
    REGISTRATION.REGISTER.DEFAULT,
    REGISTRATION.SIGNUP.DEFAULT,
    REGISTRATION.LOGIN.DEFAULT,
    REGISTRATION.RECOVERY_CODE.DEFAULT,
    REGISTRATION.TWO_FACTOR_AUTHENTICATION.DEFAULT,
    REGISTRATION.FORGOT_PASSWORD.DEFAULT,
    matchPath(REGISTRATION.CHANGE_PASSWORD.DEFAULT, pathname)?.pathname
  ]

  return pathsWithoutSecondColumn.includes(pathname)
}
