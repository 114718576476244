import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import { CountdownTypes } from './constants'
import styles from './CountdownTimer.module.scss'

interface CountdownBoxProps {
  value: string
  type: CountdownTypes
}

export const CountdownBox: FC<CountdownBoxProps> = ({ value, type }) => {
  const { t } = useTranslation()

  return (
    <Column
      className={styles['countdown-box']}
      horizontal='center'
      vertical='center'
    >
      <span className={styles['countdown-box__value']}>{value}</span>

      <span className={styles['countdown-box__label']}>
        {t(`countdown.timer.${type}`)}
      </span>
    </Column>
  )
}
