import {
  Heading,
  Icon,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Row vertical='center'>
        <Icon icon='icon-globe' size={32} color='color-accent-5' />

        <Spreader spread='tiny' />

        <Spreader spread='mini' />

        <Heading level={1} margin='none'>
          {t('publishing.options.boxes.box.custom_domain.name')}
        </Heading>
      </Row>

      <Spacer />

      <Paragraph size={16} weight={400} padding='none'>
        {t('publishing.options.change.url.description')}
      </Paragraph>
    </Fragment>
  )
}

Header.displayName = 'PublishingOptionsViewsChangeUrlHeader'
