import { useLazyService } from '@hooks/useLazyService'
import { changeVariantParam } from '@services/landings/dashboard/abTests/actions'
import {
  GetLandingResponse,
  LandingData,
  useGetLanding
} from '@services/landings/useGetLanding'
import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const useDashboardState = () => {
  const { t } = useTranslation()
  const { identifier } = useParams()

  // the current variant hash, set when the variant's traffic changes
  const [changedVariantHash, setChangedVariantHash] = useState('')

  const {
    data: landing,
    isLoading: isLoadingLanding,
    mutate: mutateLanding,
    error: errorLanding
  } = useGetLanding({ uuid: identifier })

  const setNewLandingData = (newData: Partial<LandingData>) => {
    if (!landing) {
      return
    }

    mutateLanding(
      {
        ...landing,
        data: {
          ...landing.data,
          ...newData
        }
      },
      {
        revalidate: false
      }
    )
  }

  const [changeVariantParamRequest, { isLoading: isLoadingChangeParam }] =
    useLazyService(changeVariantParam, {
      errorToastText: t('error.page.generic.title')
    })

  return {
    isLoading: isLoadingLanding,
    landing: landing?.data as GetLandingResponse['data'],
    errorLanding,
    setNewLandingData,
    changeVariantParamRequest,
    isLoadingChangeParam,
    changedVariantHash,
    setChangedVariantHash
  }
}

type DashboardState = ReturnType<typeof useDashboardState>

const DashboardContext = createContext<DashboardState | null>(null)

interface DashboardProviderProps {
  children: ReactNode
}

export const DashboardProvider: FC<DashboardProviderProps> = ({ children }) => {
  const value = useDashboardState()

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => {
  const dashboard = useContext(DashboardContext)

  if (!dashboard) {
    throw new Error('useDashboardContext must be used inside DashboardProvider')
  }

  return dashboard
}
