import { Loader } from '@landingi/landingi-ui-kit'
import {
  BoxOutline,
  BoxOutlineProvider,
  Heading,
  Icon,
  Image,
  Paragraph,
  Spreader
} from '@ui-kit'
import { ReactNode } from 'react'
import { Column, Row } from 'simple-flexbox'

export const ChooseMethodBoxOutline = ({
  imageSrc,
  heading,
  paragraph,
  onClick,
  isLoading,
  disabled = false
}: {
  imageSrc: string
  heading: ReactNode
  paragraph: ReactNode
  isLoading?: boolean
  disabled?: boolean
  onClick: () => void
}) => (
  <BoxOutlineProvider disabled={disabled || isLoading}>
    <BoxOutline onClick={onClick}>
      <Row alignItems='center' justifyContent='space-between'>
        <Row alignItems='center'>
          <Image src={imageSrc} width='48px' height='48px' />

          <Spreader spread={15} />

          <Column>
            <Heading level={4}>{heading}</Heading>
            <Paragraph size={12} color='neutral-6'>
              {paragraph}
            </Paragraph>
          </Column>
        </Row>

        <Icon
          icon={isLoading ? 'icon-spinner' : 'icon-arrow-right'}
          spin={isLoading}
          color='neutral-6'
        />
      </Row>
    </BoxOutline>
  </BoxOutlineProvider>
)
