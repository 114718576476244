import { useStyles } from '@hooks/useStyles'
import {
  NeutralColor,
  PrimaryColor,
  SuccessColor,
  WhiteColor
} from '@ui-kit/types/color'
import { FC, ReactNode } from 'react'

import styles from './Paragraph.module.scss'

export type ParagraphSize = 10 | 12 | 14 | 16 | 18

export interface ParagraphProps {
  children: ReactNode
  className?: string | string[]
  size?: ParagraphSize
  color?: NeutralColor | WhiteColor | PrimaryColor | SuccessColor
  weight?: 300 | 400 | 600
  align?: 'left' | 'center' | 'right' | 'justify'
  'data-testid'?: string
}

export const Paragraph: FC<ParagraphProps> = ({
  children,
  className,
  size = 14,
  color = 'neutral',
  weight = 400,
  align = 'left',
  'data-testid': dataTestId = 'paragraph'
}) => {
  const paragraphStyles = useStyles(
    {
      [styles.paragraph]: true,
      [styles[`paragraph--size-${size}`]]: size,
      [styles[`paragraph--color-${color}`]]: color,
      [styles[`paragraph--weight-${weight}`]]: weight,
      [styles[`paragraph--align-${align}`]]: align
    },
    className
  )

  return (
    <p data-testid={dataTestId} className={paragraphStyles}>
      {children}
    </p>
  )
}

Paragraph.displayName = 'Paragraph'
