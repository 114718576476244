import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { useChangeSubaccountToMain } from '@hooks/useChangeSubaccountToMain'
import ImportFromFigma from '@images/landings/import_figma.png'
import ImportFromFigmaImage from '@images/landings/import-from-figma-img.png'
import Templates from '@images/landings/landing_page_templates.png'
import { Loader, Tooltip } from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import ADDONS_STORE from '@routes/path/addons'
import { mixpanelEvent } from '@services/mixpanel'
import {
  Badge,
  emitTimingToast,
  Heading,
  Paragraph,
  Spacer,
  Spreader
} from '@ui-kit'
import { Close } from '@ui-kit/Close'
import { Image } from '@ui-kit/Image'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import useSWR from 'swr'

import { ChooseMethodBoxOutline } from './components/CreateLandingBoxOutline'
import { ImportFromFileBoxOutline } from './components/ImportFromFileBoxOutline'
import styles from './CreateLanding.module.scss'

export const ChooseMethod = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const close = () => navigate(LANDINGS.LANDINGS)

  const {
    areSubaccountsLoading,
    changeToMainAccountIfNeeded,
    isRequestChangeUserAccountLoading,
    isTypeManager
  } = useChangeSubaccountToMain()

  const hasAccessToAddonsMarketplaceManagement = useAcl(
    'payments.product_add_on.management'
  )

  const { data: waitlistInfo, isLoading } = useSWR(
    hasAccessToAddonsMarketplaceManagement
      ? `payments/product-addon/waitlist/import-from-figma`
      : null
  )

  const isSubscribedToWaitlist = hasAccessToAddonsMarketplaceManagement
    ? waitlistInfo?.is_subscribed
    : false

  const handleTemplatesClick = () =>
    window.open(`${NEW_LANDINGI_APP_URL}/templates/v1`, '_self')

  const handleFigmaClick = async () => {
    mixpanelEvent({ name: 'Click import from Figma' })

    if (isTypeManager) {
      emitTimingToast({
        type: 'info',
        message: t('landings.import.from.figma.mock.to.learn.profile')
      })

      return
    }

    await changeToMainAccountIfNeeded()

    navigate(ADDONS_STORE.IMPORT_FROM_FIGMA_ADDON)
  }

  if (isLoading || areSubaccountsLoading) {
    return <Loader />
  }

  return (
    <Row className={styles['choose-method']}>
      <Close className={styles.close} onClick={close} />

      <Column className={styles['choose-method__left-column']}>
        <div className={styles['choose-method__left-column-content']}>
          <Heading>{t('landings.import.from.figma.mock.heading')}</Heading>

          <Spacer space={20} />

          <Spacer space={15} />

          <ChooseMethodBoxOutline
            imageSrc={Templates}
            heading={t('landings.import.from.figma.mock.start.from.template')}
            paragraph={t(
              'landings.import.from.figma.mock.start.from.template.paragraph'
            )}
            onClick={handleTemplatesClick}
          />

          <Spacer space={15} />

          <Tooltip
            content={t('addons.store.youve.already.signed.up')}
            disabled={!isSubscribedToWaitlist}
          >
            <ChooseMethodBoxOutline
              disabled={isSubscribedToWaitlist}
              imageSrc={ImportFromFigma}
              heading={
                <Row alignItems='center'>
                  {t('landings.import.from.figma.mock.figma')}

                  <Spreader spread={10} />

                  <Badge weight={600}>NEW</Badge>
                </Row>
              }
              paragraph={t('landings.import.from.figma.mock.figma.paragraph')}
              onClick={handleFigmaClick}
              isLoading={isRequestChangeUserAccountLoading}
            />
          </Tooltip>

          <Spacer space={15} />

          <ImportFromFileBoxOutline />
        </div>
      </Column>

      <Column className={styles['choose-method__right-column']}>
        <div className={styles['choose-method__right-column-content']}>
          <Image src={ImportFromFigmaImage} width='275px' height='181px' />

          <Spacer space={15} />

          <Heading>
            {t('landings.import.from.figma.mock.second.column.heading')}
          </Heading>

          <Spacer space={15} />

          <Paragraph color='neutral-6'>
            {t('landings.import.from.figma.mock.second.column.paragraph')}
          </Paragraph>
        </div>
      </Column>
    </Row>
  )
}
