import {
  Button,
  CustomLink,
  Icon,
  Paragraph,
  Spreader
} from '@landingi/landingi-ui-kit'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { PublicationType } from '@services/landings/list/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import style from './UrlCell.module.scss'

interface UrlCellProps {
  url: string
  isFake: boolean
  publicationType: PublicationType
}

export const UrlCell: FC<UrlCellProps> = ({ url, isFake, publicationType }) => {
  const {
    landingPageUrls: { handleRefreshExternalUrls },
    publishingOptions: {
      data: { isLandingPagePublished }
    }
  } = usePublishingOptionsContext()

  const { t } = useTranslation()

  if (isFake && publicationType === TYPES.WORDPRESS) {
    return (
      <Row
        alignItems='center'
        justifyContent='space-between'
        className={style.urlBox}
      >
        <Paragraph padding='none' color='accent-2' size={12}>
          {t('publishing.options.table.wordpress.refresh.info')}
        </Paragraph>

        <Button
          variant='icon-transparent-hover'
          size='tiny'
          onClick={handleRefreshExternalUrls}
          className={style.buttonMargin}
        >
          <Icon icon='icon-sync' size={12} />
        </Button>
      </Row>
    )
  }

  if (isFake && publicationType === TYPES.EMBED) {
    return (
      <Row
        alignItems='center'
        justifyContent='space-between'
        className={style.urlBox}
      >
        <Paragraph padding='none' color='accent-2' size={12}>
          {t('publishing.options.table.embed.refresh.info')}
        </Paragraph>

        <Spreader spread='small' />

        <Button
          variant='icon-transparent-hover'
          size='tiny'
          onClick={handleRefreshExternalUrls}
          className={style.buttonMargin}
        >
          <Icon icon='icon-sync' size={12} />
        </Button>
      </Row>
    )
  }

  return (
    <CustomLink
      href={url}
      label={url}
      target='_blank'
      variant={isLandingPagePublished ? 'active' : 'inactive'}
    />
  )
}

UrlCell.displayName = 'PublishingOptionsViewsMainTableUrlCell'
