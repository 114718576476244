import { TEST_DOMAINS } from '@constants/testDomains'
import ImageEmptyGroup from '@images/empty_group.png'
import ImageLandingsPageEmpty from '@images/landing_pages_empty.png'
import ImageSearchEmpty from '@images/search_empty.svg'

export const isPublishedOnTestDomain = (isPublished: boolean, domain: string) =>
  isPublished &&
  TEST_DOMAINS.some((testDomain: string) => domain?.includes(testDomain))

export const renderEmptyMessage = ({
  searchPhrase,
  id
}: {
  searchPhrase: string
  id: string
}) => {
  if (!searchPhrase) {
    return {
      message: id ? 'message.empty.groups' : 'message.empty.landings',
      messageSmall: id ? 'message.empty.groups.small' : '',
      image: id ? ImageEmptyGroup : ImageLandingsPageEmpty
    }
  }

  return {
    message: 'message.empty.search.results',
    messageSmall: 'message.empty.search.results.small',
    image: ImageSearchEmpty
  }
}
