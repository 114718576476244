import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAclContext } from '@contexts/acl'
import { useFeatureFlags, useFeatureFlagsContext } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useAppcues } from '@hooks/useAppcues'
import { useHead } from '@hooks/useHead'
import { useHotjar } from '@hooks/useHotjar'
import { useInitialModals } from '@hooks/useInitialModals'
import { getLogoutPath } from '@hooks/useLogout/logoutPath'
import { useSupportContact } from '@hooks/useSupportContact'
import { useSurvicate } from '@hooks/useSurvicate'
import { Loader } from '@landingi/landingi-ui-kit'
import { hideRecaptchaBadge } from '@pages/Authentication/helpers/recaptcha'
import {
  getRouteBasedOnCurrentStep,
  routes
} from '@pages/Authentication/helpers/steps'
import { LANDINGS, PRICING, REGISTRATION } from '@routes/path'
import { REGISTRATION_ROUTES_ARRAY } from '@routes/path/registration'
import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

interface AuthenticatedProps {
  children: JSX.Element | null
}

export const Authenticated: FC<AuthenticatedProps> = ({ children }) => {
  const { auth, isAuth, authError, isLoading: isLoadingUser } = useUserContext()
  const { isLoading: isLoadingFeatureFlags } = useFeatureFlagsContext()
  const { isLoading: isLoadingAcl } = useAclContext()

  const hasAccessToSpa = useFeatureFlags('SPA_TOPBAR_SIDEBAR')
  const hasAccessToNewChangePlanPage = useFeatureFlags('SPA_CHANGE_PLAN_PAGE')
  const hasAccessToPricing2022 = useFeatureFlags('PRICING_2022')

  const location = useLocation()

  const { state } = location

  const logoutPath = getLogoutPath()

  // initializes freshchat or freshdesk depending on ACL and shows widget
  useSupportContact()

  useAppcues()

  useSurvicate()

  useHotjar()

  useInitialModals()

  hideRecaptchaBadge()

  useHead()

  // if transitioned from different route doesn't redirect to any other route
  if (isAuth && auth && !state?.transitionedFromRoute) {
    const redirectRoute = getRouteBasedOnCurrentStep(
      auth.flow.current_step.name as string
    )

    if (redirectRoute && redirectRoute.DEFAULT !== location.pathname) {
      const { DEFAULT } = redirectRoute

      return <Navigate to={DEFAULT} />
    }

    if (
      auth.flow.current_step.name === 'COMPLETED' &&
      (location.pathname === routes.CREDIT_CARD.DEFAULT ||
        location.pathname === routes.SURVEY.DEFAULT)
    ) {
      return <Navigate to={LANDINGS.WELCOME} />
    }
  }

  if (authError?.error?.message === 'Access Denied - 2FA Required') {
    return <Navigate to={REGISTRATION.TWO_FACTOR_AUTHENTICATION.DEFAULT} />
  }

  if (
    authError?.error?.status === 401 ||
    authError?.error?.status === 500 ||
    authError?.error?.code === 'ERR_NETWORK'
  ) {
    return <Navigate to={logoutPath} />
  }

  // if transitioned from different route don't show loader
  if (
    (isLoadingUser || isLoadingFeatureFlags || isLoadingAcl) &&
    !state?.transitionedFromRoute
  ) {
    return <Loader data-testid='authenticated-loader' />
  }

  if (!isAuth) {
    return <Navigate to={logoutPath} />
  }

  // as SPA_CHANGE_PLAN_PAGE is released before SPA_TOPBAR_SIDEBAR, there has to be possibility to enter new pricing
  if (
    location.pathname === PRICING.PRICING &&
    hasAccessToNewChangePlanPage &&
    hasAccessToPricing2022
  ) {
    return children
  }

  if (
    !hasAccessToSpa &&
    !REGISTRATION_ROUTES_ARRAY.includes(location.pathname)
  ) {
    /* Redirect to new.landingi only when user is inside app - has already finished registration */
    window.open(`${NEW_LANDINGI_APP_URL}${location.pathname}`, '_self')

    return null
  }

  return children
}
