import { useStyles } from '@hooks/useStyles'
import { Panel } from '@ui-kit/Panel'
import { FC, ReactNode } from 'react'

import styles from './BoxOutline.module.scss'
import { borders, DISABLED } from './constants'
import { useBoxOutlineContext } from './context'

export interface BoxOutlineProps {
  children: ReactNode
  className?: string | string[]
  containerClassName?: string
  disabled?: boolean
  onClick?: () => void
  'data-testid'?: string
}

export const BoxOutline: FC<BoxOutlineProps> = ({
  children,
  className,
  containerClassName,
  onClick,
  'data-testid': dataTestId = 'box-outline'
}) => {
  const { hoverProps, status } = useBoxOutlineContext()

  const boxOutlineContainerStyles = useStyles(
    {
      [styles['box-outline-container']]: true
    },
    containerClassName
  )

  const boxOutlineBackdropStyles = useStyles({
    [styles['box-outline-backdrop']]: true,
    [styles['box-outline-backdrop--disabled']]: status === DISABLED
  })

  const boxOutlineStyles = useStyles(
    {
      [styles['box-outline']]: true
    },
    className
  )

  const border = borders[status]

  return (
    <div {...hoverProps} className={boxOutlineContainerStyles}>
      <div className={boxOutlineBackdropStyles} />
      <Panel
        data-testid={dataTestId}
        className={boxOutlineStyles}
        surface={0}
        border={border}
        onClick={status === DISABLED ? undefined : onClick}
      >
        {children}
      </Panel>
    </div>
  )
}

BoxOutline.displayName = 'BoxOutline'
