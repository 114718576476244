import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { COMPLETED, SURVEY } from '@constants/registrationSteps'
import { useFeatureFlags } from '@contexts/featureFlags'
import { isEmpty } from '@helpers/data'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { useLazyService } from '@hooks/useLazyService'
import { emitTimingToastToggle } from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import { SURVEY_QUESTIONS_ANSWERS } from '@pages/Authentication/helpers/localStorage'
import { isLastStep } from '@pages/Authentication/helpers/steps'
import styles from '@pages/Authentication/routes/Survey/Form/Form.module.scss'
import FormSchemas from '@pages/Authentication/routes/Survey/Form/FormSchemas'
import questions from '@pages/Authentication/routes/Survey/Form/questions'
import { LANDINGS, REGISTRATION } from '@routes/path'
import {
  changeRegistrationStep,
  getAuthInfo,
  postSurveyInfo
} from '@services/authentication'
import { useFormik } from 'formik'
import { motion } from 'framer-motion'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

/**
 * Survey form - stateful presentational component
 * @param {object} props - props
 * @return {object} An object of children
 */
const SurveyForm = () => {
  const { questionsSkippedInfo, setHasSurveyFinished } = useSurveyContext()
  const { currentStep, nextStep, previousStep, surveySteps, getSurveyStep } =
    useStepsContext()
  const { lang, period } = useRegisterContext()

  const { finishRegistration, setTransitionedToCreditCardStep } =
    useAnimationContext()
  const CurrentQuestion = questions[getSurveyStep() - 1]
  const isLastQuestion = currentStep === questions.length
  const [isSkippingLastQuestion, setIsSkippingLastQuestion] = useState(false)

  const navigate = useNavigate()

  const { t } = useTranslation()

  /**
   * @param {string} experience_level - form value from question
   * @param {string} where - form value from question
   * @return info about question send to endpoint
   */
  const getFirstQuestionInfo = (experience_level, where) => ({
    question: 'experience_level',
    answer: questionsSkippedInfo.first
      ? ['skip']
      : [experience_level === 'advanced' ? where : 'beginner']
  })

  /**
   * @param {string} purpose_signup - form value from question
   * @return info about question to send to endpoint
   */
  const getSecondQuestionInfo = purpose_signup => ({
    question: 'purpose_signup',
    answer: questionsSkippedInfo.second ? ['skip'] : [purpose_signup]
  })

  /**
   * @param {string} industry_reg_survey - form value from question
   * @return info about question send to endpoint
   */
  const getThirdQuestionInfo = industry_reg_survey => ({
    question: 'industry_reg_survey',
    answer: questionsSkippedInfo.third ? ['skip'] : [industry_reg_survey]
  })

  /**
   * @param {object} values - formik values object
   * @return info about question send to endpoint
   */
  const getFourthQuestionInfo = values => {
    const fourthQuestionAnswers = []

    if (values['own-process-optimisation']) {
      fourthQuestionAnswers.push('own-process-optimisation')
    }

    if (values['social-media']) {
      fourthQuestionAnswers.push('social-media')
    }

    if (values['direct-sales']) {
      fourthQuestionAnswers.push('direct-sales')
    }

    if (values.ads) {
      fourthQuestionAnswers.push('ads')
    }

    if (isEmpty(fourthQuestionAnswers)) fourthQuestionAnswers.push('skip')

    return {
      question: 'signup_goal',
      answer: questionsSkippedInfo.fourth ? ['skip'] : fourthQuestionAnswers
    }
  }

  /**
   * @param {string} marketing_stock - form value from question
   * @return info about question send to endpoint
   */
  const getFifthQuestionInfo = marketing_stock => {
    const marketingStockString = marketing_stock.join(';')

    return {
      question: 'marketing_stock',
      answer: questionsSkippedInfo.fifth ? ['skip'] : [marketingStockString]
    }
  }
  /**
   * @param {object} values - formik values object
   * @return info about question send to endpoint
   */
  const getSixthQuestionInfo = values => {
    const sixthQuestionAnswers = []

    if (values.newsletter) {
      sixthQuestionAnswers.push('newsletter')
    }

    if (values['product-updates']) {
      sixthQuestionAnswers.push('product-updates')
    }

    if (isEmpty(sixthQuestionAnswers)) sixthQuestionAnswers.push('skip')

    return {
      question: 'communication',
      answer: questionsSkippedInfo.sixth ? ['skip'] : sixthQuestionAnswers
    }
  }

  const { mutate } = useSWRConfig()

  const [getAuthInfoRequest] = useLazyService(getAuthInfo)
  const hasAccessToSpa = useFeatureFlags('SPA_TOPBAR_SIDEBAR')

  /**
   * onSubmit - handles submit of user data and calls login endpoint
   * @param  {object} values - formik values
   * @param  {object} actions - formik actions
   * @type {function}
   */
  const onSubmit = useCallback(
    async (values, actions) => {
      const { setTouched, validateForm, setSubmitting } = actions

      if (currentStep < surveySteps) {
        nextStep()
        setTouched({})
        validateForm()
        setSubmitting(false)

        return
      }

      const {
        experience_level,
        where,
        purpose_signup,
        marketing_stock,
        industry_reg_survey
      } = values

      const firstQuestion = getFirstQuestionInfo(experience_level, where)

      const secondQuestion = getSecondQuestionInfo(purpose_signup)

      const thirdQuestion = getThirdQuestionInfo(industry_reg_survey)

      const fourthQuestion = getFourthQuestionInfo(values)

      const fifthQuestion = getFifthQuestionInfo(marketing_stock)

      const sixthQuestion = getSixthQuestionInfo(values)

      const surveyInfo = [
        firstQuestion,
        secondQuestion,
        thirdQuestion,
        fourthQuestion,
        fifthQuestion,
        sixthQuestion
      ]

      try {
        await postSurveyInfo(surveyInfo)
        await changeRegistrationStep(SURVEY)

        const authInfo = await getAuthInfoRequest()

        setHasSurveyFinished(true)

        const { flow } = authInfo
        const { current_step, steps } = flow
        const { name } = current_step

        if (isLastStep(steps, SURVEY)) {
          await changeRegistrationStep(COMPLETED)

          mutate('payments/discount', undefined, { revalidate: true })

          setTimeout(async () => {
            await finishRegistration()

            setTimeout(() => {
              if (hasAccessToSpa) {
                navigate(LANDINGS.WELCOME)
              } else {
                window.open(`${NEW_LANDINGI_APP_URL}/welcome`, '_self')
              }

              mutate('auth')
            }, 3200)
          }, 2200)
        } else {
          setTransitionedToCreditCardStep(true)

          setTimeout(async () => {
            await mutate('auth')
            nextStep()
            navigate(
              `${REGISTRATION.CREDIT_CARD_STEP.DEFAULT}?lang=${lang}&period=${period}`
            )
          }, 2200)
        }
      } catch {
        emitTimingToastToggle(t('error.page.generic.title'))
      }
    },
    [currentStep]
  )

  const initialValues = JSON.parse(
    getLocalStorage(SURVEY_QUESTIONS_ANSWERS)
  ) || {
    experience_level: '',
    purpose_signup: '',
    ads: false,
    'own-process-optimisation': false,
    'social-media': false,
    'direct-sales': false,
    'product-updates': false,
    newsletter: false,
    marketing_stock: [],
    industry_reg_survey: '',
    refferedOtherName: '',
    otherName: '',
    influencerName: ''
  }

  const {
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    dirty,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateField,
    validateForm,
    handleChange,
    setTouched,
    errors,
    touched,
    setSubmitting
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: FormSchemas[getSurveyStep() - 1]?.()
  })

  const handleSkip = async () => {
    if (isLastQuestion) {
      setIsSkippingLastQuestion(true)

      await onSubmit(values, { setTouched, setSubmitting, validateForm })

      setIsSkippingLastQuestion(false)
    } else {
      nextStep()
    }
  }

  setLocalStorage(SURVEY_QUESTIONS_ANSWERS, JSON.stringify(values))

  return (
    <motion.div
      className={styles['survey-layout']}
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <form onSubmit={handleSubmit}>
        <CurrentQuestion
          key={currentStep}
          values={values}
          isSubmitting={isSubmitting}
          isValid={isValid}
          dirty={dirty}
          errors={errors}
          touched={touched}
          handleBack={previousStep}
          handleSkipStep={handleSkip}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          handleChange={handleChange}
          validateField={validateField}
          validateForm={validateForm}
          isLastQuestion={isLastQuestion}
          isSkippingLastQuestion={isSkippingLastQuestion}
        />
      </form>
    </motion.div>
  )
}

SurveyForm.displayName = 'SurveyForm'

export default SurveyForm
