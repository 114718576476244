import { CurrencyType, PLN } from '@constants/currencies'
import { splitPriceByFloatingPoint } from '@helpers/payment'
import { Badge } from '@ui-kit/Badge'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './Price.module.scss'

interface PriceProps {
  price: {
    amount_net: number
    currency: CurrencyType
  }
}

export const Price: FC<PriceProps> = ({ price }) => {
  const { t } = useTranslation()

  const { decimalPoint, floatingPoint } = splitPriceByFloatingPoint(
    price.amount_net
  )

  return (
    <Fragment>
      <Row className={styles.price}>
        <Paragraph
          className={styles.price__amount}
          weight={600}
          color='primary'
          data-testid='price-amount'
        >
          {decimalPoint}

          {floatingPoint && (
            <sup className={styles['price__floating-point']}>
              {`,${floatingPoint}`}
            </sup>
          )}
        </Paragraph>

        <Column>
          <Paragraph
            className={styles.price__currency}
            size={14}
            weight={600}
            color='primary'
            data-testid='price-currency'
          >
            {price.currency}
          </Paragraph>

          <Paragraph
            className={styles.price__currency}
            size={14}
            color='neutral-6'
            data-testid='price-currency-net'
          >
            {price.currency === PLN && t('change.plan.page.net')}
          </Paragraph>
        </Column>
      </Row>

      <Spacer space={10} />

      <Badge variant='calmviolet-border'>
        <Icon icon='icon-credit-card' />

        <Spreader spread={5} />

        {t('addons.store.badge.one.off.payment')}
      </Badge>
    </Fragment>
  )
}
