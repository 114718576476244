import { ButtonUseCredits } from '@components/Credits/ButtonUseCredits/ButtonUseCredits'
import { CreditsBalance } from '@components/Credits/CreditsBalance'
import {
  TRANSLATE_LP_CLOSE_EVENT_NAME,
  TRANSLATE_LP_OPEN_EVENT_NAME,
  TRANSLATE_LP_SUBMIT_EVENT_NAME
} from '@constants/mixpanel'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { setLocalStorage } from '@helpers/storage'
import { useLazyService } from '@hooks/useLazyService'
import { useStyles } from '@hooks/useStyles'
import ImageOne from '@images/landings/dashboard/translation/image_one.svg'
import ImageTwo from '@images/landings/dashboard/translation/image_two.svg'
import {
  Button,
  Icon,
  Image,
  InputForm,
  Loader,
  Modal,
  Paragraph,
  PerfectDropdownSelect,
  Spacer
} from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import { useGetTranslationPrice } from '@services/credits/useGetTranslationPrice'
import {
  translateLanding,
  TranslateLandingRequest,
  TranslateLandingResponse
} from '@services/landings/translate/translateLanding'
import { useGetLanguages } from '@services/landings/translate/useGetLanguages'
import { mixpanelEvent } from '@services/mixpanel'
import { emitTimingToast } from '@ui-kit'
import { Spreader } from '@ui-kit/Spreader'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'
import { mutate } from 'swr'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Header } from './components/header'
import styles from './ModalLandingTranslate.module.scss'

const VIEW_FORM = 'form'
const VIEW_INFO = 'info'

interface TranslateLandingForm {
  name: string
  sourceLanguage: string | null
  desiredLanguage: string | null
}

interface ModalLandingTranslateProps {
  name: string
  uuid: string
}

export const ModalLandingTranslate =
  NiceModal.create<ModalLandingTranslateProps>(({ name, uuid }) => {
    const { remove } = useModal()

    const { t } = useTranslation()

    const navigate = useNavigate()

    const hasAccessToCredits = useFeatureFlags('CREDITS')

    const { cost } = useGetTranslationPrice(uuid)

    const [view, setView] = useState(VIEW_FORM)

    const [translatedLPUuid, setTranslatedLPUuid] = useState<string | null>(
      null
    )

    const [sendMixpanelEvent] = useLazyService(mixpanelEvent)

    const { data, error } = useGetLanguages()

    const languages = data
      ? {
          sourceLanguages: data.sourceLanguages.map(({ language }) => language),
          targetLanguages: data.targetLanguages.map(({ language }) => language)
        }
      : null

    if (error) {
      emitTimingToast({
        message: t('error.please.try.again'),
        type: 'error'
      })
    }

    const [handleTranslateLanding] = useLazyService<
      TranslateLandingRequest,
      TranslateLandingResponse
    >(translateLanding, {
      successToastText: t('modal.lp.translate.success'),
      onSuccess: res => {
        mutate('credits/balance')

        setTranslatedLPUuid(res.data.uuid)

        setLocalStorage('used_translation', true)
      },
      onError: error => {
        const { response } = error as AxiosError

        if (response?.status === 400) {
          emitTimingToast({
            message: t('modal.lp.translate.error'),
            type: 'error'
          })

          return
        }

        emitTimingToast({
          message: t('error.please.try.again'),
          type: 'error'
        })

        setView(VIEW_FORM)
      }
    })

    const sendOpenEvent = useCallback(async () => {
      await sendMixpanelEvent({
        name: TRANSLATE_LP_OPEN_EVENT_NAME
      })
    }, [])

    useEffect(() => {
      sendOpenEvent()
    }, [sendOpenEvent])

    const handleModalClose = async () => {
      if (view === VIEW_FORM) {
        await sendMixpanelEvent({
          name: TRANSLATE_LP_CLOSE_EVENT_NAME
        })
      }
      remove()
    }

    const onSubmit = async ({
      name,
      sourceLanguage,
      desiredLanguage
    }: TranslateLandingForm) => {
      if (!sourceLanguage || !desiredLanguage) {
        return
      }

      setView(VIEW_INFO)

      await sendMixpanelEvent({
        name: TRANSLATE_LP_SUBMIT_EVENT_NAME
      })

      await handleTranslateLanding({
        landingUuid: uuid,
        name,
        sourceLanguage,
        desiredLanguage
      })
    }

    const goToDashboard = () => {
      if (!translatedLPUuid) {
        return
      }

      remove()
      navigate(
        generatePath(LANDINGS.LANDINGS_DASHBOARD, {
          identifier: translatedLPUuid
        })
      )
    }

    const validationSchema = toFormikValidationSchema(
      z.object({
        name: z.string(),
        sourceLanguage: z.string(),
        desiredLanguage: z.string()
      })
    )

    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      setFieldValue,
      isSubmitting,
      dirty
    } = useFormik<TranslateLandingForm>({
      validationSchema,
      onSubmit,
      initialValues: {
        name: `${name} - ${t('modal.lp.translate.name.suffix')}`,
        sourceLanguage: null,
        desiredLanguage: null
      },
      enableReinitialize: true
    })

    const sourceDropdownStyles = useStyles({
      [styles.translateDropdown]: true,
      [styles['translateDropdown--hasValue']]: values.sourceLanguage
    })

    const outputDropdownStyles = useStyles({
      [styles.translateDropdown]: true,
      [styles['translateDropdown--hasValue']]: values.desiredLanguage
    })

    const handleSwitch = () => {
      const desired = languages?.targetLanguages.includes(
        values.sourceLanguage || ''
      )
        ? values.sourceLanguage
        : null

      const source = languages?.sourceLanguages.includes(
        values.desiredLanguage || ''
      )
        ? values.desiredLanguage
        : null

      setFieldValue('sourceLanguage', source)
      setFieldValue('desiredLanguage', desired)
    }

    const inputLanguages = languages?.sourceLanguages.map(language => ({
      label: t(`translate.language.${language}`),
      value: language,
      disabled: language === values.desiredLanguage
    }))

    const outputLanguages = languages?.targetLanguages.map(language => ({
      label: t(`translate.language.${language}`),
      value: language,
      disabled: language === values.sourceLanguage
    }))

    const formView = (
      <Fragment>
        <Paragraph>{t('modal.lp.translate.description')}</Paragraph>
        <Spacer space='tiny' />
        <InputForm
          field={{
            name: 'name',
            value: values.name,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          i18n={{
            placeholder: t('modal.lp.translate.name'),
            label: t('modal.lp.translate.name')
          }}
        />

        {languages?.sourceLanguages?.length &&
        languages.targetLanguages?.length ? (
          <Row alignItems='end'>
            <PerfectDropdownSelect
              overflowStyle={{ maxHeight: 164 }}
              options={inputLanguages || []}
              formikKey='sourceLanguage'
              onChange={(key, value) => {
                if (typeof key === 'string') {
                  setFieldValue(key, value)
                }
              }}
              value={values.sourceLanguage}
              label={t('modal.lp.translate.source.language')}
              className={sourceDropdownStyles}
              hasSearcher
              liveChanges
              i18n={{ placeholder: t('modal.lp.translate.search.placeholder') }}
            />

            <Spreader spread={15} />

            <Button variant='transparent' size='mini' onClick={handleSwitch}>
              <Icon icon='icon-switch' />
            </Button>

            <Spreader spread={15} />

            <PerfectDropdownSelect
              overflowStyle={{ maxHeight: 164 }}
              options={outputLanguages || []}
              formikKey='desiredLanguage'
              onChange={(key, value) => {
                if (typeof key === 'string') {
                  setFieldValue(key, value)
                }
              }}
              value={values.desiredLanguage}
              label={t('modal.lp.translate.desired.language')}
              className={outputDropdownStyles}
              hasSearcher
              liveChanges
              i18n={{ placeholder: t('modal.lp.translate.search.placeholder') }}
            />
          </Row>
        ) : (
          <Loader className={styles.loader} />
        )}

        <Spacer space='medium' />

        <Row justifyContent='flex-end' alignItems='center'>
          <CreditsBalance />

          <Spreader spread='max' />

          <Button variant='secondary' onClick={handleModalClose}>
            {t('word.cancel')}
          </Button>

          <Spreader />

          {hasAccessToCredits ? (
            <ButtonUseCredits
              disabled={
                !isValid ||
                isSubmitting ||
                !dirty ||
                // I have to check it manually, becouse formik claims that it is valid
                !values.sourceLanguage ||
                !values.desiredLanguage
              }
              isLoading={isSubmitting}
              type='submit'
              price={cost}
            >
              {t('word.translate')}
            </ButtonUseCredits>
          ) : (
            <Button
              isDisabled={
                !isValid ||
                isSubmitting ||
                !dirty ||
                // I have to check it manually, becouse formik claims that it is valid
                !values.sourceLanguage ||
                !values.desiredLanguage
              }
              isLoading={isSubmitting}
              type='submit'
            >
              {t('word.translate')}
            </Button>
          )}
        </Row>
      </Fragment>
    )

    const infoView = (
      <Fragment>
        <div className={styles.animation}>
          <Image className={styles.imageOne} src={ImageOne} />

          <Image className={styles.imageTwo} src={ImageTwo} />
        </div>
        <Spacer space='tiny' />
        <Paragraph>
          <Trans
            i18nKey='modal.lp.translate.progress.description'
            components={{
              b: <b />
            }}
          />
          <br />
          {t('modal.lp.translate.progress.description.part2')}
        </Paragraph>

        <Spacer space='medium' />
        <Row justifyContent='flex-end'>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={goToDashboard}
          >
            {t('word.go.to.dashboard')}
          </Button>
        </Row>
      </Fragment>
    )

    return (
      <Modal
        isActive
        onClick={handleModalClose}
        isClosable
        isComponent
        component={
          <Header
            heading={
              view === VIEW_FORM
                ? t('modal.lp.translate.title')
                : t('modal.lp.translate.progress.title')
            }
          />
        }
      >
        <form onSubmit={handleSubmit}>
          {view === VIEW_FORM ? formView : infoView}
        </form>
      </Modal>
    )
  })

ModalLandingTranslate.displayName = 'ModalLandingTranslate'
