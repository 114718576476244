import {
  europeanUnionCountries,
  mapCountryCodeToCurrency,
  PLN
} from '@constants/currencies'

export const isEuropeanUnionCountry = code =>
  europeanUnionCountries.includes(code)

export const setActiveAccountCurrency = code => {
  if (isEuropeanUnionCountry(code) && code !== 'PL' && code !== 'GB') {
    return mapCountryCodeToCurrency.EU
  }

  if (!isEuropeanUnionCountry(code) && code !== 'PL' && code !== 'GB') {
    return mapCountryCodeToCurrency.REST
  }

  return mapCountryCodeToCurrency[code]
}

export const getPackageName = (packages, packageRecordKey) =>
  packages?.find(({ record_key }) => record_key === packageRecordKey)?.name

export const getPackagePrice = ({
  packages,
  periodToFind,
  currencyToFind,
  packageRecordKey
}) =>
  packages
    ?.find(({ record_key }) => record_key === packageRecordKey)
    ?.tariffs.find(({ period }) => period === periodToFind)
    ?.pricing?.find(({ currency }) => currency === currencyToFind)?.price

export const convertPriceToNetto = bruttoPrice => bruttoPrice / 1.23

export const formatPrice = price => {
  let priceWithoutSpaces = price

  // remove spaces, so this function can be used on price that already is formatted and doesn't destroy it
  if (typeof price === 'string') {
    priceWithoutSpaces = price.replace(/\s/g, '')
  }

  const formattedPrice = parseFloat(priceWithoutSpaces).toFixed(2)

  // makes price have space after thounsand number, e. g. 5000 -> 5 000
  const localeString = formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  const floatingPoints = localeString.split('.')[1]

  if (floatingPoints === '00') {
    return localeString.split('.')[0]
  }

  if (floatingPoints?.length === 1) {
    return `${localeString}0}` // add additional 0, so e. g. 87.5 becomes 87.50
  }

  return localeString
}

export const splitPriceByFloatingPoint = price => {
  const formattedPrice = formatPrice(price)

  const splittedPrice = formattedPrice.split('.')

  return {
    decimalPoint: splittedPrice[0],
    floatingPoint: splittedPrice[1]
  }
}

export const convertPriceToBrutto = nettoPrice => nettoPrice * 1.23

export const convertPriceToBruttoWhenNeeded = (nettoPrice, currency) =>
  currency === PLN ? convertPriceToBrutto(nettoPrice) : nettoPrice

export const getActiveDiscountForPlanAndPeriod = (
  discounts,
  planRecordKey,
  period
) => {
  if (!discounts) {
    return null
  }

  return discounts.find(
    discount =>
      !discount.is_used &&
      !discount.is_expired &&
      discount.package === planRecordKey &&
      discount.period === period
  )
}

const annualDiscountNames = [
  'discount_specialoffer_50_usd',
  'discount_specialoffer_246_pln',
  'discount_specialoffer_50_eur',
  'discount_specialoffer_40_gbp',
  'discount_specialoffer_200_brl',
  'discount_specialoffer_100_usd',
  'discount_specialoffer_492_pln',
  'discount_specialoffer_100_eur',
  'discount_specialoffer_80_gbp',
  'discount_specialoffer_400_brl',
  'discount_specialoffer_300_usd',
  'discount_specialoffer_1476_pln',
  'discount_specialoffer_300_eur',
  'discount_specialoffer_240_gbp',
  'discount_specialoffer_1200_brl'
]

export const getIsPromotion12MonthUsed = discounts => {
  if (!discounts) {
    return false
  }

  const discountNames = discounts.map(({ name }) => name)

  const promotion12MDiscountName = annualDiscountNames.find(discount =>
    discountNames.includes(discount)
  )

  return !!discounts.find(
    discount => discount.name === promotion12MDiscountName && discount.is_used
  )?.is_used
}

export const getHas12MonthSaleDiscount = discounts => {
  if (!discounts) {
    return false
  }

  const discountNames = discounts.map(({ name }) => name)

  const promotion12MDiscountName = annualDiscountNames.find(discount =>
    discountNames.includes(discount)
  )

  return !!discounts.find(
    discount =>
      discount.name === promotion12MDiscountName && !discount.is_expired
  )
}
