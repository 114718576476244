import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import {
  Alert,
  Button,
  Check,
  InputForm,
  ShowPassword,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { REGISTRATION } from '@routes/path'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import grantNewPasswordSchema from './FormSchema'
import styles from './GrantNewPassword.module.scss'

const GrantNewPasswordForm = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const { token } = useParams()
  const { getWhitelabelPageConfig, changePassword } = useWhitelabelPageContext()
  const { mainColor, buttonStyle } = getWhitelabelPageConfig()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const renderChecks = password => (
    <Row>
      <Column>
        <Check positive={hasLowerCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.lowercase')}
        </Check>

        <Spacer space='mini' />

        <Check positive={hasUpperCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.uppercase')}
        </Check>
      </Column>

      <Spreader spread='large' />

      <Column justifyContent='space-between'>
        <Check positive={hasNumber(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.number')}
        </Check>

        <Spacer space='mini' />

        <Check positive={password?.length > 9} crossedOutOnPositive>
          {t('registration.flow.at.least.10')}
        </Check>
      </Column>
    </Row>
  )

  const handleInputTypeState = useCallback(
    type =>
      type === 'text'
        ? setPasswordInputType('text')
        : setPasswordInputType('password'),
    []
  )

  const onSubmit = useCallback(async values => {
    const { password } = values

    await changePassword(token, password)
  }, [])

  const initialValues = {
    password: ''
  }

  const {
    isValid,
    isSubmitting,
    handleSubmit,
    dirty,
    values,
    errors,
    touched,
    handleChange,
    handleBlur
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema: grantNewPasswordSchema()
  })

  const { password } = values

  /**
   * isPasswordValid - returns true if all checks have passed
   * @param {string} password - password to validate
   */
  const isPasswordValid = password =>
    hasLowerCase(password || '') &&
    hasUpperCase(password || '') &&
    hasNumber(password || '') &&
    password?.length > 9

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <div className={styles['reset-password__password']}>
          <Row>
            <Column flexGrow='1'>
              <InputForm
                id='password'
                type={passwordInputType}
                field={{
                  name: 'password',
                  value: values.password,
                  onChange: handleChange,
                  onBlur: handleBlur
                }}
                i18n={{
                  label: t('registration.flow.password'),
                  placeholder: t('registration.flow.password')
                }}
                form={{
                  errors,
                  touched
                }}
              />
            </Column>

            <Spreader spread='tiny' />

            <Column>
              <ShowPassword setHidden={handleInputTypeState} />
            </Column>
          </Row>
        </div>

        {isPasswordValid(password) ? (
          <Alert type='success'>
            <Trans i18nKey='registration.flow.password.alert' />
          </Alert>
        ) : (
          renderChecks(password)
        )}

        <Spacer space='mini' />

        <Spacer space='tiny' />

        <Button
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          size='large'
          customEnabledBackgroundColor={
            buttonStyle?.backgroundColor || mainColor
          }
          customStyle={{ ...buttonStyle }}
        >
          <Trans i18nKey='registration.flow.reset.password' />
        </Button>
      </Column>
    </form>
  )
}

GrantNewPasswordForm.displayName = 'Enhanced Grant New Password Form'

export default GrantNewPasswordForm
