import { APP_LANDINGI_URL, NEW_LANDINGI_URL } from '@config/env'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useModal } from '@ebay/nice-modal-react'
import { Button, Tooltip } from '@ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from '../ModalUpgradePlan.module.scss'

interface PrimaryButtonProps {
  customContent?: string
  customUrl?: string
}

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  customContent,
  customUrl
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { remove } = useModal()

  const hasAccessToSPAChangePlanPage = useFeatureFlags('SPA_CHANGE_PLAN_PAGE')
  const hasAccessToPricing2022 = useFeatureFlags('PRICING_2022')

  const hasAccessToChangePlan = useAcl('plan.change_plan')

  const pricingURL =
    hasAccessToSPAChangePlanPage && hasAccessToPricing2022
      ? `${APP_LANDINGI_URL}/account/package`
      : `https://${NEW_LANDINGI_URL}/account/package`

  const handleClick = () => {
    if (customUrl) {
      navigate(customUrl)

      remove()

      return
    }

    window.open(pricingURL, '_self')
  }

  const content = customContent || t('upgrade.account')

  return (
    <Tooltip
      content={t('upgrade.account.tooltip')}
      disabled={hasAccessToChangePlan}
    >
      <Button
        className={styles['upgrade-modal__footer__link']}
        variant='primary'
        onClick={handleClick}
        disabled={!hasAccessToChangePlan}
      >
        {content}
      </Button>
    </Tooltip>
  )
}
