import { getMainPath } from '@components/Sidebar/components/SidebarNavigation/helpers'
import { ExpandType } from '@components/Sidebar/types'
import { useStyles } from '@hooks/useStyles'
import { Icon, Spreader } from '@landingi/landingi-ui-kit'
import { Indicator } from '@ui-kit/Indicator'
import { AnimatePresence, motion } from 'framer-motion'
import {
  Children,
  Dispatch,
  FC,
  Fragment,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useEffect
} from 'react'
import { NavLink } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { ItemAnimation } from '../ItemAnimation'
import styles from './Item.module.scss'

interface ItemProps {
  identifier?: string
  icon: string
  text: string
  to?: ExpandType
  children?: ReactNode
  sidebarMini?: boolean
  expand?: {
    id: ExpandType
    currentExpand: ExpandType
    setCurrentExpand: Dispatch<SetStateAction<ExpandType>>
  }
  onClick?: () => void
  hasExternalLink?: boolean
  hasIndicator?: boolean
}

export const Item: FC<ItemProps> = ({
  identifier,
  icon,
  text,
  to,
  children = undefined,
  sidebarMini,
  expand = {
    id: null,
    currentExpand: null,
    setCurrentExpand: () => {}
  },
  onClick,
  hasExternalLink,
  hasIndicator
}) => {
  const useActive = ({ isActive = false }) =>
    useStyles({
      [styles.item]: true,
      [styles['item--active']]: isActive,
      [styles['item--mini']]: sidebarMini
    })

  const buttonStyles = useStyles({
    [styles.item]: true,
    [styles['item--mini']]: sidebarMini
  })

  const { id, currentExpand, setCurrentExpand } = expand

  const mainPath = getMainPath()

  useEffect(() => {
    if (sidebarMini) {
      setCurrentExpand(null)
    }

    if (mainPath === id) {
      setCurrentExpand(id)
    }
  }, [sidebarMini, setCurrentExpand, id, mainPath])

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    setCurrentExpand(prev => (prev === id ? null : id))
  }

  const isExpand = currentExpand === id && id !== null

  const navLinkProps = children && !sidebarMini ? { onClick: handleClick } : {}

  const hasAllChildrenHidden =
    children !== undefined &&
    Children.toArray(children).every(child => child === null)

  const MenuItems = (
    <motion.div
      key='content'
      initial='collapsed'
      animate='open'
      exit='collapsed'
      variants={{
        open: { opacity: 1, height: 'auto', marginBottom: '10px' },
        collapsed: { opacity: 0, height: 0 }
      }}
      transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
    >
      {children}
    </motion.div>
  )

  const Content = (
    <Fragment>
      <Row alignItems='center' className={styles.item__content}>
        <Spreader spread='mini' />

        <Icon icon={icon} />

        <Spreader spread='mini' />

        {sidebarMini ? null : (
          <ItemAnimation>
            <Spreader spread='mini' />

            <span>{text}</span>
          </ItemAnimation>
        )}

        {hasIndicator ? (
          <Indicator className={styles['item__content--indicator']} />
        ) : null}
      </Row>

      {children && !sidebarMini ? (
        <ItemAnimation>
          <Icon size={12} icon={`icon-chevron-${!isExpand ? 'down' : 'up'}`} />
        </ItemAnimation>
      ) : null}

      {hasExternalLink && !sidebarMini ? (
        <Icon size={12} icon='icon-external-link-alt' />
      ) : null}
    </Fragment>
  )

  return hasAllChildrenHidden ? null : (
    <AnimatePresence>
      {!onClick && to && (
        <NavLink
          id={identifier}
          to={to}
          className={useActive}
          data-testid='nav-item'
          {...navLinkProps}
        >
          {Content}
        </NavLink>
      )}

      {onClick && !to && (
        <button
          id={identifier}
          type='button'
          className={buttonStyles}
          data-testid='nav-item'
          onClick={onClick}
        >
          {Content}
        </button>
      )}

      {isExpand && MenuItems}
    </AnimatePresence>
  )
}

Item.displayName = 'SidebarItem'
