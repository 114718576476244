import { Authenticated } from '@components/Authenticated'
import { Unauthenticated } from '@components/Unauthenticated'
import { GOOGLE_CLIENT_ID, RECAPTCHA_KEY } from '@config/env'
import { AnimationProvider } from '@pages/Authentication/contexts/animation'
import { CreditCardStepProvider } from '@pages/Authentication/contexts/creditCardStep'
import { RegisterProvider } from '@pages/Authentication/contexts/register'
import { SurveyProvider } from '@pages/Authentication/contexts/survey'
import CreditCardStepLayout from '@pages/Authentication/Layouts/CreditCardStep'
import RegistrationLayout from '@pages/Authentication/Layouts/Registration'
import SurveyLayout from '@pages/Authentication/Layouts/Survey'
import WhitelabelLayout from '@pages/Authentication/Layouts/WhitelabelPage'
import SpectrumMarketingLayout from '@pages/Authentication/Layouts/WhitelabelPage/SpectrumMarketing'
import ThriveCoachLayout from '@pages/Authentication/Layouts/WhitelabelPage/ThriveCoach'
import WebToLearnLayout from '@pages/Authentication/Layouts/WhitelabelPage/WebToLearn'
import ChangePassword from '@pages/Authentication/routes/ChangePassword'
import CreditCardStep from '@pages/Authentication/routes/CreditCardStep'
import ForgotPassword from '@pages/Authentication/routes/ForgotPassword'
import Login from '@pages/Authentication/routes/Login'
import RecoveryCode from '@pages/Authentication/routes/RecoveryCode'
import Register from '@pages/Authentication/routes/Register'
import Survey from '@pages/Authentication/routes/Survey'
import TwoFactorAuthentication from '@pages/Authentication/routes/TwoFactorAuthentication'
import WhitelabelChangePassword from '@pages/Authentication/routes/WhitelabelPages/GrantNewPassword'
import WhitelabelLogin from '@pages/Authentication/routes/WhitelabelPages/Login'
import WhitelabelRecoveryCode from '@pages/Authentication/routes/WhitelabelPages/RecoveryCode'
import WhitelabelForgotPassword from '@pages/Authentication/routes/WhitelabelPages/ResetPassword'
import SpectrumMarketingChangePassword from '@pages/Authentication/routes/WhitelabelPages/SpectrumMarketing/GrantNewPassword'
import SpectrumMarketingLogin from '@pages/Authentication/routes/WhitelabelPages/SpectrumMarketing/Login'
import SpectrumMarketingRecoveryCode from '@pages/Authentication/routes/WhitelabelPages/SpectrumMarketing/RecoveryCode'
import SpectrumMarketingForgotPassword from '@pages/Authentication/routes/WhitelabelPages/SpectrumMarketing/ResetPassword'
import SpectrumMarketingTwoFactorAuthentication from '@pages/Authentication/routes/WhitelabelPages/SpectrumMarketing/TwoFactorAuthentication'
import ThriveCoachChangePassword from '@pages/Authentication/routes/WhitelabelPages/ThriveCoach/GrantNewPassword'
import ThriveCoachLogin from '@pages/Authentication/routes/WhitelabelPages/ThriveCoach/Login'
import ThriveCoachRecoveryCode from '@pages/Authentication/routes/WhitelabelPages/ThriveCoach/RecoveryCode'
import ThriveCoachForgotPassword from '@pages/Authentication/routes/WhitelabelPages/ThriveCoach/ResetPassword'
import ThriveCoachTwoFactorAuthentication from '@pages/Authentication/routes/WhitelabelPages/ThriveCoach/TwoFactorAuthentication'
import WhitelabelTwoFactorAuthentication from '@pages/Authentication/routes/WhitelabelPages/TwoFactorAuthentication'
import WebToLearnChangePassword from '@pages/Authentication/routes/WhitelabelPages/WebToLearn/GrantNewPassword'
import WebToLearnLogin from '@pages/Authentication/routes/WhitelabelPages/WebToLearn/Login'
import WebToLearnRecoveryCode from '@pages/Authentication/routes/WhitelabelPages/WebToLearn/RecoveryCode'
import WebToLearnForgotPassword from '@pages/Authentication/routes/WhitelabelPages/WebToLearn/ResetPassword'
import WebToLearnTwoFactorAuthentication from '@pages/Authentication/routes/WhitelabelPages/WebToLearn/TwoFactorAuthentication'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { REGISTRATION } from '@routes/path'
import PropTypes from 'prop-types'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Outlet } from 'react-router-dom'

import { StepsProvider } from '../contexts/steps'

const Registration = ({ children }) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <RegisterProvider>
        <RegistrationLayout>{children}</RegistrationLayout>
      </RegisterProvider>
    </GoogleOAuthProvider>
  </GoogleReCaptchaProvider>
)

Registration.propTypes = {
  children: PropTypes.node.isRequired
}

const WhitelabelPage = ({ children }) => (
  <WhitelabelLayout>{children}</WhitelabelLayout>
)

WhitelabelPage.propTypes = {
  children: PropTypes.node.isRequired
}

const WebToLearnPage = ({ children }) => (
  <WebToLearnLayout>{children}</WebToLearnLayout>
)

WebToLearnPage.propTypes = {
  children: PropTypes.node.isRequired
}

const SpectrumMarketingPage = ({ children }) => (
  <SpectrumMarketingLayout>{children}</SpectrumMarketingLayout>
)

SpectrumMarketingPage.propTypes = {
  children: PropTypes.node.isRequired
}

const ThriveCoachPage = ({ children }) => (
  <ThriveCoachLayout>{children}</ThriveCoachLayout>
)

ThriveCoachPage.propTypes = {
  children: PropTypes.node.isRequired
}

const WhitelabelRegistration = ({ children }) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <RegisterProvider>{children}</RegisterProvider>
    </GoogleOAuthProvider>
  </GoogleReCaptchaProvider>
)

WhitelabelRegistration.propTypes = {
  children: PropTypes.node.isRequired
}

const surveyElement = (
  <Authenticated>
    <RegisterProvider>
      <SurveyProvider>
        <SurveyLayout>
          <Survey />
        </SurveyLayout>
      </SurveyProvider>
    </RegisterProvider>
  </Authenticated>
)

const element = (
  <StepsProvider>
    <AnimationProvider>
      <Outlet />
    </AnimationProvider>
  </StepsProvider>
)

export const routes = [
  {
    path: REGISTRATION.DEFAULT,
    element,
    children: [
      {
        path: REGISTRATION.DEFAULT,
        element: (
          <Unauthenticated>
            <Registration>
              <Login />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.CHANGE_PASSWORD.DEFAULT,
        element: (
          <Unauthenticated>
            <Registration>
              <ChangePassword />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.CREDIT_CARD_STEP.DEFAULT,
        element: (
          <Authenticated>
            <CreditCardStepProvider>
              <CreditCardStepLayout>
                <CreditCardStep />
              </CreditCardStepLayout>
            </CreditCardStepProvider>
          </Authenticated>
        )
      },
      {
        path: REGISTRATION.FORGOT_PASSWORD.DEFAULT,
        element: (
          <Unauthenticated>
            <Registration>
              <ForgotPassword />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.LOGIN.DEFAULT,
        element: (
          <Unauthenticated>
            <Registration>
              <Login />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.REGISTER.DEFAULT,
        exact: false,
        element: (
          <Unauthenticated>
            <Registration>
              <Register />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.LOGIN_WL_TEST,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WhitelabelPage>
                <WhitelabelLogin />
              </WhitelabelPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.FORGOT_PASSWORD_WL_TEST,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WhitelabelPage>
                <WhitelabelForgotPassword />
              </WhitelabelPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.CHANGE_PASSWORD_WL_TEST,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WhitelabelPage>
                <WhitelabelChangePassword />
              </WhitelabelPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.RECOVERY_CODE_WL_TEST,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WhitelabelPage>
                <WhitelabelRecoveryCode />
              </WhitelabelPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.TWO_FACTOR_AUTHENTICATION_WL_TEST,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WhitelabelPage>
                <WhitelabelTwoFactorAuthentication />
              </WhitelabelPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.LOGIN_WEBTOLEARN,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WebToLearnPage>
                <WebToLearnLogin />
              </WebToLearnPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.FORGOT_PASSWORD_WEBTOLEARN,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WebToLearnPage>
                <WebToLearnForgotPassword />
              </WebToLearnPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.CHANGE_PASSWORD_WEBTOLEARN,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WebToLearnPage>
                <WebToLearnChangePassword />
              </WebToLearnPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.RECOVERY_CODE_WEB_TO_LEARN,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WebToLearnPage>
                <WebToLearnRecoveryCode />
              </WebToLearnPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.TWO_FACTOR_AUTHENTICATION_WEB_TO_LEARN,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <WebToLearnPage>
                <WebToLearnTwoFactorAuthentication />
              </WebToLearnPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.LOGIN_SPECTRUM_MARKETING,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <SpectrumMarketingPage>
                <SpectrumMarketingLogin />
              </SpectrumMarketingPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.FORGOT_PASSWORD_SPECTRUM_MARKETING,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <SpectrumMarketingPage>
                <SpectrumMarketingForgotPassword />
              </SpectrumMarketingPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.CHANGE_PASSWORD_SPECTRUM_MARKETING,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <SpectrumMarketingPage>
                <SpectrumMarketingChangePassword />
              </SpectrumMarketingPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.RECOVERY_CODE_SPECTRUM_MARKETING,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <SpectrumMarketingPage>
                <SpectrumMarketingRecoveryCode />
              </SpectrumMarketingPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.TWO_FACTOR_AUTHENTICATION_SPECTRUM_MARKETING,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <SpectrumMarketingPage>
                <SpectrumMarketingTwoFactorAuthentication />
              </SpectrumMarketingPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.LOGIN_THRIVE_COACH,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <ThriveCoachPage>
                <ThriveCoachLogin />
              </ThriveCoachPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.FORGOT_PASSWORD_THRIVE_COACH,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <ThriveCoachPage>
                <ThriveCoachForgotPassword />
              </ThriveCoachPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.CHANGE_PASSWORD_THRIVE_COACH,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <ThriveCoachPage>
                <ThriveCoachChangePassword />
              </ThriveCoachPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.RECOVERY_CODE_THRIVE_COACH,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <ThriveCoachPage>
                <ThriveCoachRecoveryCode />
              </ThriveCoachPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.TWO_FACTOR_AUTHENTICATION_THRIVE_COACH,
        exact: false,
        element: (
          <Unauthenticated>
            <WhitelabelRegistration>
              <ThriveCoachPage>
                <ThriveCoachTwoFactorAuthentication />
              </ThriveCoachPage>
            </WhitelabelRegistration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.SIGNUP.DEFAULT,
        exact: false,
        element: (
          <Unauthenticated>
            <Registration>
              <Register />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.REGISTER.VARIANT,
        exact: false,
        element: (
          <Unauthenticated>
            <Registration>
              <Register />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.TWO_FACTOR_AUTHENTICATION.DEFAULT,
        exact: false,
        element: (
          <Unauthenticated>
            <Registration>
              <TwoFactorAuthentication />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.RECOVERY_CODE.DEFAULT,
        exact: false,
        element: (
          <Unauthenticated>
            <Registration>
              <RecoveryCode />
            </Registration>
          </Unauthenticated>
        )
      },
      {
        path: REGISTRATION.SURVEY.DEFAULT,
        element: surveyElement
      }
    ]
  }
]
