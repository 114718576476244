import { Heading, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { REGISTRATION } from '@routes/path'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import styles from './ChangePassword.module.scss'
import Form from './Form'

const ChangePassword = () => {
  const { searchParams, trialPeriod } = useRegisterContext()

  const { t } = useTranslation()

  return (
    <Suspense fallback={<>...</>}>
      <Column className={styles.layout}>
        <Heading level={1} bold>
          {t('registration.flow.change.password', {
            days: trialPeriod
          })}
        </Heading>

        <Form />

        <Spacer space='mini' />

        <Spacer space='tiny' />

        <Paragraph size={12} color='accent-2' align='center'>
          <Link to={`${REGISTRATION.LOGIN.DEFAULT}?${searchParams.toString()}`}>
            {t('registration.flow.go.back.to.sign.in', {
              a: chunks => <Link to={REGISTRATION.LOGIN.DEFAULT}>{chunks}</Link>
            })}
          </Link>
        </Paragraph>
      </Column>
    </Suspense>
  )
}

export default ChangePassword
