import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { generateFakeUuid } from '@helpers/data'
import {
  Button,
  Divider,
  emitCloseDropdown,
  Icon,
  List,
  ListItem,
  Paragraph,
  PerfectDropdown,
  Tooltip
} from '@landingi/landingi-ui-kit'
import { MODAL_DOWNLOAD_LANDING_PAGE } from '@pages/Landings/components/Modals/ModalDownloadLandingPage'
import { MODAL_DUPLICATE_TO_ACCOUNTS } from '@pages/Landings/components/Modals/ModalDuplicateToAccounts'
import { MODAL_LANDING_DUPLICATE } from '@pages/Landings/components/Modals/ModalLandingDuplicate'
import { MODAL_LANDING_RENAME } from '@pages/Landings/components/Modals/ModalLandingRename'
import { MODAL_LANDING_RESET } from '@pages/Landings/components/Modals/ModalLandingReset'
import { MODAL_LANDING_TRANSLATE } from '@pages/Landings/components/Modals/ModalLandingTranslate'
import { MODAL_MOVE_TO_ANOTHER_ACCOUNT } from '@pages/Landings/components/Modals/ModalMoveToAnotherAccount'
import { LandingOptionsDropdownTrigger } from '@pages/Landings/routes/common/LandingOptionsDropdownTrigger'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { LANDINGS } from '@routes/path'
import { archiveLanding } from '@services/landings/archive'
import { emitTimingToast } from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'

import styles from './Actions.module.scss'
import { dropdownItems } from './dropdownItems'

export const DropdownListItems = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { isSubaccount, isAgency } = useUserContext()

  const { landing, setNewLandingData } = useDashboardContext()

  const { name, identifier, master_hash, test_enabled } = landing

  const hasAccessToDuplicate = useAcl('landing_pages.landing.duplicate')
  const hasAccessToRename = useAcl('landing_pages.landing.rename')
  const hasAccessToPreview = useAcl('landing_pages.landing.preview')
  const hasAccessToTranslate = useAcl('landing_pages.landing.translate')

  const hasAccessToPublishingOptions = useAcl(
    'landing_pages.landing.publishing_options'
  )
  const hasAccessToLeads = useAcl('leads.view_list')
  const hasAccessToOptimization = useAcl('landing_pages.ab_tests.view_list')
  const hasAccessToMoveToAnotherAccount = useAcl(
    'landing_pages.landing.move_to_another_account'
  )
  const hasAccessToDuplicateToAnotherAccounts = useAcl(
    'landing_pages.landing.duplicate_to_another_workspace'
  )
  const hasAccessToReset = useAcl('landing_pages.landing.reset')
  const hasAccessToDownload = useAcl('landing_pages.landing.download')
  const hasAccessToArchive = useAcl('landing_pages.landing.archive')

  const hasAccessToMoveToAccount =
    isSubaccount && hasAccessToMoveToAnotherAccount

  const hasAccessToDuplicateToAccounts =
    (isAgency || isSubaccount) && hasAccessToDuplicateToAnotherAccounts

  const handleDuplicate = () =>
    NiceModal.show(MODAL_LANDING_DUPLICATE, {
      uuid: identifier,
      name
    })

  const handleRename = () =>
    NiceModal.show(MODAL_LANDING_RENAME, {
      uuid: identifier,
      name,
      callback: (name: string) => setNewLandingData({ name })
    })

  const handleTranslate = () => {
    emitCloseDropdown()

    NiceModal.show(MODAL_LANDING_TRANSLATE, {
      uuid: identifier,
      name
    })
  }

  const handlePreview = () =>
    window.open(`${NEW_LANDINGI_APP_URL}/preview/${master_hash}`, '_blank')

  const handlePublishingOptions = () => {
    const path = generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
      identifier
    })

    navigate(`${path}?dashboard`)
  }

  const handleLeads = () => {
    window.open(
      `${NEW_LANDINGI_APP_URL}/leads?${createSearchParams({
        source_type: 'landing',
        source_uuid: identifier
      }).toString()}`,
      '_self'
    )
  }

  const handleOptimization = () =>
    navigate(generatePath(LANDINGS.LANDINGS_AB, { identifier }))

  const handleMoveToAnotherAccount = () =>
    NiceModal.show(MODAL_MOVE_TO_ANOTHER_ACCOUNT, {
      uuid: identifier,
      name
    })

  const handleDuplicateToAccounts = () => {
    emitCloseDropdown()

    NiceModal.show(MODAL_DUPLICATE_TO_ACCOUNTS, {
      uuid: identifier,
      name
    })
  }

  const handleReset = () => {
    NiceModal.show(MODAL_LANDING_RESET, {
      uuid: identifier
    })
  }

  const handleDownload = () => {
    NiceModal.show(MODAL_DOWNLOAD_LANDING_PAGE, { uuid: identifier })
  }

  const handleArchive = async () => {
    try {
      await archiveLanding({ uuids: [identifier] })

      emitTimingToast({
        message: t('modal.landings.archive.toast_one'),
        type: 'success'
      })

      navigate(LANDINGS.LANDINGS_ARCHIVED)
    } catch {
      emitTimingToast({ message: t('error.page.generic.title'), type: 'error' })
    }
  }

  const callbacks = {
    handleDuplicate,
    handleRename,
    handlePreview,
    handlePublishingOptions,
    handleLeads,
    handleOptimization,
    handleMoveToAnotherAccount,
    handleDuplicateToAccounts,
    handleReset,
    handleDownload,
    handleArchive,
    handleTranslate
  } as const

  const accesses = {
    hasAccessToDuplicate,
    hasAccessToRename,
    hasAccessToPreview,
    hasAccessToPublishingOptions,
    hasAccessToLeads,
    hasAccessToOptimization,
    hasAccessToMoveToAccount,
    hasAccessToDuplicateToAccounts,
    hasAccessToReset,
    hasAccessToDownload,
    hasAccessToArchive,
    hasAccessToTranslate
  } as const

  const tooltips = {
    translateTooltip: test_enabled ? t('word.lp.translate.disabled') : ''
  }

  const disabled = {
    translateIsDisabled: test_enabled
  }

  return (
    <PerfectDropdown size='big' customTrigger={LandingOptionsDropdownTrigger}>
      <List>
        {dropdownItems?.map((element, index) => {
          const groupElements = element?.map(
            ({
              icon,
              title,
              isEnabled,
              badge,
              callback,
              tooltip,
              isDisabled,
              id
            }) => {
              const handleDropdownAction = () => {
                if (callbacks[callback as keyof typeof callbacks]) {
                  callbacks[callback as keyof typeof callbacks]()
                }

                emitCloseDropdown()
              }

              if (accesses[isEnabled as keyof typeof accesses]) {
                const tooltipContent = tooltip ? tooltips[tooltip] : ''
                const isDisabledButton = isDisabled
                  ? disabled[isDisabled]
                  : false

                return (
                  <ListItem key={generateFakeUuid()} variant='dropdown'>
                    <div>
                      <Tooltip
                        content={tooltipContent}
                        disabled={!tooltipContent}
                        placement='left'
                      >
                        <Button
                          variant='dropdown-element'
                          align='left'
                          hasIcon
                          id={id}
                          onClick={
                            isDisabledButton ? undefined : handleDropdownAction
                          }
                          isDisabled={isDisabledButton}
                        >
                          <Icon icon={icon} size={12} />

                          {t(title)}

                          {badge && (
                            <div className={styles.dropdown__badge}>
                              <Paragraph
                                size={10}
                                line={20}
                                padding='none'
                                weight={400}
                              >
                                {badge}
                              </Paragraph>
                            </div>
                          )}
                        </Button>
                      </Tooltip>
                    </div>
                  </ListItem>
                )
              }
              return null
            }
          )

          return [
            ...groupElements,
            index + 1 < dropdownItems?.length && (
              <Divider type='dropdown' key={generateFakeUuid()} />
            )
          ]
        })}
      </List>
    </PerfectDropdown>
  )
}

DropdownListItems.displayName =
  'LandingsDashboardHeaderRowActionsDropdownListItems'
