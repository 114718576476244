import SelectBoxRadio from '@components/SelectBoxRadio'
import Briefcase from '@images/registration/survey/questionIcons/briefcase.png'
import CEO from '@images/registration/survey/questionIcons/ceo.png'
import ChatBubble from '@images/registration/survey/questionIcons/chat-bubble.png'
import Freelancer from '@images/registration/survey/questionIcons/freelancer.png'
import ShakingHands from '@images/registration/survey/questionIcons/shaking-hands.png'
import { Heading, InputForm, Spacer } from '@landingi/landingi-ui-kit'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import { LayoutGroup, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import FooterButtons from './FooterButtons'
import styles from './Questions.module.scss'

/**
 * Second - stateful presentational component representing survey question
 * @param {object} values - formik values
 * @param {bool} isValid - formik isValid
 * @param {bool} isSubmitting - formik isSubmitting
 * @param {function} handleBack - function that handles clicking back arrow to go back to previous quesiton
 * @param {function} handleSkipStep - function that handles clicking skip button
 * @param {function} setFieldValue - formik setFieldValue
 * @param {function} validateField - formik validateField
 * @param {function} validateForm - formik validateForm
 * @param {bool} isLastQuestion - information whether question is last or not
 * @param {function} handleChange - change formik values
 * @param {function} onBlur - callback called on blur input
 * @return {object} - children
 */
const Second = ({
  values,
  isValid,
  isSubmitting,
  errors,
  touched,
  handleBack,
  handleSkipStep,
  setFieldValue,
  validateField,
  validateForm,
  isLastQuestion,
  handleChange,
  onBlur
}) => {
  const {
    activeSurveyBoxesIDs,
    setActiveSurveyBoxesIDs,
    questionsSkippedInfo,
    setQuestionsSkippedInfo
  } = useSurveyContext()
  const { t } = useTranslation()

  useEffect(() => {
    validateForm()
  }, [])

  /**
   * handles clicking Next button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleNext = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    newQuestionsSkippedInfo.second = false
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
  }

  /**
   * handles clicking Skip step button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleSkipStepWrapper = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    setFieldValue('purpose_signup', '')
    setFieldValue('otherName', '')

    const newActiveSurveyBoxes = activeSurveyBoxesIDs.filter(
      id => !questionsIDs.includes(id)
    )

    setActiveSurveyBoxesIDs(newActiveSurveyBoxes)

    newQuestionsSkippedInfo.second = true
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
    handleSkipStep()
  }

  const questionsIDs = [
    'marketingTips',
    'tipsForManagersAndOwners',
    'tipsForFreelancers',
    'tipsForAgencies'
  ]

  const handleFieldChange = id => {
    validateField('purpose_signup')
    validateField('otherName')

    const { purpose_signup } = values

    let newActiveSurveyBoxes

    if (purpose_signup) {
      newActiveSurveyBoxes = activeSurveyBoxesIDs.map(activeID =>
        questionsIDs.includes(activeID) ? id : activeID
      )

      setActiveSurveyBoxesIDs(newActiveSurveyBoxes)
    } else {
      setActiveSurveyBoxesIDs([...activeSurveyBoxesIDs, id])
    }
  }

  const setMarketingTips = () => handleFieldChange('marketingTips')
  const setTipsFormMenagersAndOwnser = () =>
    handleFieldChange('tipsForManagersAndOwners')
  const setTipsForFreelancers = () => handleFieldChange('tipsForFreelancers')
  const setTipsForAgencies = () => handleFieldChange('tipsForAgencies')

  return (
    <LayoutGroup>
      <motion.div
        layout='position'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Column>
          <motion.div layout>
            <Row>
              <Heading level={1}>
                {t('registration.flow.survey.who.do.you.create.for')}
              </Heading>
            </Row>
          </motion.div>

          <div>
            <div className={styles['second-question-boxes']}>
              {/* purpose_signup/business */}
              <SelectBoxRadio
                field={{
                  name: 'purpose_signup',
                  value: values.purpose_signup,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='business'
                className={styles['second-question-boxes__business']}
                variant='responsive'
                i18n={{
                  title: t(
                    'registration.flow.survey.for.the.company.i.work.for'
                  )
                }}
                iconSource={Briefcase}
                onChange={setMarketingTips}
              />
              {/* purpose_signup/company */}
              <SelectBoxRadio
                field={{
                  name: 'purpose_signup',
                  value: values.purpose_signup,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='company'
                className={styles['second-question-boxes__company']}
                variant='responsive'
                i18n={{
                  title: t('registration.flow.survey.for.the.company.i.manage')
                }}
                iconSource={CEO}
                onChange={setTipsFormMenagersAndOwnser}
              />
              {/* purpose_signup/freelancer */}
              <SelectBoxRadio
                field={{
                  name: 'purpose_signup',
                  value: values.purpose_signup,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='freelancer'
                className={styles['second-question-boxes__freelancer']}
                variant='responsive'
                i18n={{
                  title: t('registration.flow.survey.freelancer')
                }}
                iconSource={Freelancer}
                onChange={setTipsForFreelancers}
              />
              {/* purpose_signup/agency */}
              <SelectBoxRadio
                field={{
                  name: 'purpose_signup',
                  value: values.purpose_signup,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='agency'
                className={styles['second-question-boxes__agency']}
                variant='responsive'
                i18n={{
                  title: t('registration.flow.survey.as.agency')
                }}
                iconSource={ShakingHands}
                onChange={setTipsForAgencies}
              />
              {/* purpose_signup/forOther */}
              <SelectBoxRadio
                field={{
                  name: 'purpose_signup',
                  value: values.purpose_signup,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='forOther'
                className={styles['second-question-boxes__forOther']}
                variant='responsive'
                i18n={{
                  title: t('registration.flow.survey.other')
                }}
                iconSource={ChatBubble}
                onChange={setMarketingTips}
                activeContent={
                  <InputForm
                    field={{
                      name: 'otherName',
                      value: values.otherName,
                      onChange: handleChange,
                      onBlur
                    }}
                    form={{ errors, touched }}
                    i18n={{
                      placeholder: t('registration.flow.survey.for.who')
                    }}
                    id='firstName'
                    autoFocus
                    allowHotjar
                    className={styles['no-min-height']}
                  />
                }
              />
            </div>
          </div>

          <Spacer space='medium' />

          <Spacer space='mini' />

          <FooterButtons
            isLastQuestion={isLastQuestion}
            isSubmitting={isSubmitting}
            handleSkipStep={handleSkipStepWrapper}
            handleNext={handleNext}
            isValid={isValid}
          />
        </Column>
      </motion.div>
    </LayoutGroup>
  )
}

Second.propTypes = {
  values: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func,
  touched: PropTypes.instanceOf(Object),
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleSkipStep: PropTypes.func,
  validateForm: PropTypes.func,
  validateField: PropTypes.func,
  isLastQuestion: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object)
}

export default Second
