import { useStyles } from '@hooks/useStyles'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { forwardRef, Fragment, HTMLAttributes, ReactNode } from 'react'

import styles from './Badge.module.scss'

export interface BadgeProps
  extends Omit<HTMLAttributes<HTMLSpanElement>, 'className'> {
  children: ReactNode
  className?: string | string[]
  variant?:
    | 'info-border'
    | 'warning-border'
    | 'error-border'
    | 'success-border'
    | 'calmviolet-border'
    | 'almostcyan-border'
    | 'neutral4-border'
    | 'info-filled'
    | 'warning-filled'
    | 'error-filled'
    | 'success-filled'
    | 'calmviolet-filled'
    | 'almostcyan-filled'
    | 'neutral4-filled'
  radius?: 2 | 250
  weight?: 400 | 600
  icon?: string
  isTextUppercase?: boolean
  'data-testid'?: string
}

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      children,
      className = '',
      variant = 'info-filled',
      weight = 400,
      icon,
      isTextUppercase = true,
      'data-testid': dataTestId = 'badge',
      radius = 2,
      ...rest
    },
    ref
  ) => {
    const badgeStyles = useStyles(
      {
        [styles.badge]: true,
        [styles[`badge--${variant}`]]: true,
        [styles[`badge--radius-${radius}`]]: true,
        [styles[`badge--weight-${weight}`]]: true,
        [styles[`badge--uppercase`]]: isTextUppercase
      },
      className
    )

    return (
      <span
        {...rest}
        ref={ref}
        data-testid={dataTestId}
        className={badgeStyles}
      >
        {icon && (
          <Fragment>
            <Icon size={12} icon={icon} data-testid='icon' />
            <Spreader spread={5} />
          </Fragment>
        )}

        {children}
      </span>
    )
  }
)

Badge.displayName = 'Badge'
