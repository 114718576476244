export const MODAL_ERROR_ACL = 'MODAL_ERROR_ACL'
export const MODAL_ERROR_EXPIRED_ACCOUNT = 'MODAL_ERROR_EXPIRED_ACCOUNT'

export const MODAL_PRIVACY_POLICY = 'MODAL_PRIVACY_POLICY'

export const MODAL_PAYPAL = 'MODAL_PAYPAL'

export const MODAL_POPUP_DOWNLOAD = 'MODAL_POPUP_DOWNLOAD'
export const MODAL_POPUP_DELETE = 'MODAL_POPUP_DELETE'
export const MODAL_POPUP_RENAME = 'MODAL_POPUP_RENAME'
export const MODAL_POPUP_DUPLICATE = 'MODAL_POPUP_DUPLICATE'

export const MODAL_LIGHTBOX_RENAME = 'MODAL_LIGHTBOX_RENAME'
export const MODAL_LIGHTBOX_DELETE = 'MODAL_LIGHTBOX_DELETE'
export const MODAL_LIGHTBOX_DUPLICATE = 'MODAL_LIGHTBOX_DUPLICATE'

export const MODAL_SMART_SECTION_RENAME = 'MODAL_SMART_SECTION_RENAME'
export const MODAL_SMART_SECTION_DELETE = 'MODAL_SMART_SECTION_DELETE'
export const MODAL_SMART_SECTION_DUPLICATE = 'MODAL_SMART_SECTION_DUPLICATE'
export const MODAL_SMART_SECTION_LOCATE = 'MODAL_SMART_SECTION_LOCATE'
export const MODAL_SMART_SECTION_DELETE_GROUP =
  'MODAL_SMART_SECTION_DELETE_GROUP'

export const MODAL_PRODUCT_DELETE = 'MODAL_PRODUCT_DELETE'
export const MODAL_PRODUCT_ADD_EDIT = 'MODAL_PRODUCT_ADD_EDIT'

export const MODAL_ORDER_DELETE = 'MODAL_ORDER_DELETE'

export const MODAL_CONFIRM_IDENTITY = 'MODAL_CONFIRM_IDENTITY'

export const MODAL_WORKING_ON_IT = 'MODAL_WORKING_ON_IT'

export const UPGRADE_MODALS = {
  DOMAIN: 'domain_upgrade',
  PUBLISH_LIMIT: 'publish_limit',
  SUBACCOUNT_PUBLISH_LIMIT: 'subaccount_publish_limit',
  AGENCY: 'agency_upgrade',
  SMART_SECTIONS: 'smart_sections_upgrade',
  POPUPS: 'popups_upgrade',
  LIGHTBOXES: 'lightboxes_upgrade',
  OPTIMIZATION: 'optimization_upgrade',
  ECOMMERCE: 'ecommerce_upgrade',
  FONTS: 'fonts_upgrade',
  AGENCY_HUB: 'agency_hub_upgrade'
}
