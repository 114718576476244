import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { LANDINGS } from '@routes/path'
import { PublicationType } from '@services/landings/list/types'
import {
  GetLandingPagePublishingOptionsResponse,
  useGetLandingPagePublishingOptions
} from '@services/landings/publishingOptions'
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import { useUrlsCollection } from './hooks/useUrlsCollection'

type UsePublishingOptionsStateArgs = {
  uuid: string
}

const usePublishingOptionsState = ({ uuid }: UsePublishingOptionsStateArgs) => {
  const navigate = useNavigate()
  const { mutate } = useSWRConfig()
  const { search } = useLocation()

  const [isDashboardPath, setDashboardPath] = useState(
    search.includes('dashboard')
  )

  const dashoardPath = generatePath(LANDINGS.LANDINGS_DASHBOARD, {
    identifier: uuid
  })

  const handleClose = async () => {
    navigate(isDashboardPath ? dashoardPath : LANDINGS.LANDINGS)

    mutate(
      key =>
        typeof key === 'string' && key.startsWith('landing-page/landings?'),
      undefined,
      { revalidate: true }
    )

    setDashboardPath(false)
  }

  const {
    data: publishingOptions,
    isLoading: publishingOptionsIsLoading,
    mutate: mutateLandingPagePublishingOptions
  } = useGetLandingPagePublishingOptions({
    uuid
  })

  const [defaultChangeUrlProps, setDefaultChangeUrlProps] = useState({
    selectedDomain: '',
    selectedPath: ''
  })

  useEffect(() => {
    if (publishingOptions?.landingPagePublicationType === TYPES.CUSTOM) {
      const { hostname, pathname } = new URL(
        publishingOptions.landingPageMainUrl
      )

      setDefaultChangeUrlProps({
        selectedDomain: hostname,
        selectedPath: pathname.split('/').pop() || ''
      })
    }
  }, [publishingOptions])

  const setPublicationType = ({
    publicationType
  }: {
    publicationType: PublicationType
  }) => {
    if (publishingOptions) {
      mutateLandingPagePublishingOptions(
        {
          ...publishingOptions,
          landingPagePublicationType: publicationType
        },
        {
          revalidate: false
        }
      )
    }
  }

  const handleChangeUrl = (domain: string, path?: string) => {
    setDefaultChangeUrlProps(prev => ({
      ...prev,
      selectedDomain: domain,
      selectedPath: path?.replace('/', '') || ''
    }))

    navigate(
      generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.CHANGE_URL, {
        identifier: uuid
      })
    )
  }

  const landingPageUrls = useUrlsCollection({ uuid })

  const isInitialLoading = publishingOptionsIsLoading

  return {
    uuid,
    publishingOptions: {
      data: publishingOptions as GetLandingPagePublishingOptionsResponse,
      isLoading: publishingOptionsIsLoading
    },
    isInitialLoading,
    landingPageUrls,
    handleChangeUrl,
    setPublicationType,
    defaultChangeUrlProps,
    handleClose
  }
}
type PublishingOptionsState = ReturnType<typeof usePublishingOptionsState>

const PublishingOptionsContext = createContext<PublishingOptionsState | null>(
  null
)

interface PublishingOptionsProviderProps {
  children: ReactNode
  uuid: string
}

export const PublishingOptionsProvider: FC<PublishingOptionsProviderProps> = ({
  children,
  uuid
}) => {
  const value = usePublishingOptionsState({ uuid })

  return (
    <PublishingOptionsContext.Provider value={value}>
      {children}
    </PublishingOptionsContext.Provider>
  )
}

export const usePublishingOptionsContext = () => {
  const publishingOptions = useContext(PublishingOptionsContext)

  if (!publishingOptions) {
    throw new Error(
      'usePublishingOptionsContext must be used inside PublishingOptionsProvider'
    )
  }

  return publishingOptions
}
