import { ItemGroup } from '@components/Sidebar/components/Menu/ItemGroup'
import {
  AGENCY as AGENCY_ACCOUNT,
  CLIENT as CLIENT_ACCOUNT,
  NORMAL as NORMAL_ACCOUNT
} from '@constants/customerTypes'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { generateFakeUuid } from '@helpers/data'
import { useLogout } from '@hooks/useLogout'
import {
  Button,
  Divider,
  Icon,
  List,
  ListItem,
  Paragraph,
  PerfectDropdown,
  Spacer
} from '@landingi/landingi-ui-kit'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Account.module.scss'
import { AccountData } from './components/AccountData'
import { ITEMS, ItemsTypes } from './types'

interface AccountProps {
  isBiggerMobile?: boolean
}

type Events = {
  [key: string]: () => Promise<any>
}

export const Account: FC<AccountProps> = ({ isBiggerMobile }) => {
  const { t } = useTranslation()

  const handleLogoutAccount = useLogout()

  const {
    userName,
    userEmail,
    accountType,
    isTypeOwner,
    isTypeAdmin,
    isTypeDesigner,
    isTypeAccountant,
    isTypeReadOnly,
    user
  } = useUserContext()

  const ECOMMERCE_MODULE_FF = useFeatureFlags('ECOMMERCE_MODULE')
  const DISABLE_INVOICES_FF = useFeatureFlags('DISABLE_INVOICES')

  const itemsAccesses = {
    [ITEMS.SUBACCOUNTS]: {
      [AGENCY_ACCOUNT]: true,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]: false
    },
    [ITEMS.SETTINGS]: {
      [AGENCY_ACCOUNT]: true,
      [CLIENT_ACCOUNT]: true,
      [NORMAL_ACCOUNT]: !isTypeAccountant
    },
    [ITEMS.PAYMENT_GATEWAYS]: {
      [AGENCY_ACCOUNT]: ECOMMERCE_MODULE_FF,
      [CLIENT_ACCOUNT]: ECOMMERCE_MODULE_FF,
      [NORMAL_ACCOUNT]:
        ECOMMERCE_MODULE_FF &&
        !isTypeDesigner &&
        !isTypeAccountant &&
        !isTypeReadOnly
    },
    [ITEMS.USERS]: {
      [AGENCY_ACCOUNT]: isTypeOwner || isTypeAdmin,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]: !isTypeAccountant && (isTypeOwner || isTypeAdmin)
    },
    [ITEMS.BETA_FEATURES]: {
      [AGENCY_ACCOUNT]: true,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]: isTypeOwner || isTypeAdmin
    },
    [ITEMS.INVOICES]: {
      [AGENCY_ACCOUNT]: !DISABLE_INVOICES_FF,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]:
        !DISABLE_INVOICES_FF && (isTypeOwner || isTypeAdmin || isTypeAccountant)
    },
    [ITEMS.PLAN_AND_BILLING]: {
      [AGENCY_ACCOUNT]: !user?.has_custom_offer,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]:
        (isTypeOwner || isTypeAdmin || isTypeAccountant) &&
        !user?.has_custom_offer
    }
  }

  const events: Events = {
    handleLogoutAccount
  }

  const hasAccessToItem = (item: ItemsTypes) =>
    itemsAccesses[item][accountType || NORMAL_ACCOUNT]

  const topbarDropdown = AccountData().map(
    ({ label, icon, href, onClick, access, divider }) => {
      const buttonAnchor = href
        ? { tag: 'a' as const, href, buttonStyle: true }
        : {}

      const event = onClick ? events[onClick] : undefined

      const button = (
        <ListItem variant='dropdown'>
          <Button
            variant='dropdown-element'
            onClick={event}
            hasIcon
            {...buttonAnchor}
          >
            <Icon icon={icon} size={12} />
            {t(label)}
          </Button>
        </ListItem>
      )

      return (
        <Fragment key={generateFakeUuid()}>
          {divider === 'top' ? <Divider variant='horizontal' /> : null}

          {access ? hasAccessToItem(access) && button : button}

          {divider === 'bottom' ? <Divider variant='horizontal' /> : null}
        </Fragment>
      )
    }
  )

  const sidebarDropdown = AccountData().map(
    ({ label, href, onClick, access }) => {
      const event = onClick ? events[onClick] : undefined

      const itemGroup = (
        <ItemGroup
          key={generateFakeUuid()}
          href={href}
          onClick={event}
          text={t(label)}
        />
      )

      return access ? hasAccessToItem(access) && itemGroup : itemGroup
    }
  )

  return isBiggerMobile ? (
    <div>{sidebarDropdown}</div>
  ) : (
    <div id='topbar-account' data-testid='topbar-account'>
      <PerfectDropdown
        className={styles.account}
        icon='icon-users-circle'
        label={userName || t('topbar.account.label')}
        fontColor='color-3'
        fontWeight={600}
        hasHoverLabel
      >
        <div className={styles['account__user-details']}>
          <Paragraph
            className={styles['account__user-details--text']}
            size={16}
            weight={700}
            padding='none'
          >
            {userName}
          </Paragraph>

          <Paragraph
            className={styles['account__user-details--text']}
            size={12}
            padding='none'
          >
            {userEmail}
          </Paragraph>

          <Spacer space='small' />
        </div>

        <List>{topbarDropdown}</List>
      </PerfectDropdown>
    </div>
  )
}

Account.displayName = 'TopbarAccount'
