import { Heading, Spacer } from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import TwoFactorAuthenticationForm from './Form'
import styles from './TwoFactorAuthentication.module.scss'

/**
 * TwoFactorAuthentication - stateless presentational component
 * @return {object} An object of children
 */
const TwoFactorAuthentication = () => {
  const { t } = useTranslation()
  const { trialPeriod } = useRegisterContext()

  return (
    <Column className={styles['register-cta']}>
      <Heading level={1} bold>
        {t('two.factor.authentication.page.heading', {
          days: trialPeriod
        })}
      </Heading>

      {t('two.factor.authentication.enter.your.auth.code')}

      <Spacer space='medium' />

      <TwoFactorAuthenticationForm />

      <Spacer space='mini' />

      <Spacer space='tiny' />
    </Column>
  )
}

TwoFactorAuthentication.displayName = 'TwoFactorAuthentication'

export default TwoFactorAuthentication
