import {
  ACCOUNTANT,
  ADMIN,
  AGENCY,
  CLIENT,
  DESIGNER,
  MANAGER,
  NORMAL,
  OWNER,
  READONLY,
  USER
} from '@constants/customerTypes'
import { SubscriptionStatusType } from '@constants/subscription'
import * as Sentry from '@sentry/react'
import { useGetAuth } from '@services/authentication'
import { AxiosError } from 'axios'
import useSWR from 'swr'

export type AccountInfoResponse = {
  created_at: { formatted: string; timestamp: number }
  expires_at: {
    formatted: string | null
    timestamp: number
    expiration_days_left: number
  }
  current_user: {
    identifier: string
    email: string
    name: string
    language: string
    type:
      | typeof ACCOUNTANT
      | typeof ADMIN
      | typeof AGENCY
      | typeof CLIENT
      | typeof DESIGNER
      | typeof MANAGER
      | typeof NORMAL
      | typeof OWNER
      | typeof READONLY
      | typeof USER
    does_have_new_notification: boolean
  }
  identifier: string
  logo: string
  name: string
  owner: { identifier: string; email: string; name: string }
  payment: {
    does_account_have_pinned_credit_card: boolean
    was_account_payment_registered: boolean
    has_first_payment_more_ore_equal_one_month: boolean
    period: 1 | 12
  }
  plan: { identifier: string; name: string; record_key: string }
  profile: { country: string }
  statuses: {
    in_free_trial: boolean
    is_active: boolean
  }
  subscription: {
    is_in_subscription: boolean
    status: SubscriptionStatusType
    credit_card: {
      is_expired: boolean
      days_to_expire: number
    } | null
    error: {
      code: string | 'Default'
    } | null
  }
  timezone: string
  type: typeof AGENCY | typeof CLIENT | typeof NORMAL
  unread_leads_count: number
  support_mode: boolean
  platform_stage: 'MQL' | 'SAL' | 'SQL' | 'PAID'
  currency: string
  display_free_trial_infobar: boolean
  has_custom_offer: boolean
  is_default_registration: boolean
}

export const useGetAccountInfo = () => {
  const { isAuth } = useGetAuth()

  const { data, isLoading, error, ...rest } = useSWR<
    AccountInfoResponse,
    AxiosError
  >(isAuth ? `account/info` : null)

  const accountType = data?.type
  const isAgency = data?.type === AGENCY
  const isSubaccount = data?.type === CLIENT
  const isNormal = data?.type === NORMAL

  const isTypeOwner = data?.current_user?.type === OWNER
  const isTypeAdmin = data?.current_user?.type === ADMIN
  const isTypeManager = data?.current_user?.type === MANAGER
  const isTypeClient = data?.current_user?.type === CLIENT
  const isTypeDesigner = data?.current_user?.type === DESIGNER
  const isTypeAccountant = data?.current_user?.type === ACCOUNTANT
  const isTypeReadOnly = data?.current_user?.type === READONLY

  const hasFreeTrial = data?.statuses.in_free_trial
  const isActive = data?.statuses.is_active
  const hasSubscription = data?.subscription.is_in_subscription

  const accountName = data?.name
  const accountID = data?.identifier
  const accountCurrency = data?.currency

  const userName = data?.current_user?.name
  const userEmail = data?.current_user?.email
  const userID = data?.current_user?.identifier
  const language = data?.current_user?.language
  const isNewNotification = data?.current_user?.does_have_new_notification

  const ownerName = data?.owner?.name
  const ownerEmail = data?.owner?.email
  const ownerID = data?.owner?.identifier

  const userLogo = data?.logo
  const planRecordKey = data?.plan?.record_key
  const planName = data?.plan?.name
  const planPackageUuid = data?.plan?.identifier
  const leadsCount = data?.unread_leads_count
  const isSupportMode = data?.support_mode
  const timezone = data?.timezone
  const createdAt = data?.created_at
  const expiresAt = data?.expires_at

  const daysLeftToAccountExpiration = expiresAt?.expiration_days_left

  const hasPinnedCreditCard =
    data?.payment?.does_account_have_pinned_credit_card
  const billingPeriod = data?.payment?.period

  const creditCardExpire = data?.subscription?.credit_card
  const subscription = data?.subscription
  const isCreditCardExpired = data?.subscription.credit_card?.is_expired

  const subscriptionStatus = data?.subscription?.status
  const hasCustomOffer = data?.has_custom_offer
  const isDefaultRegistration = data?.is_default_registration

  Sentry.setUser({ email: userEmail })

  return {
    accountType,
    isAgency,
    isActive,
    isSubaccount,
    isNormal,
    isTypeOwner,
    isTypeAdmin,
    isTypeClient,
    isTypeManager,
    isTypeDesigner,
    isTypeAccountant,
    isTypeReadOnly,
    hasFreeTrial,
    hasSubscription,
    accountName,
    accountID,
    accountCurrency,
    userName,
    userEmail,
    userID,
    language,
    isNewNotification,
    ownerName,
    ownerEmail,
    ownerID,
    userLogo,
    planRecordKey,
    planName,
    planPackageUuid,
    leadsCount,
    isSupportMode,
    hasPinnedCreditCard,
    timezone,
    data,
    isLoading,
    error,
    createdAt,
    expiresAt,
    daysLeftToAccountExpiration,
    creditCardExpire,
    subscription,
    billingPeriod,
    subscriptionStatus,
    hasCustomOffer,
    isDefaultRegistration,
    isCreditCardExpired,
    ...rest
  }
}
