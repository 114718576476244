import { Price } from '@components/Credits/Price'
import { MODAL_BUY_CREDITS } from '@components/Modals/ModalBuyCredits'
import { CLICK_SELECT_PACKAGE } from '@constants/mixpanel'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { formatNumeric } from '@helpers/data'
import { Divider, Loader, Modal } from '@landingi/landingi-ui-kit'
import { useGetCredits } from '@services/credits/useGetCredits'
import { useGetCreditsPackage } from '@services/credits/useGetCreditsPackage'
import { mixpanelEvent } from '@services/mixpanel'
import { Button, ButtonLink } from '@ui-kit/Button'
import { Heading } from '@ui-kit/Heading'
import { Icon } from '@ui-kit/Icon'
import { Panel } from '@ui-kit/Panel'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import styles from './ModalCreditsPackage.module.scss'

interface ModalCreditsPackageProps {
  properties: {
    click_location: string
    type: string
  }
}

export const ModalCreditsPackage = NiceModal.create<ModalCreditsPackageProps>(
  ({ properties }) => {
    const { t } = useTranslation()
    const { remove } = useModal()

    const creditsPackage = useGetCreditsPackage()
    const { credits, isLoading } = useGetCredits()

    const handleOpenBuyCreditsModal = () => {
      mixpanelEvent({
        name: CLICK_SELECT_PACKAGE,
        properties
      })

      NiceModal.show(MODAL_BUY_CREDITS, {
        properties
      })

      remove()
    }

    return (
      <Modal
        isActive
        isClosable
        onClick={remove}
        customZIndex={{
          backdrop: 250999,
          dialog: 251000
        }}
        i18n={{
          title: t('modals.credits.packages.title')
        }}
        className={styles.modal}
      >
        {creditsPackage && !isLoading ? (
          <Fragment>
            <Heading level={3} data-testid='credits-amount'>
              <Icon icon='icon-coin' />
              <Spreader spread={5} />
              <Trans
                i18nKey='modals.credits.packages.credits.left'
                components={{
                  b: <b />
                }}
                values={{ credits: formatNumeric(credits ?? 0) }}
              />
            </Heading>

            <Spacer space={25} />

            <Panel border='neutral-2' surface={0}>
              <Heading level={3} weight={600}>
                {t('modals.credits.packages.credits', {
                  credits: formatNumeric(creditsPackage.amount)
                })}
              </Heading>

              <Spacer space={15} />

              <Price price={creditsPackage.price} />

              <Spacer space={20} />

              <Divider />

              <Spacer space={15} />

              <Paragraph size={12}>
                {t('modals.credits.packages.description')}
              </Paragraph>

              <Spacer space={20} />

              <Button
                onClick={handleOpenBuyCreditsModal}
                data-testid='button-buy-now'
              >
                {t('change.plan.page.buy.now')}
              </Button>
            </Panel>

            <Spacer space={20} />

            <ButtonLink
              variant='text-neutral'
              icon='icon-external-link-alt'
              size={12}
              href={t('modals.credits.packages.what.are.credits.url')}
              target='_blank'
            >
              {t('modals.credits.packages.what.are.credits')}
            </ButtonLink>
          </Fragment>
        ) : (
          <Loader data-testid='loader' />
        )}
      </Modal>
    )
  }
)
