import { exhaustiveMatchingGuard } from '@helpers/exhaustiveMatchingGuard'
import { Icon, Tooltip } from '@landingi/landingi-ui-kit'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { PublicationType as PublicationTypes } from '@services/landings/list/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface PublicationTypeProps {
  publicationType: PublicationTypes
}

export const PublicationType: FC<PublicationTypeProps> = ({
  publicationType
}) => {
  const { t } = useTranslation()

  switch (publicationType) {
    case TYPES.TEST:
      return (
        <Tooltip
          content={t('publishing.options.alert.warning')}
          placement='top'
        >
          <Icon icon='icon-flask-fill' color='warning' />
        </Tooltip>
      )
    case TYPES.CUSTOM:
      return (
        <Tooltip
          content={t('publishing.options.custom.domain.icon.tooltip')}
          placement='top'
        >
          <Icon icon='icon-globe' color='primary' />
        </Tooltip>
      )
    case TYPES.WORDPRESS:
      return (
        <Tooltip
          content={t('publishing.options.wordpress.domain.icon.tooltip')}
          placement='top'
        >
          <Icon icon='icon-wordpress-alt' color='primary' />
        </Tooltip>
      )
    case TYPES.EMBED:
      return (
        <Tooltip
          content={t('publishing.options.embed.domain.icon.tooltip')}
          placement='top'
        >
          <Icon icon='icon-server' color='primary' />
        </Tooltip>
      )
    case TYPES.NONE:
      return null
    default:
      return exhaustiveMatchingGuard(publicationType)
  }
}

PublicationType.displayName = 'PublishingOptionsViewsMainTablePublicationType'
