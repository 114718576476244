import { ADDONS_IDS } from '@constants/addonsIds'
import GeneratingSEO from '@images/addons/icons/GeneratingSEO.svg'
import {
  EDITOR,
  INCLUDED_IN_PLAN
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { AI, AIAddonTemplate } from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getAISeoGenerationAddon: (
  t: TFunction<'translation', undefined>
) => AIAddonTemplate = t => ({
  type: AI,
  name: t('addons.store.ai.seo.generation'),
  title: t('addons.store.ai.seo.generation'),
  tileDescription: (
    <Trans i18nKey='addons.store.ai.seo.generation.description' />
  ),
  pageDescription: t('addons.store.ai.seo.generation.page.description'),
  badges: [INCLUDED_IN_PLAN, EDITOR],
  imageSrc: GeneratingSEO,
  id: ADDONS_IDS.AI_SEO_GENERATION,
  overview: getOverview(t),
  features: getFeatures(),
  pricing: getPricing(t),
  price: '300',
  useCases: getUseCases(t),
  faq: getFAQ(t),
  CTALink: t('addons.store.ai.seo.generation.pricing.cta.link')
})
