import { useStyles } from '@hooks/useStyles'
import SummerSaleMateBanner from '@images/dealmate/promo-banner-credit-card-ss.png'
import { Badge, Heading, Image, Paragraph, Toggle } from '@ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './SalePromo12Banner.module.scss'

interface SummerSaleBannerProps {
  isChecked: boolean
  handleToggle: () => undefined
  currency: string
  discount: number
}

export const SummerSaleBanner: FC<SummerSaleBannerProps> = ({
  isChecked,
  handleToggle,
  currency,
  discount
}) => {
  const { t } = useTranslation()

  const salePromoBannerStyles = useStyles({
    [styles['sale-promo-banner']]: true,
    [styles['sale-promo-banner--checked']]: isChecked
  })

  return (
    <Row className={salePromoBannerStyles}>
      <Toggle
        id='summer-sale-toggle'
        checked={isChecked}
        className={styles['sale-promo-banner__toggle']}
        onChange={handleToggle}
      />

      <Image
        className={styles['sale-promo-banner__image']}
        src={SummerSaleMateBanner}
        width='100%'
      />

      <Column
        className={styles['sale-promo-banner__text']}
        justifyContent='center'
      >
        <Heading level={3} color='primary' weight={600}>
          {t('summer.sale.offer')}
        </Heading>

        <Column alignItems='start'>
          <Badge
            variant='warning-filled'
            weight={600}
            className={styles['sale-promo-banner__badge']}
          >
            {t('annual.save.price', { discount, currency })}
          </Badge>

          <Paragraph size={12} color='primary'>
            {t('annual.for.first.payment')}
          </Paragraph>
        </Column>
      </Column>
    </Row>
  )
}
