import { useUserContext } from '@contexts/user'
import { wait } from '@helpers/promise'
import ImportLandingPage from '@images/landings/Import_landing-page.png'
import { Tooltip } from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import { importLandingFromFile } from '@services/landings/list/importLandingFromFile'
import { emitTimingToast } from '@ui-kit'
import { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ChooseMethodBoxOutline } from './CreateLandingBoxOutline'

export const ImportFromFileBoxOutline = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { hasFreeTrial } = useUserContext()

  const inputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsFileLoading] = useState(false)

  const handleImportFileClick = () => inputRef?.current?.click()

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.currentTarget.files?.[0]
      const formData = new FormData()

      if (!file) {
        return
      }

      setIsFileLoading(true)

      formData.append('landingPageFile', file)
      formData.append('fileType', 'application/zip')

      await importLandingFromFile(formData)

      emitTimingToast({
        type: 'success',
        message: t('landing.pages.import.success')
      })

      // added delay to allow the read model to be updated
      await wait(1000)

      navigate(LANDINGS.LANDINGS)
    } catch {
      emitTimingToast({
        type: 'error',
        message: t('landing.pages.import.alert')
      })
    } finally {
      setIsFileLoading(false)
    }
  }

  return (
    <Tooltip
      content={t('landings.import.from.figma.mock.cant.upload')}
      disabled={!hasFreeTrial}
    >
      <ChooseMethodBoxOutline
        imageSrc={ImportLandingPage}
        heading={t('landings.import.from.figma.mock.import.lp')}
        paragraph={t('landings.import.from.figma.mock.import.lp.paragraph')}
        onClick={handleImportFileClick}
        isLoading={isLoading}
        disabled={hasFreeTrial}
      />

      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type='file'
        onChange={handleFileChange}
        accept='.landingpage'
      />
    </Tooltip>
  )
}
