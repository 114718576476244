import BlackFridayMate from '@images/dealmate/BlackFridayMateHead.svg'
import GABadgeCategoryLeaders from '@images/registration/GA-Badge-CategoryLeaders.svg'
import HighPerformerMidMarketReward from '@images/registration/HighPerformerMidMarketReward.png'
import HighPerformerReward from '@images/registration/HighPerformerReward.png'
import LeadCaptureSmallBusinessReward from '@images/registration/LeadCaptureSmallBusinessReward.png'
import SABadgeFrontrunners from '@images/registration/SA-Badge-FrontRunners-2023.png'
import {
  Badge,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { motion } from 'framer-motion'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './RefreshedColumnContent.module.scss'

const BlackFriday = () => {
  const { secondColumnControls } = useAnimationContext()

  const { t } = useTranslation()

  return (
    <div className={styles['black-friday-column-content']}>
      <motion.div
        className={styles['column-content']}
        animate={secondColumnControls}
      >
        <Row>
          <Image src={BlackFridayMate} />
          <Spreader spread='tiny' />
          <Heading level={1} color='white' bold margin='none'>
            <Trans i18nKey='black.friday.sale.started' />
          </Heading>
        </Row>

        <Spacer space='tiny' />

        <Heading level={2} color='white' margin='none'>
          <Trans i18nKey='black.friday.sale.limited.offer' />
        </Heading>

        <Spacer space='tiny' />

        <Row>
          <Badge type='warning'>
            <Paragraph color='white' size={14} padding='none' weight={700}>
              {t('annual.save.up.to')}{' '}
            </Paragraph>
          </Badge>
          <Paragraph color='white' size={14}>
            {t('annual.sale.for.first.payment')}
          </Paragraph>
        </Row>

        <Paragraph color='white' padding='none' size={14}>
          <Trans i18nKey='annual.get.event.tracker.free' />
        </Paragraph>

        <Spacer />

        <Paragraph color='white' padding='none' weight={700} size={16}>
          {t('black.friday.by.register.you.get.more')}
        </Paragraph>

        <List>
          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='white'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={14} line={24} padding='none' color='white'>
                <Trans i18nKey='registration.flow.refreshed.2nd.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='white'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={14} line={24} padding='none' color='white'>
                <Trans i18nKey='registration.flow.refreshed.3rd.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='white'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={14} line={24} padding='none' color='white'>
                <Trans i18nKey='registration.flow.refreshed.4th.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>
        </List>

        <Spacer space='mini' />

        <Spacer space='tiny' />

        <Paragraph color='white' padding='none' size={14}>
          {t('annual.by.register.we.are.waiting')}
        </Paragraph>

        <Spacer space='small' />

        <div className={styles['column-content__logos']}>
          <Image src={HighPerformerReward} size='54px' />
          <Image src={HighPerformerMidMarketReward} size='54px' />
          <Image src={LeadCaptureSmallBusinessReward} size='54px' />
          <Image src={GABadgeCategoryLeaders} size='54px' />
          <Image src={SABadgeFrontrunners} size='54px' />
        </div>
      </motion.div>
    </div>
  )
}

BlackFriday.displayName = 'BlackFriday'

export default memo(BlackFriday)
