import { WorkspaceButton } from '@components/Sidebar/components/WorkspaceButton'
import { NEW_LANDINGI_URL } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useChangeSubaccountToMain } from '@hooks/useChangeSubaccountToMain'
import searchEmpty from '@images/search_empty.svg'
import { Divider, Loader, Message, Search } from '@landingi/landingi-ui-kit'
import { useSubaccounts } from '@services/subaccounts'
import { Button } from '@ui-kit'
import { ChangeEvent, Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './WorkspacesContent.module.scss'

export const WorkspacesContent = () => {
  const { t } = useTranslation()

  const [searchPhrase, setSearchPhrase] = useState('')

  const { data: accountsList, isLoading } = useSubaccounts()

  const { isTypeOwner, isTypeAdmin } = useUserContext()

  // TODO - Sidebar & Topbar iteration 2
  const hasAccessToActions = useFeatureFlags('SPA_DRAWER_ACTIONS')

  const hasAccessToLiteLimitedDevelopment = useFeatureFlags(
    'LITE_LIMITED_DEVELOPMENT'
  )

  const {
    changeToMainAccountIfNeeded,
    isTypeManager,
    areSubaccountsLoading,
    isRequestChangeUserAccountLoading
  } = useChangeSubaccountToMain()

  const hasAccessToManageSubaccounts =
    (isTypeOwner || isTypeAdmin) && !isTypeManager

  const handleManageSubaccounts = async () => {
    await changeToMainAccountIfNeeded()

    window.open(`https://${NEW_LANDINGI_URL}/agency/accounts`, '_self')
  }

  const handleSearchAccounts = useCallback(
    (event?: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event?.target?.value

      const searchValue =
        inputValue && inputValue.length >= 3 ? inputValue.toLowerCase() : ''

      setSearchPhrase(searchValue)
    },
    []
  )

  const searchedAccountsList =
    accountsList?.filter(
      ({ name }) => name?.toLowerCase().includes(searchPhrase) ?? ''
    ) ?? []

  return (
    <Fragment>
      <div className={styles['workspaces-content__padding--middle']}>
        <Search
          i18n={{ placeholder: t('word.search') }}
          onChange={handleSearchAccounts}
        />
      </div>

      <Divider />

      <Column className={styles['workspaces-content__workspaces']}>
        {isLoading ? <Loader /> : null}

        {!searchedAccountsList.length && searchPhrase ? (
          <Message
            className={styles['workspaces-content__empty-message']}
            title={t('message.empty.search.results')}
            titleLevel={4}
            url={searchEmpty}
            height={90}
          />
        ) : null}

        {(searchPhrase ? searchedAccountsList : accountsList)?.map(
          ({ uuid, name, email, isActive, isMain, isDisabled, limitUuid }) => (
            <WorkspaceButton
              key={uuid}
              uuid={uuid}
              icon={isMain ? 'icon-home' : 'icon-building'}
              name={name || t('word.main-account')}
              limitUuid={limitUuid}
              email={email}
              isActive={isActive}
              isDisabled={isDisabled}
              settingsBtn={hasAccessToActions && !isMain}
            />
          )
        )}
      </Column>

      <Divider />

      <Row
        className={styles['workspaces-content__padding--bottom']}
        justifyContent='space-between'
      >
        {hasAccessToLiteLimitedDevelopment && hasAccessToManageSubaccounts && (
          <Button
            onClick={handleManageSubaccounts}
            disabled={isRequestChangeUserAccountLoading}
            isLoading={areSubaccountsLoading}
          >
            {t('manage.subaccounts')}
          </Button>
        )}

        {hasAccessToActions && <Button>{t('subaccount.create.new')}</Button>}
      </Row>
    </Fragment>
  )
}

WorkspacesContent.displayName = 'SidebarWorkspacesContent'
