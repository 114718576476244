import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { Button } from '@landingi/landingi-ui-kit'
import { PAYMENTS } from '@routes/path'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

interface WillExpireTodayProps {
  buttonContent: string
}

export const WillExpireToday: FC<WillExpireTodayProps> = ({
  buttonContent
}) => {
  const { t } = useTranslation()

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  return (
    <Row
      justifyContent='space-between'
      alignItems='center'
      style={{
        width: '100%'
      }}
    >
      <span className={styles.alert__content}>
        {t('flash.account-expiredat-today-message')}
      </span>

      {hasAccessToPayments && (
        <Button
          size='tiny'
          tag='a'
          buttonStyle
          href={`${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`}
        >
          {buttonContent}
        </Button>
      )}
    </Row>
  )
}

WillExpireToday.displayName = 'TopbarInfobarAccountExpiresAtWillExpireToday'
