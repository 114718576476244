import { ADDONS_IDS } from '@constants/addonsIds'
import { importLandingPagePrices } from '@constants/addonsPrices'
import ImportLandingPage from '@images/addons/icons/ImportLandingPage.svg'
import {
  ONE_OFF_PAYMENT,
  SERVICE
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { LandingPageImportSecondaryButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import {
  SERVICE as ADDON_TYPE_SERVICE,
  ServiceAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getOverview } from './Overview'
import { getServices } from './Services'
import { getUseCases } from './UseCases'

export const getImportLandingPageAddon: (
  t: TFunction<'translation', undefined>
) => ServiceAddonTemplate = t => ({
  type: ADDON_TYPE_SERVICE,
  name: t('addons.store.landing.page.import'),
  title: t('addons.store.landing.page.import'),
  tileDescription: (
    <Trans i18nKey='addons.store.landing.page.import.description' />
  ),
  pageDescription: t('addon.store.landing.page.import.page.description'),
  badges: [SERVICE, ONE_OFF_PAYMENT],
  imageSrc: ImportLandingPage,
  id: ADDONS_IDS.LANDING_PAGE_IMPORT,
  overview: getOverview(t),
  services: getServices(),
  useCases: getUseCases(t),
  price: importLandingPagePrices,
  faq: getFAQ(t),
  secondaryButton: LandingPageImportSecondaryButton
})
