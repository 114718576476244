import { GTMProvider } from '@boot/gtm'
import { LanguageProvider } from '@boot/i18n'
import { Authenticated } from '@components/Authenticated'
import { AclContextProvider } from '@contexts/acl'
import { FeatureFlagsContextProvider } from '@contexts/featureFlags/FeatureFlagsContext'
import { UserContextProvider } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { Loader, TimingToast } from '@landingi/landingi-ui-kit'
import { routes as addons } from '@pages/AddonsStore/routes'
import { routes as authentication } from '@pages/Authentication/routes'
import { routes as landings } from '@pages/Landings/routes'
import { routes as notFound } from '@pages/NotFound/routes'
import { routes as pricing } from '@pages/Pricing/routes'
import { routes as programmatic } from '@pages/Programmatic/routes'
import { wrapUseRoutes } from '@sentry/react'
import { ToastContainer } from '@ui-kit/Toast'
import { AnimatePresence } from 'framer-motion'
import { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'

export const Root = () => {
  const useSentryRoutes = wrapUseRoutes(useRoutes)

  const authPages = [...landings, ...pricing, ...addons, ...programmatic].map(
    ({ element, ...rest }) => ({
      element: <Authenticated>{element}</Authenticated>,
      ...rest
    })
  )

  const unauthPages = [...authentication, notFound]

  const pages = useSentryRoutes([...unauthPages, ...authPages])

  return (
    <Suspense fallback={<Loader />}>
      <UserContextProvider>
        <FeatureFlagsContextProvider>
          <AclContextProvider>
            <LanguageProvider>
              <GTMProvider>
                <AnimatePresence mode='wait'>
                  <NiceModal.Provider>{pages}</NiceModal.Provider>
                </AnimatePresence>
              </GTMProvider>
            </LanguageProvider>
          </AclContextProvider>
        </FeatureFlagsContextProvider>
      </UserContextProvider>

      <ToastContainer />
      <TimingToast />
    </Suspense>
  )
}

export default Root
