import { ExpandType } from '@components/Sidebar/types'
import {
  LANDINGS,
  LEADS,
  LIGHTBOXES,
  ORDERS,
  PRODUCTS,
  SMART_SECTIONS
} from '@routes/path'

export const landingsActiveGroup = [
  LANDINGS.LANDINGS,
  LANDINGS.WELCOME,
  SMART_SECTIONS.SMART_SECTIONS,
  LIGHTBOXES.LIGHTBOXES,
  PRODUCTS.PRODUCTS
] as ExpandType[]

export const conversionsActiveGroup = [
  LEADS.LEADS,
  ORDERS.ORDERS
] as ExpandType[]

export const TABS = {
  DASHBOARD: 'DASHBOARD',
  PRODUCTS: 'PRODUCTS',
  LEADS: 'LEADS',
  ORDERS: 'ORDERS',
  // TODO Remove after the release of the second Lite Limited integration
  FONTS: 'FONTS',
  LIMIT_PACKAGES: 'LIMIT_PACKAGES',
  TEMPLATES: 'TEMPLATES',
  INTEGRATIONS: 'INTEGRATIONS',
  ADD_ONS: 'ADD_ONS',
  PROGRAMMATIC: 'PROGRAMMATIC'
} as const

export type TabTypes = keyof typeof TABS
