/* eslint-disable consistent-return */
import { emitTimingToast } from '@ui-kit/Toast'
import { AxiosResponse } from 'axios'
import { useState } from 'react'

type Options<RequestData, ResponseData> = {
  successToastText?: string
  errorToastText?: string
  onSuccess?: (res: ResponseData, req: RequestData) => void
  onError?: (error: unknown, req: RequestData) => void
  onFinally?: (res: ResponseData, req: RequestData) => void
  forwardError?: boolean
}

type ReturnHookData<RequestData, ResponseData> = RequestData extends undefined
  ? [
      () => Promise<ResponseData | undefined>,
      {
        isLoading: boolean
        data: ResponseData | null
        error: unknown
      }
    ]
  : [
      (requestData: RequestData) => Promise<ResponseData | undefined>,
      {
        isLoading: boolean
        data: ResponseData | null
        error: unknown
      }
    ]

export const useLazyService = <
  RequestData = undefined,
  ResponseData = undefined
>(
  service: (
    requestData: RequestData
  ) => Promise<AxiosResponse<ResponseData, any>>,
  options: Options<RequestData, ResponseData> = {}
): ReturnHookData<RequestData, ResponseData> => {
  const [state, setState] = useState<{
    isLoading: boolean
    data: ResponseData | null
    error: unknown
  }>({ isLoading: false, data: null, error: null })

  const trigger = async (requestData: RequestData) => {
    setState({ isLoading: true, data: null, error: null })

    const {
      successToastText,
      errorToastText,
      onSuccess,
      onError,
      onFinally,
      forwardError
    } = options

    try {
      const data = await service(requestData)

      setState({ isLoading: false, data: data.data, error: null })

      if (successToastText) {
        emitTimingToast({ message: successToastText, type: 'success' })
      }

      if (onSuccess) {
        onSuccess(data as ResponseData, requestData)
      }

      return data.data
    } catch (error) {
      setState({ isLoading: false, data: null, error })

      if (errorToastText) {
        emitTimingToast({ message: errorToastText, type: 'error' })
      }

      if (onError) {
        onError(error, requestData)
      }

      if (forwardError) {
        throw new Error(error as string)
      }
    } finally {
      if (onFinally) {
        onFinally(state.data as ResponseData, requestData)
      }
    }
  }

  return [trigger, state] as ReturnHookData<RequestData, ResponseData>
}
