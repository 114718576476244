import NiceModal from '@ebay/nice-modal-react'
import { isEmpty } from '@helpers/data'
import {
  Heading,
  Paragraph,
  Separator,
  Spacer
} from '@landingi/landingi-ui-kit'
import { MODAL_PRIVACY_POLICY } from '@pages/Authentication/components/PrivacyPolicy'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import getRegistrationButton from '@pages/Authentication/helpers/getRegistrationButton'
import { motion } from 'framer-motion'
import { Fragment, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import styles from './Register.module.scss'

const Register = () => {
  const { formControls } = useAnimationContext()
  const { startFromFirstStep } = useStepsContext()

  const {
    registrationForm,
    lang,
    chosenPackage,
    variantName,
    trialPeriod,
    source,
    period,
    registrationMethods,
    heading,
    subtitle,
    pricing,
    newSignup,
    isDefaultRegistration
  } = useRegisterContext()

  const RegistrationForm = registrationForm

  const { t } = useTranslation()

  const handlePrivacyPolicyModalToggle = useCallback(event => {
    event.preventDefault()

    NiceModal.show(MODAL_PRIVACY_POLICY, { closeOnOutsideClick: true })
  }, [])

  return (
    <motion.div animate={formControls} className={styles['register-cta']}>
      <Heading level={1} bold>
        {t(heading, {
          days: trialPeriod
        })}
      </Heading>

      {subtitle && (
        <Paragraph line={24} size={16}>
          {t(subtitle)}
        </Paragraph>
      )}

      {subtitle && !isEmpty(registrationMethods) && <Spacer />}

      {!isEmpty(registrationMethods) && (
        <Fragment>
          {registrationMethods.map(method => (
            <Fragment>
              {getRegistrationButton(method)}
              <Spacer space='tiny' />
            </Fragment>
          ))}

          <Separator>
            <Paragraph color='accent-6' size={12} weight={700} padding='none'>
              {t('word.or')}
            </Paragraph>
          </Separator>
        </Fragment>
      )}

      <Spacer space='medium' />

      <RegistrationForm
        lang={lang}
        chosenPackage={chosenPackage}
        variantName={variantName}
        source={source}
        period={period}
        pricing={pricing}
        newSignup={newSignup}
        startFromFirstStep={startFromFirstStep}
        isDefaultRegistration={isDefaultRegistration}
      />

      <Spacer space='small' />

      <Paragraph size={12} color='accent-2'>
        <Trans
          i18nKey='registration.flow.privacy.policy.short'
          components={{
            privacyPolicy: (
              <a
                href={t('link.landingi.privacy.policy')}
                target='_blank'
                rel='noreferrer'
              />
            ),
            terms: (
              <a
                href={t('link.landingi.terms.and.conditions')}
                target='_blank'
                rel='noreferrer'
              />
            ),
            privacyPolicyModalLink: (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href='#'
                onClick={handlePrivacyPolicyModalToggle}
                data-testid='open-modal-button'
              />
            )
          }}
        />
      </Paragraph>
    </motion.div>
  )
}

Register.displayName = 'Register'

export default Register
