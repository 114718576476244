import { getWhatHappensAfterFreeTrialQuestion } from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import { Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getWhatHappensAfterFreeTrialQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.2')}
      </Row>
    ),
    content: <Paragraph size={14}>{t('addons.store.faq.answer.2')}</Paragraph>
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.programmatic.lp.title.3')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.faq.question.programmatic.lp.answer.3')}
      </Paragraph>
    )
  }
]
