import {
  Heading,
  MultiSelect,
  Paragraph,
  ProgressBar,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import FooterButtons from '@pages/Authentication/routes/Survey/Form/questions/FooterButtons'
import styles from '@pages/Authentication/routes/Survey/Form/questions/Questions.module.scss'
import { LayoutGroup, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import apps from './constants/apps'
import EmptySearchResultsComponent from './EmptySearchResultsComponent'

const Fifth = ({
  isValid,
  isSubmitting,
  handleSkipStep,
  setFieldValue,
  validateForm,
  isLastQuestion,
  values
}) => {
  const {
    activeSurveyBoxesIDs,
    setActiveSurveyBoxesIDs,
    questionsSkippedInfo,
    setQuestionsSkippedInfo
  } = useSurveyContext()

  const { t } = useTranslation()

  const handleChange = (name, value) => {
    if (
      !activeSurveyBoxesIDs.includes('instructions-for-marketing-stock') &&
      value.length > 0
    ) {
      setActiveSurveyBoxesIDs([
        ...activeSurveyBoxesIDs,
        'instructions-for-marketing-stock'
      ])
    }

    if (
      activeSurveyBoxesIDs.includes('instructions-for-marketing-stock') &&
      value.length === 0
    ) {
      setActiveSurveyBoxesIDs(
        activeSurveyBoxesIDs.filter(
          id => id !== 'instructions-for-marketing-stock'
        )
      )
    }

    setFieldValue(name, value)
  }

  useEffect(() => {
    validateForm()
  }, [])

  /**
   * handles clicking Next button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleNext = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    newQuestionsSkippedInfo.fifth = false
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
  }

  /**
   * handles clicking Skip step button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleSkipStepWrapper = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    setActiveSurveyBoxesIDs(
      activeSurveyBoxesIDs.filter(
        id => id !== 'instructions-for-marketing-stock'
      )
    )

    setFieldValue('marketing_stock', [])

    newQuestionsSkippedInfo.fifth = true
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
    handleSkipStep()
  }

  return (
    <LayoutGroup>
      <motion.div
        layout='position'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Column>
          <motion.div layout>
            <Column>
              <Heading level={1} margin='none'>
                {t('registration.flow.survey.how.what.apps.do.you.use')}
              </Heading>

              <Spacer space='tiny' />

              <Paragraph size={16} padding='none'>
                {t('registration.flow.survey.we.will.show.you.how.to.connect')}
              </Paragraph>

              <Spacer space='small' />
            </Column>
          </motion.div>

          <MultiSelect
            initialOptions={apps}
            formikKey='marketing_stock'
            onChange={handleChange}
            searcher={{
              i18n: {
                placeholder: t('registration.flow.surve.app.name')
              }
            }}
            emptySearchResultsComponent={EmptySearchResultsComponent}
            values={values.marketing_stock}
          />

          <Spacer space='tiny' />

          <FooterButtons
            isLastQuestion={isLastQuestion}
            isSubmitting={isSubmitting}
            handleSkipStep={handleSkipStepWrapper}
            handleNext={handleNext}
            isValid={isValid}
            leftSideContent={
              <Column>
                <Paragraph>
                  {t('registration.flow.survey.select.five.apps')}
                </Paragraph>
                <div className={styles['footer__progress-bar']}>
                  <ProgressBar
                    limit={5}
                    quantity={values?.marketing_stock.length}
                    variant='brand'
                    size='small'
                  />
                </div>
              </Column>
            }
          />
        </Column>
      </motion.div>
    </LayoutGroup>
  )
}

Fifth.propTypes = {
  dirty: PropTypes.bool,
  values: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func,
  touched: PropTypes.instanceOf(Object),
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleSkipStep: PropTypes.func,
  validateForm: PropTypes.func,
  validateField: PropTypes.func,
  isLastQuestion: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default Fifth
