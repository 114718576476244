import { BillingPeriodType } from '@constants/billingPeriods'
import { STATUS, SubscriptionStatusType } from '@constants/subscription'

const { ACTIVE, PAST_DUE, PENDING, CANCELED } = STATUS

interface GetVariantNameParams {
  subscriptionStatus?: SubscriptionStatusType
  hasDiscount?: boolean
  usedDiscount?: boolean
  hasFreeTrial?: boolean
  billingPeriod?: BillingPeriodType
  hasFreePlanRestrictions?: boolean
  planRecordKey?: string
}

export const getVariantName = ({
  subscriptionStatus,
  hasDiscount,
  usedDiscount,
  hasFreeTrial,
  billingPeriod,
  hasFreePlanRestrictions,
  planRecordKey
}: GetVariantNameParams) => {
  let consideredSubscriptionStatues: (SubscriptionStatusType | undefined)[]

  if (planRecordKey === 'lite_22' && hasDiscount === false) {
    return 'see-details'
  }

  if (hasFreePlanRestrictions && hasDiscount === false) {
    return 'upgrade-plan'
  }

  if (
    hasFreeTrial &&
    subscriptionStatus === PENDING &&
    billingPeriod === 12 &&
    hasDiscount === true &&
    usedDiscount === false
  ) {
    return 'discount-applied'
  }

  consideredSubscriptionStatues = [ACTIVE, PAST_DUE]

  if (
    !hasFreeTrial &&
    consideredSubscriptionStatues.includes(subscriptionStatus) &&
    billingPeriod === 12 &&
    hasDiscount === true &&
    usedDiscount === false
  ) {
    return 'discount-applied'
  }

  if (
    hasFreeTrial &&
    subscriptionStatus === PENDING &&
    billingPeriod === 12 &&
    hasDiscount === false
  ) {
    return 'see-details'
  }

  consideredSubscriptionStatues = [null, CANCELED]

  if (
    hasFreeTrial &&
    consideredSubscriptionStatues.includes(subscriptionStatus) &&
    hasDiscount === false
  ) {
    return 'see-details'
  }

  if (
    !hasFreeTrial &&
    consideredSubscriptionStatues.includes(subscriptionStatus) &&
    hasDiscount === false
  ) {
    return 'see-details'
  }

  consideredSubscriptionStatues = [ACTIVE, PAST_DUE]

  if (
    !hasFreeTrial &&
    consideredSubscriptionStatues.includes(subscriptionStatus) &&
    hasDiscount === false &&
    billingPeriod === 12
  ) {
    return 'see-details'
  }

  if (
    hasFreeTrial &&
    subscriptionStatus === PENDING &&
    billingPeriod === 1 &&
    (hasDiscount === false || usedDiscount === false)
  ) {
    return 'upgrade-plan'
  }

  consideredSubscriptionStatues = [ACTIVE, PAST_DUE]

  if (
    !hasFreeTrial &&
    consideredSubscriptionStatues.includes(subscriptionStatus) &&
    billingPeriod === 1 &&
    (hasDiscount === false || usedDiscount === false)
  ) {
    return 'upgrade-plan'
  }

  consideredSubscriptionStatues = [null, CANCELED]

  if (
    consideredSubscriptionStatues.includes(subscriptionStatus) &&
    hasDiscount === true &&
    usedDiscount === false
  ) {
    return 'pay-now'
  }

  if (
    !hasFreeTrial &&
    subscriptionStatus === PENDING &&
    billingPeriod === 12 &&
    hasDiscount === false
  ) {
    return 'see-details'
  }

  if (
    !hasFreeTrial &&
    subscriptionStatus === PENDING &&
    billingPeriod === 12 &&
    hasDiscount === true &&
    usedDiscount === false
  ) {
    return 'discount-applied'
  }

  if (
    !hasFreeTrial &&
    subscriptionStatus === PENDING &&
    billingPeriod === 1 &&
    (hasDiscount === false || (hasDiscount === true && usedDiscount === false))
  ) {
    return 'upgrade-plan'
  }

  return 'see-details'
}
