import SelectBoxRadio from '@components/SelectBoxRadio'
import logoImg from '@images/registration/survey/questionIcons/logo.svg'
import noGestureImg from '@images/registration/survey/questionIcons/noGesture.png'
import paintImg from '@images/registration/survey/questionIcons/paint.png'
import workerImg from '@images/registration/survey/questionIcons/worker.png'
import { Heading, Spacer } from '@landingi/landingi-ui-kit'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import { LayoutGroup, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import FooterButtons from './FooterButtons'
import styles from './Questions.module.scss'

/**
 * First - stateful presentational component representing survey question
 * @param {object} values - formik values
 * @param {bool} isValid - formik isValid
 * @param {bool} isSubmitting - formik isSubmitting
 * @param {function} handleSkipStep - function that handles clicking skip button
 * @param {function} validateForm - formik validateForm
 * @param {bool} isLastQuestion - information whether question is last or not
 * @param {function} onBlur - callback called on blur input
 * @param {function} setFieldValue - set formik field
 * @return {object} - children
 */
const First = ({
  values,
  isValid,
  isSubmitting,
  handleSkipStep,
  validateForm,
  setFieldValue,
  isLastQuestion,
  onBlur
}) => {
  const {
    activeSurveyBoxesIDs,
    setActiveSurveyBoxesIDs,
    questionsSkippedInfo,
    setQuestionsSkippedInfo
  } = useSurveyContext()

  const { t } = useTranslation()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  /**
   * handles clicking Next button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleNext = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    newQuestionsSkippedInfo.first = false
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
  }

  /**
   * removes landingPageTips from array of active survey boxes
   * @type {function}
   */
  const removeLandingPageTipsActiveSurveyBox = () =>
    setActiveSurveyBoxesIDs(
      activeSurveyBoxesIDs.filter(id => id !== 'landingPageTips')
    )

  /**
   * handles clicking Skip step button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleSkipStepWrapper = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    newQuestionsSkippedInfo.first = true

    setFieldValue('experience_level', '')
    setFieldValue('where', '')
    removeLandingPageTipsActiveSurveyBox()

    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
    handleSkipStep()
  }

  /**
   * adds landingPageTips to array of active survey boxes
   * @type {function}
   */
  const setLandingPageTipsActiveSurveyBoxes = () =>
    setActiveSurveyBoxesIDs([...activeSurveyBoxesIDs, 'landingPageTips'])

  return (
    <LayoutGroup>
      <motion.div
        layout='position'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Column>
          <motion.div layout='position'>
            <Heading level={1}>
              {t('registration.flow.survey.have.you.worked.with.landing.pages')}
            </Heading>
          </motion.div>
          <div>
            <div className={styles['first-question-boxes']}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
                className={styles['first-question-boxes__agency-dev']}
                layout='position'
              >
                <SelectBoxRadio
                  field={{
                    name: 'experience_level',
                    value: values.experience_level,
                    onBlur
                  }}
                  form={{ errors: {}, touched: {}, setFieldValue }}
                  id='own-solution'
                  variant='responsive'
                  i18n={{
                    title: t(
                      'registration.flow.survey.with.agency.or.developer'
                    )
                  }}
                  iconSource={workerImg}
                  onChange={removeLandingPageTipsActiveSurveyBox}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
                className={styles['first-question-boxes__another']}
                layout='position'
              >
                <SelectBoxRadio
                  field={{
                    name: 'experience_level',
                    value: values.experience_level,
                    onBlur
                  }}
                  form={{ errors: {}, touched: {}, setFieldValue }}
                  id='competitor'
                  variant='responsive'
                  i18n={{
                    title: t('registration.flow.survey.another.builder')
                  }}
                  iconSource={paintImg}
                  onChange={removeLandingPageTipsActiveSurveyBox}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
                className={styles['first-question-boxes__landingi']}
                layout='position'
              >
                <SelectBoxRadio
                  field={{
                    name: 'experience_level',
                    value: values.experience_level,
                    onBlur
                  }}
                  form={{ errors: {}, touched: {}, setFieldValue }}
                  id='landingi'
                  variant='responsive'
                  i18n={{
                    title: t('registration.flow.survey.used.landingi.before')
                  }}
                  iconSource={logoImg}
                  onChange={removeLandingPageTipsActiveSurveyBox}
                />
              </motion.div>
              <SelectBoxRadio
                field={{
                  name: 'experience_level',
                  value: values.experience_level,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='beginner'
                className={styles['first-question-boxes__no']}
                variant='responsive'
                i18n={{ title: t('registration.flow.survey.no') }}
                iconSource={noGestureImg}
                onChange={setLandingPageTipsActiveSurveyBoxes}
              />
            </div>
          </div>

          <Spacer space='medium' />

          <Spacer space='mini' />

          <FooterButtons
            isLastQuestion={isLastQuestion}
            isSubmitting={isSubmitting}
            handleSkipStep={handleSkipStepWrapper}
            handleNext={handleNext}
            isValid={isValid}
          />
        </Column>
      </motion.div>
    </LayoutGroup>
  )
}

First.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSkipStep: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired
}

export default First
