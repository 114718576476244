import { Price } from '@components/Credits/Price'
import { MODAL_BUY_CREDITS } from '@components/Modals/ModalBuyCredits'
import { CLICK_SELECT_PACKAGE } from '@constants/mixpanel'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { formatNumeric } from '@helpers/data'
import BgRemoverImage from '@images/credits/bg-remover.svg'
import ContentGenerarionImage from '@images/credits/content-generation.svg'
import SeoGenerarionImage from '@images/credits/seo-generation.svg'
import TranslationsImage from '@images/credits/translations.svg'
import { Loader, Modal } from '@landingi/landingi-ui-kit'
import { useGetCredits } from '@services/credits/useGetCredits'
import { useGetCreditsPackage } from '@services/credits/useGetCreditsPackage'
import { mixpanelEvent } from '@services/mixpanel'
import { Image, Paragraph } from '@ui-kit'
import { Button, ButtonLink } from '@ui-kit/Button'
import { Heading } from '@ui-kit/Heading'
import { Icon } from '@ui-kit/Icon'
import { Panel } from '@ui-kit/Panel'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './ModalAdvertiseCredits.module.scss'

interface ModalAdvertiseCreditsProps {
  properties: {
    click_location: string
    type: string
  }
}

export const ModalAdvertiseCredits =
  NiceModal.create<ModalAdvertiseCreditsProps>(({ properties }) => {
    const { t } = useTranslation()
    const { remove } = useModal()

    const creditsPackage = useGetCreditsPackage()
    const { credits, isLoading } = useGetCredits()

    const handleOpenBuyCreditsModal = () => {
      mixpanelEvent({
        name: CLICK_SELECT_PACKAGE,
        properties
      })

      NiceModal.show(MODAL_BUY_CREDITS, {
        properties
      })

      remove()
    }

    return (
      <Modal
        isActive
        isClosable
        onClick={remove}
        customZIndex={{
          backdrop: 250999,
          dialog: 251000
        }}
        i18n={{
          title: t('modal.buy.credits.advertise.heading')
        }}
        className={styles.modal}
      >
        <Row justifyContent='space-between'>
          <Column
            style={{
              maxWidth: 300
            }}
          >
            <Heading weight={600} level={3} data-testid='credits-amount'>
              <Icon icon='icon-coin' />

              <Spreader spread={5} />

              <Trans
                i18nKey='modals.credits.packages.credits.left'
                components={{
                  b: <b />
                }}
                values={{ credits: formatNumeric(credits ?? 0) }}
              />
            </Heading>

            <Spacer space={25} />

            <Paragraph size={12}>
              {t('modal.buy.credits.advertise.desc')}
            </Paragraph>

            <Spacer space={10} />

            <Row alignItems='center'>
              <Image src={ContentGenerarionImage} />
              <Spreader spread={10} />
              <Paragraph weight={600} size={12}>
                {t('modal.buy.credits.advertise.content')}
              </Paragraph>
            </Row>

            <Spacer space={5} />

            <Row alignItems='center'>
              <Image src={SeoGenerarionImage} />
              <Spreader spread={10} />
              <Paragraph weight={600} size={12}>
                {t('modal.buy.credits.advertise.seo')}
              </Paragraph>
            </Row>

            <Spacer space={5} />

            <Row alignItems='center'>
              <Image src={BgRemoverImage} />
              <Spreader spread={10} />
              <Paragraph weight={600} size={12}>
                {t('modal.buy.credits.advertise.image')}
              </Paragraph>
            </Row>

            <Spacer space={5} />

            <Row alignItems='center'>
              <Image src={TranslationsImage} />
              <Spreader spread={10} />
              <Paragraph weight={600} size={12}>
                {t('modal.buy.credits.advertise.translation')}
              </Paragraph>
            </Row>
          </Column>

          {creditsPackage && !isLoading ? (
            <Column className={styles['modal__price-container']}>
              <Panel border='neutral-2' surface={0}>
                <Heading level={3} weight={600}>
                  {t('modals.credits.packages.credits', {
                    credits: formatNumeric(creditsPackage.amount)
                  })}
                </Heading>

                <Spacer space={15} />

                <Price price={creditsPackage.price} />

                <Spacer space={50} />

                <Button
                  onClick={handleOpenBuyCreditsModal}
                  data-testid='button-buy-now'
                >
                  {t('change.plan.page.buy.now')}
                </Button>
              </Panel>
            </Column>
          ) : (
            <Column
              className={styles['modal__price-container']}
              justifyContent='center'
              alignItems='center'
            >
              <Loader data-testid='loader' />
            </Column>
          )}
        </Row>

        <Spacer space={20} />

        <Row justifyContent='end'>
          <ButtonLink
            variant='text-neutral'
            icon='icon-external-link-alt'
            size={12}
            href={t('modals.credits.packages.what.are.credits.url')}
            target='_blank'
          >
            {t('modals.credits.packages.what.are.credits')}
          </ButtonLink>
        </Row>
      </Modal>
    )
  })
