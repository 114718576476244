import { NEW_LANDINGI_URL } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import {
  getHas12MonthSaleDiscount,
  getIsPromotion12MonthUsed
} from '@helpers/payment'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useStyles } from '@hooks/useStyles'
import { useSubscriptionInfoParams } from '@hooks/useSubscriptionInfoParams'
import SummerSaleMateBackground from '@images/dealmate/summer-sale-topbar-banner-background.svg'
import SummerSaleMate from '@images/dealmate/summer-sale-topbar-banner-deal-mate.svg'
import { CustomLink } from '@landingi/landingi-ui-kit'
import { Image, Paragraph } from '@ui-kit'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import { getVariantName } from './bannerVariant'
import styles from './ChristmasSale.module.scss'

export const SummerSalePromoBanner = () => {
  const { t } = useTranslation()
  const {
    billingPeriod,
    subscriptionStatus,
    hasFreeTrial,
    isTypeClient,
    discounts,
    isLoading,
    planRecordKey,
    expiresAt
  } = useUserContext()

  const hasDiscount = getHas12MonthSaleDiscount(discounts)
  const usedDiscount = getIsPromotion12MonthUsed(discounts)

  const hasFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')
  const hasAccessToPromoBanner = useFeatureFlags('PROMO_BANNER')

  const params = useSubscriptionInfoParams(hasDiscount, 'app-top-bar')

  const variantName = getVariantName({
    subscriptionStatus,
    hasFreeTrial,
    hasFreePlanRestrictions,
    billingPeriod,
    hasDiscount,
    usedDiscount,
    planRecordKey
  })

  // expiresAt?.formatted means that the user has completed registration and we have all fresh information from the /account/info endpoint

  const isVisible =
    useBreakpoint('breakpoint-biggerLaptop', 'min') &&
    hasAccessToPromoBanner &&
    !isTypeClient &&
    !usedDiscount &&
    !isLoading &&
    expiresAt?.formatted

  const boxContentStyles = useStyles({
    [styles.box__content]: true
  })

  const titles = {
    'discount-applied': t('summer.sale.offer.banner.title.discount.applied'),
    'see-details': t('summer.sale.offer.banner.title.see.details'),
    'upgrade-plan': t('summer.sale.offer.banner.title.upgrade.plan'),
    'pay-now': t('summer.sale.offer.banner.title.discount.available')
  }

  const contents = {
    'discount-applied': t('summer.sale.offer.banner.at.lower.price'),
    'see-details': null,
    'upgrade-plan': null,
    'pay-now': null
  }

  const links = {
    'discount-applied': null,
    'see-details': (
      <CustomLink
        label={<Trans i18nKey='summer.sale.offer.banner.content.no.badge' />}
        size={12}
        underlined
        color='white'
      />
    ),
    'upgrade-plan': (
      <CustomLink
        label={<Trans i18nKey='summer.sale.offer.banner.get.annual.discount' />}
        size={12}
        underlined
        color='white'
      />
    ),
    'pay-now': (
      <CustomLink
        label={
          <Trans i18nKey='summer.sale.offer.banner.activate.annual.discount' />
        }
        size={12}
        underlined
        color='white'
      />
    )
  }

  const title = titles[variantName]

  const content = contents[variantName]

  const link = links[variantName]

  const handleOpenSeeMore = () =>
    window.open(`${t('change.plan.page.summer.sale.url')}${params}`, '_blank')

  const handleOpenUpgradePlan = () =>
    window.open(`https://${NEW_LANDINGI_URL}/account/package`, '_self')

  const handleOpenPayNow = () =>
    window.open(`https://${NEW_LANDINGI_URL}/payments`, '_self')

  const CTAs = {
    'discount-applied': () => null,
    'see-details': handleOpenSeeMore,
    'upgrade-plan': handleOpenUpgradePlan,
    'pay-now': handleOpenPayNow
  }

  const boxStyles = useStyles({
    [styles.box]: true,
    [styles['box--pointer']]: [
      'see-details',
      'upgrade-plan',
      'pay-now'
    ].includes(variantName)
  })

  return isVisible ? (
    <div className={boxStyles} onClick={CTAs[variantName]}>
      <Row className={boxContentStyles} alignItems='center'>
        <Column>
          <Paragraph color='white' weight={600}>
            {title}
          </Paragraph>

          <Paragraph size={12} color='white'>
            {content} {link}
          </Paragraph>
        </Column>
      </Row>

      <Image src={SummerSaleMate} className={styles.dealmate} />

      <Image src={SummerSaleMateBackground} className={styles.background} />
    </div>
  ) : null
}

SummerSalePromoBanner.displayName = 'SummerSalePromoBanner'

export default SummerSalePromoBanner
