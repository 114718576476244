import { Heading, Icon, Spreader } from '@landingi/landingi-ui-kit'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <Row vertical='center'>
      <Icon icon='icon-wordpress-alt' size={32} />

      <Spreader spread='tiny' />

      <Spreader spread='mini' />

      <Heading level={1} margin='none'>
        {t('publishing.options.boxes.box.wordpress.name')}
      </Heading>
    </Row>
  )
}

Header.displayName = 'PublishingOptionsViewsWordpressHeader'
