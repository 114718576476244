import { useFeatureFlags } from '@contexts/featureFlags'
import { Alert, Spacer } from '@landingi/landingi-ui-kit'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Boxes } from './components/Boxes'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Table } from './components/Table'

export const Main = () => {
  const { t } = useTranslation()

  const {
    publishingOptions: { data },
    landingPageUrls: {
      data: { collection }
    }
  } = usePublishingOptionsContext()

  const { isLandingPagePublished, landingPagePublicationType } = data

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const freePlanRestrictionsFeatureFlag = useFeatureFlags(
    'FREE_PLAN_RESTRICTIONS'
  )

  const isFreePlanRestrictions =
    freePlanRestrictionsFeatureFlag && !hasAllowTestAutoDomainFeatureFlag

  const isTestDomain = landingPagePublicationType === TYPES.TEST
  const isAnyMainUrl = collection.some(item => item.isMain)
  const isAnyUrl = collection.length > 0

  const testDomainAlert =
    isTestDomain && isLandingPagePublished && !isFreePlanRestrictions

  const mainDomainAlert =
    !isAnyMainUrl &&
    isAnyUrl &&
    isLandingPagePublished &&
    isFreePlanRestrictions

  const shouldRenderTable = () => {
    if (isFreePlanRestrictions) {
      return isAnyUrl
    }

    return true
  }

  return (
    <Fragment>
      <Header />

      {shouldRenderTable() && <Table />}

      {testDomainAlert && (
        <Fragment>
          <Alert type='warning'>{t('publishing.options.alert.warning')}</Alert>

          <Spacer />
        </Fragment>
      )}

      {mainDomainAlert && (
        <Fragment>
          <Alert type='info'>
            <Trans
              i18nKey='publishing.options.alert.info'
              components={{
                b: <b />
              }}
            />
          </Alert>

          <Spacer />
        </Fragment>
      )}

      <Boxes />

      <Spacer />

      <Footer />
    </Fragment>
  )
}

Main.displayName = 'PublishingOptionsViewsMain'
