export const ACTIVE = 'active' as const
export const INACTIVE = 'inactive' as const

export const PUBLISHED = 'published' as const
export const UNPUBLISHED = 'unpublished' as const

export const POPUP_DISPLAY_RULES = {
  DEFAULT: 'default',
  ACTIVE: 'active',
  SUCCESS: 'success',
  ERROR: 'error'
} as const
