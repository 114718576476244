import { LongLogo } from '@components/Logo'
import { getLocalStorage } from '@helpers/storage'
import { Back, Paragraph, ProgressBar, Spacer } from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import { REGISTRATION } from '@routes/path'
import { motion } from 'framer-motion'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import styles from './HeaderElements.module.scss'

/**
 * StartTrialCTA - component that renders paragraph with start trial call to action
 * @return {object} children
 */
export const StartTrialCTA = () => {
  const { searchParams } = useRegisterContext()

  let registrationRoute

  if (getLocalStorage('entryRoute') === 'signup') {
    registrationRoute = REGISTRATION.SIGNUP.DEFAULT
  } else {
    registrationRoute = REGISTRATION.REGISTER.DEFAULT
  }

  return (
    <Fragment>
      <LongLogo />

      <Paragraph size={12} color='accent-2' align='right'>
        <Trans
          i18nKey='registration.flow.dont.have.account'
          components={{
            a: <Link to={`${registrationRoute}?${searchParams.toString()}`} />
          }}
        />
      </Paragraph>
    </Fragment>
  )
}

StartTrialCTA.displayName = 'Start trial CTA'

/**
 * LoginCTA - component that renders paragraph with login call to action
 * @return {object} children
 */
export const LoginCTA = () => {
  const { searchParams } = useRegisterContext()

  return (
    <Fragment>
      <LongLogo />

      <Paragraph size={12} color='accent-2'>
        <Trans
          i18nKey='registration.flow.already.have.account'
          components={{
            a: (
              <Link
                to={`${REGISTRATION.LOGIN.DEFAULT}?${searchParams.toString()}`}
              />
            )
          }}
        />
      </Paragraph>
    </Fragment>
  )
}

LoginCTA.displayName = 'Login CTA'

/**
 * StepsBar - component that renders paragraph with login call to action
 * @return {object} children
 */
export const StepsBar = () => {
  const { currentStep, maxSteps, previousStep } = useStepsContext()

  const { t } = useTranslation()

  return currentStep > 0 ? (
    <motion.div className={styles['steps-bar']}>
      {currentStep > 1 && (
        <Back className={styles.back} onClick={previousStep} />
      )}

      <LongLogo />

      <Column style={{ width: 120 }}>
        <ProgressBar
          variant='brand'
          quantity={currentStep}
          limit={maxSteps}
          size='small'
          limitText='word.active.subaccounts'
        />

        <Spacer space='tiny' />

        <Row justifyContent='end'>
          <Paragraph size={12} color='accent-2'>
            {t('registration.flow.survey.step')}
            {` ${currentStep}/${maxSteps}`}
          </Paragraph>
        </Row>
      </Column>
    </motion.div>
  ) : (
    <LongLogo />
  )
}

StepsBar.displayName = 'StepsBar'
