import { useHover } from '@hooks/useHover'
import { BoxOutline, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { FC, Fragment, ReactNode } from 'react'
import { Column } from 'simple-flexbox'

import styles from './Box.module.scss'

interface BoxProps {
  icon: ReactNode
  name: string
  description: string
  disabled?: boolean
  onClick?: () => void
}

export const Box: FC<BoxProps> = ({
  icon,
  name,
  description,
  disabled,
  onClick
}) => {
  const paragraphColor = disabled ? 'accent-6' : 'accent-1'

  const [hoverProps, isHovered] = useHover()

  return (
    <BoxOutline
      variant='background'
      padding='none'
      onClickHandler={disabled ? undefined : onClick}
      disableHover={disabled}
      disabled={disabled}
      className={styles.wrapper}
      {...(disabled ? {} : hoverProps)}
    >
      <Column
        className={styles.box}
        justifyContent='center'
        alignItems='center'
      >
        {isHovered ? (
          <Paragraph align='center' padding='none' weight={400}>
            {description}
          </Paragraph>
        ) : (
          <Fragment>
            {icon}

            <Spacer space='tiny' />

            <Paragraph
              align='center'
              color={paragraphColor}
              padding='none'
              weight={400}
            >
              {name}
            </Paragraph>
          </Fragment>
        )}
      </Column>
    </BoxOutline>
  )
}

Box.displayName = 'PublishingOptionsViewsMainBoxesBox'
