import { Image, Spreader } from '@landingi/landingi-ui-kit'
import { domainStatus } from '@pages/Landings/routes/LandingsPublishingOptions/helpers/domains'
import { FC } from 'react'
import { Column, Row } from 'simple-flexbox'

interface DomainLabelProps {
  label: string
  status: string
}

export const DomainLabel: FC<DomainLabelProps> = ({ label, status }) => {
  const imageSource = domainStatus(status)

  return (
    <Row>
      <Column horizontal='center' vertical='center'>
        {imageSource ? (
          <Image src={imageSource} size='16px' height={16} />
        ) : null}
      </Column>

      <Spreader spread='tiny' />

      {label}
    </Row>
  )
}

DomainLabel.displayName = 'PublishingOptionsViewsChangeUrlDomainLabel'
