import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { Button, Icon, Spreader, Tooltip } from '@landingi/landingi-ui-kit'
import { PAYMENTS } from '@routes/path'
import { FC, Fragment, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

interface PaymentErrorProps {
  paymentError?: string
  planPackageUuid?: string
}

export const PaymentError: FC<PaymentErrorProps> = ({
  paymentError = '',
  planPackageUuid
}) => {
  const { t } = useTranslation()

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  const [isLoading, setLoading] = useState(false)

  const excludedTooltipCodes = ['2004', '2011']
  const excludedRetryButtonCodes = ['2004', '2011']

  const isTooltip = !excludedTooltipCodes.includes(paymentError)
  const isRetryButton = !excludedRetryButtonCodes.includes(paymentError)

  const handleRetryButtonClick = () => {
    setLoading(true)

    window.open(
      `${NEW_LANDINGI_APP_URL}${PAYMENTS.SUBSCRIPTION.RETRY}`,
      '_self'
    )
  }

  return (
    <Fragment>
      <span className={styles.alert__content}>
        <Trans
          i18nKey='braintree.payment.error.message.header'
          components={{
            b: <b />,
            br: <br />
          }}
        />

        <Spreader spread='mini' />

        <Trans
          i18nKey={`braintree.payment.error.${paymentError}.message`}
          components={{
            b: <b />,
            br: <br />
          }}
        />

        {isTooltip ? (
          <Fragment>
            <Spreader spread='mini' />

            <Tooltip
              content={t(`braintree.payment.error.${paymentError}.tooltip`)}
            >
              <Icon icon='icon-info-circle' />
            </Tooltip>
          </Fragment>
        ) : null}
      </span>

      {hasAccessToPayments && (
        <Row>
          {isRetryButton ? (
            <Button
              className={styles.alert__button}
              variant='secondary'
              size='tiny'
              onClick={handleRetryButtonClick}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              {t('braintree.payment.error.retry.payment')}
            </Button>
          ) : null}

          <Spreader spread='small' />

          <Button
            className={styles.alert__button}
            size='tiny'
            tag='a'
            href={`${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}/${planPackageUuid}`}
            buttonStyle
          >
            {t('payments.change.payment.method')}
          </Button>
        </Row>
      )}
    </Fragment>
  )
}

PaymentError.displayName = 'TopbarInfobarPaymentErrorPaymentError'
