import { Icon, Tooltip } from '@landingi/landingi-ui-kit'
import { isPublishedOnTestDomain } from '@pages/Landings/routes/Landings/helpers'
import { useTranslation } from 'react-i18next'

import styles from './TestDomainIcon.module.scss'

interface TestDomainIconProps {
  domain: string
  isPublished: boolean
}

export const TestDomainIcon = ({
  domain,
  isPublished
}: TestDomainIconProps) => {
  const { t } = useTranslation()

  return isPublishedOnTestDomain(isPublished, domain) ? (
    <Tooltip
      content={t('list.domains.tip.testdomain')}
      placement='top'
      align='center'
    >
      <span className={styles['test-domain-icon']}>
        <Icon icon='icon-exclamation' color='white' autoSize />
      </span>
    </Tooltip>
  ) : null
}

TestDomainIcon.displayName = 'TestDomainIcon'
