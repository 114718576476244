import { useStyles } from '@hooks/useStyles'
import { Image } from '@landingi/landingi-ui-kit'
import PropTypes from 'prop-types'

import styles from './CardImages.module.scss'

const CardImage = ({ image, prefixNumber, cardNumber }) => {
  const isInactive = !prefixNumber.test(cardNumber)

  const imageStyles = useStyles({
    [styles['cards-imgs__inactive']]: isInactive
  })

  return <Image className={imageStyles} src={image} size={35} />
}

CardImage.displayName = 'Card Images'

CardImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  prefixNumber: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired
}

export default CardImage
